import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import CartList from "../../Ecommerce/CartList";
import PriceList from "../../Ecommerce/PriceList";
import BillingAddress from "../../Ecommerce/BillingAddress";
import CheckoutAddress from "./CheckoutAddress";
import { Divider, Radio, Space, Avatar, Result, message } from "antd";
import styles from "../../ApiService/Data";
import "../../../src/Assets/Css/style.css";
import { Link, useNavigate } from "react-router-dom";
import Gpay_Logo from "../../Assets/Images/Payment/gpay.png";
import API from "../../ApiService/ApiService";
import Phonepe_Logo from "../../Assets/Images/Payment/phonepe_logo.png";
import { sum } from "lodash";

const CheckoutStepper = ({ totalAmount, onCartChange, onAddressChange }) => {
  const [radioValue, setRadioValue] = useState(1);
  const [activeStep, setActiveStep] = useState(0); 
  const [refresh1, setRefresh1] = useState(false); 
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  const api = new API();
  const getUser = localStorage.getItem("user") || "";
  const getDeliveryAddress = localStorage.getItem("Default Address") || "";
  const user1 = JSON.parse(localStorage.getItem("user")) || "";
  const company = useSelector((state) => state?.company?.company?.data[0]);
  const isDecimal = useSelector((state) => state?.priceDecimalsSlice?.pricedecimal?.[0]);
  
  console.log("company-->", company);

  let user;
  let deliveryAddress;
  if (getUser || getDeliveryAddress) {
    user = getUser && JSON.parse(getUser);
    deliveryAddress = getDeliveryAddress && JSON.parse(getDeliveryAddress);
  } else {
    user = "";
    deliveryAddress = "";
  }

  const data4Checkout = {
    buyer_id: user1?._id
  }

  useEffect(() => {
    getAllCart();
  }, []);

  useEffect(() => {
    if (typeof onCartChange !== 'function') {
      console.error('onCartChange is not a function');   
      // getAllCart();   
    }
    getAllCart();
  }, [onCartChange]);

  useEffect(() => {
    if (typeof onAddressChange !== 'function') {
      console.error('onAddressChange is not a function');
      // getAllCart();
    }
    getAllCart();
  }, [onAddressChange]);

  const getAllCart = () => {
    api.getcart(data4Checkout).then((res) => {
      // console.log("res->", res);
      setCart(res?.data?.data);
    });
  };

  const onChange = (e) => {
    setRadioValue(e.target.value);
  };

  const data = [];

  console.log("cart", cart?.cart_item);

  cart?.cart_item?.map((item) => {
    console.log("stepperItem", item);

    // Extract price details: Discount, Tax, and Final Price
    const calculatePriceDetails = () => {
      let basePrice = 0;
      let discountAmount = 0;
      let taxAmount = 0;
      const taxRate = item?.product_id?.tax_percentage?.tax_percentage / 100 || 0;

      // MRP only logic
      if (item?.show_mrp === true && item?.show_sp === false) {
        basePrice = item?.mrp;

        if (item?.product_spec?.discount_id?.discount_id?.discount > 0) {
          if (item?.discount_type === "Percentage") {
            discountAmount = (basePrice * item?.product_spec?.discount_id?.discount_id?.discount) / 100;
          } else if (item?.discount_type === "Price") {
            discountAmount = item?.product_spec?.discount_id?.discount_id?.discount;
          }
        }

        taxAmount = (basePrice - discountAmount) * taxRate;
      } 
      // Common MRP logic
      else if (item?.product_id?.show_common_mrp === true && item?.product_id?.show_common_sp === false) {
        basePrice = item?.product_id?.common_mrp;

        if (item?.product_spec?.discount_id?.discount_id?.discount > 0) {
          if (item?.discount_type === "Percentage") {
            discountAmount = (basePrice * item?.product_spec?.discount_id?.discount_id?.discount) / 100;
          } else if (item?.discount_type === "Price") {
            discountAmount = item?.product_spec?.discount_id?.discount_id?.discount;
          }
        }

        taxAmount = (basePrice - discountAmount) * taxRate;
      } 
      // SP or Common SP logic
      else {
        basePrice = item?.sp || item?.product_id?.common_sp || 0;

        if (item?.product_spec?.discount_id?.discount_id?.discount > 0) {
          if (item?.discount_type === "Percentage") {
            discountAmount = (basePrice * item?.product_spec?.discount_id?.discount_id?.discount) / 100;
          } else if (item?.discount_type === "Price") {
            discountAmount = item?.product_spec?.discount_id?.discount_id?.discount;
          }
        }

        taxAmount = (basePrice - discountAmount) * taxRate;
      }

      // Final Price after Discount and Tax
      const finalPrice = basePrice - discountAmount + taxAmount;

      return {
        finalPrice: finalPrice,
        discountAmount: discountAmount,
        taxAmount: taxAmount,
      };

      // return {
      //   finalPrice: isDecimal?.isDecimal === false
      //     ? Math.round(finalPrice)
      //     : finalPrice.toFixed(isDecimal?.Decimal_points),
      //   discountAmount: isDecimal?.isDecimal === false
      //     ? Math.round(discountAmount)
      //     : discountAmount.toFixed(isDecimal?.Decimal_points),
      //   taxAmount: isDecimal?.isDecimal === false
      //     ? Math.round(taxAmount)
      //     : taxAmount.toFixed(isDecimal?.Decimal_points),
      // };
    };

    // Calculate details
    const { finalPrice, discountAmount, taxAmount } = calculatePriceDetails();

    data.push({
      product_id: item?.product_id?._id,
      quantity: item?.qty,
      product_name: item?.product_id?.product_name,
      taxable_price: (item?.show_mrp === true && item?.show_sp === false) 
                        ? item?.mrp 
                        : (item?.product_id?.show_common_mrp === true && item?.product_id?.show_common_sp == false) 
                        ? item?.product_id?.common_mrp : item?.sp || item?.product_id?.common_sp || 0,
      price: finalPrice,
      discount: discountAmount,
      tax: taxAmount,
      cgst: Number(taxAmount / 2),
      sgst: Number(taxAmount / 2),
    });
  });  

  const totalAmountAll = cart?.cart_item?.reduce((total, item) => {
    // console.log("item", item);
    let productAmount = 0;
    
    if (item?.show_mrp === true && item?.show_sp == false) {
      productAmount = item?.product_spec?.discount_id?.discount_id?.discount > 0 ? 
                      (item?.discount_type === "Percentage" ? (                        
                          Number(item?.mrp - (item?.mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) + // Apply percentage discount
                          Number((item?.mrp - (item?.mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation                       
                      ) 
                      : item?.discount_type === "Price" ?
                      (                        
                          Number(item?.mrp - item?.product_spec?.discount_id?.discount_id?.discount) + // Apply flat discount
                          Number((item?.mrp - item?.product_spec?.discount_id?.discount_id?.discount) * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation                       
                      )
                      :
                      (
                        (item?.mrp || 0) + 
                        ((item?.mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      )
                      )
                      :
                      ((item?.mrp || 0) + 
                      ((item?.mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100)));
    } else if (item?.product_id?.show_common_mrp === true && item?.product_id?.show_common_sp == false) {
      productAmount = item?.product_spec?.discount_id?.discount_id?.discount > 0 ?
                      (item?.discount_type === "Percentage" ? (                        
                          Number(item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) + // Apply percentage discount
                          Number((item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation                        
                      ) : item?.discount_type === "Price" ? (                        
                          Number(item?.product_id?.common_mrp - item?.product_spec?.discount_id?.discount_id?.discount) + // Apply flat discount
                          Number((item?.product_id?.common_mrp - item?.product_spec?.discount_id?.discount_id?.discount) * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation                        
                      ) : (
                        (item?.product_id?.common_mrp || 0) + 
                      ((item?.product_id?.common_mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      )
                      )
                      :
                      ((item?.product_id?.common_mrp || 0) + 
                      ((item?.product_id?.common_mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100)));
    } else {
      productAmount = item?.product_spec?.discount_id?.discount_id?.discount > 0 ? 
                      (item?.discount_type === "Percentage" ? (                        
                          Number(
                            (item?.sp || item?.product_id?.common_sp || 0) - ((item?.sp || item?.product_id?.common_sp || 0) * item?.product_spec?.discount_id?.discount_id?.discount / 100) 
                          ) +
                          Number(
                            ((item?.sp || item?.product_id?.common_sp || 0) - ((item?.sp || item?.product_id?.common_sp || 0) * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                          )                        
                      ) : item?.discount_type === "Price" ? (                        
                          Number(
                            (item?.sp || item?.product_id?.common_sp || 0) - item?.product_spec?.discount_id?.discount_id?.discount 
                          ) +
                          Number(
                            ((item?.sp || item?.product_id?.common_sp || 0) - item?.product_spec?.discount_id?.discount_id?.discount) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                          )                        
                      ) : (
                        (item?.sp || item?.product_id?.common_sp || 0) + 
                      ((item?.sp || item?.product_id?.common_sp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      )
                      )
                      :
                      ((item?.sp || item?.product_id?.common_sp || 0) + 
                      ((item?.sp || item?.product_id?.common_sp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100)));
    }
  
    // Return the total amount including quantity
    return total + (productAmount * (item?.qty || 1));
  }, 0);

  const data_all = {
    data,
    delivery_Charge: cart?.total_delivery_charge,
    total_without_delivery: totalAmountAll,
    total: Number(totalAmountAll) + Number(cart?.total_delivery_charge),
    total_discount: data.reduce((sum, item) => sum + (Number(item?.discount) || 0), 0),
    total_tax: data.reduce((sum, item) => sum + (Number(item?.tax) || 0), 0),
    logo: company?.logo,
  }

  // data.push({
  //   delivery_Charge: cart?.total_delivery_charge,
  //   total_without_delivery: totalAmountAll,
  //   total: Number(totalAmountAll) + Number(cart?.total_delivery_charge),
  // });

  console.log("data", data);  
  console.log("data111", data_all);  

  const steps = [
    // {
    //   label: (
    //     <div
    //       style={{
    //         fontSize: "16px",
    //         fontWeight: 600,
    //         textTransform: "uppercase",
    //       }}
    //     >
    //       Order Summary
    //     </div>
    //   ),
    //   description: (
    //     <>
    //       <CartList />
    //       <Divider />
    //     </>
    //   ),
    // },
    {
      label: (
        <div className="address_label"
          style={{
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          Order Summary
        </div>
      ),
      description: (
        <>
          <CartList  refresh1={refresh1} onCartChange={onCartChange} />
          <Divider />
        </>
      ),
    },
    {
      label: (
        <div className="address_label"
          style={{
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          Delivery Address
        </div>
      ),
      description: <CheckoutAddress onAddressChange={onAddressChange} />,
    },   
    {
      label: (
        <div className="address_label"
          style={{
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          Payment Options
        </div>
      ),
      description: (
        <div className="Payment">
          <Radio.Group onChange={onChange} value={radioValue}>
            <Space direction="vertical">
              {/* <Radio value={1}>UPI</Radio>
              {radioValue == 1 && (
                <Radio.Group style={{marginLeft: "20px"}}>
                  <Radio value={1}>
                    <Avatar src={Gpay_Logo} /> Gpay
                  </Radio>
                  <Radio value={2}>
                    <Avatar src={Phonepe_Logo} /> PhonePe
                  </Radio>
                </Radio.Group>
              )}
              <Radio value={2}>Net Banking</Radio> */}
              <Radio value={1}>Easebuzz</Radio>
            </Space>
          </Radio.Group>
        </div>
      ),
    },
  ];

  const min = 1;
  const max = 10000;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

  const handleNext = (index) => {
    // console.log("index--->", index);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (index == steps?.length - 1) {
      const combinedData = {
        buyer_id: user?._id,
        buyer_address_id: deliveryAddress?._id,
        data_all,
      };

      api.placeorder(combinedData).then((res) => {
        console.log("res--->", res);
        if (res?.status == 200) {
          // console.log(res?.data?.data)
          window.open(res?.data?.data?.redirect, "_self")
          // navigate("/payment");
          message.success("Directed to Payment Successfully");
        } else {
          message.error(res?.response?.data?.message);
        }
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <StepperAlign>
      <Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step?.label}>
              <StepLabel
                className={
                  index < activeStep
                    ? "StepperLabel-completed"
                    : "StepperLabel-incomplete"
                }
              // optional={
              //   index == 2 ? (
              //     <Typography variant="caption">Last step</Typography>
              //   ) : null
              // }
              >
                {step?.label}
              </StepLabel>
              <StepContent>
                <Typography>{step?.description}</Typography>
                {/* {console.log("index--->", index)} */}
                {/* {index === 1 && (
                  <CartList refresh1={refresh1} onCartChange={onCartChange} /> 
                )} */}
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      style={{ backgroundColor: "rgb(25, 118, 210)" }}
                      variant="contained"
                      sx={{ mt: 1, mr: 1 }}
                      onClick={() => handleNext(index)}
                    >
                      {index == steps?.length - 1 ? "Place Order" : "Continue"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {/* {activeStep === steps?.length && (
          <Paper square elevation={0} sx={{p: 3}}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{mt: 1, mr: 1}}>
              Reset
            </Button>
          </Paper>
        )} */}
      </Box>
    </StepperAlign>
  );
};

export default CheckoutStepper;

const StepperAlign = styled.div`
padding-left: 20px;
  /* padding: 20px; */
  width: 100%;

  .MuiBox-root .css-1qm1lh {
    float: right;
  }

  .StepperLabel-incomplete {
    background-color: #ddd;
    color: #999;
    border-radius: 10px;
    padding: 20px;
  }

  /* Completed Step */
  .StepperLabel-completed {
    border-radius: 10px;
    padding: 20px;
    background-color: ${styles.appColor};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .css-vnkopk-MuiStepLabel-iconContainer {
    padding: 5px;
    border-radius: 5px;
  }

  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: black;
    border-radius: 0 !important;
    width: 25px;
    height: 25px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: black;
    border-radius: 0 !important;
    width: 25px;
    height: 25px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .css-1f5ro5o-MuiButtonBase-root-MuiButton-root {
    background-color: ${styles.appColor};
    color: ${styles.white};
  }

  .css-1f5ro5o-MuiButtonBase-root-MuiButton-root:hover {
    background-color: ${styles.appColor};
  }
`;
