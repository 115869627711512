import React, { useState, useEffect, createContext, useReducer } from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  getSigleProductFail,
  getSigleProductStart,
  getSigleProductSuccess,
} from "../Redux/slices/singleProduct";
import { useDispatch, useSelector } from "react-redux";
import { cartStart, cartFailure, cartSuccess } from "../Redux/slices/cart";
import { updateLocalCartLength } from '../Redux/slices/cartLocalLength';
import { updateLocalWishlistLength } from "../Redux/slices/wishlistLocalLength";
import {
  wiseListStart,
  wiseListSuccess,
  wiseListFailure,
} from "../Redux/slices/wishList";
import {
  HomeOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UpOutlined,
  DownOutlined,
  HeartOutlined,
  MinusOutlined,
  PlusOutlined,
  CaretRightOutlined,
  SkinOutlined,
  LayoutOutlined,
  CheckCircleOutlined,
  RightCircleOutlined,
  LockOutlined,
  FireOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Divider,
  Row,
  Col,
  Tabs,
  Tooltip,
  Alert,
  Modal,
  Button,
  Image,
  List,
  Rate,
  Form,
  Input,
  message,
  Card,
  Radio,
  Skeleton,
  Tag,
  Space,
  Spin,
  InputNumber,
  Collapse,
} from "antd";
import { Comment } from "@ant-design/compatible";
import Default from "../Assets/Images/default.png";
import API from "../ApiService/ApiService";
import moment from "moment";
import crud_service from "../ApiService/CrudService";
import { theme, font } from "../ApiService/Theme";
import App from "../App";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import VideoPlay from "../Assets/Images/video.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, Mousewheel } from "swiper";
import "swiper/swiper-bundle.css";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import styles from "../ApiService/Data";
import {
  userGetAllStart,
  userGetAllSuccess,
  userGetAllFail,
} from "../Redux/slices/user";
import { company_code, base_url, api_website, api_admin } from "../config";
import dayjs from "dayjs";

const crud = new crud_service();

const { TextArea } = Input;

const ProductContext = createContext();
SwiperCore.use([Navigation, Thumbs]);
let specificationSet;
let user = "";

const Products = () => {
  const CurrentDate = new dayjs();
  // console.log("CurrentDate", CurrentDate);
  const location = useLocation();
  const { size } = location.state || {}; 
  let { productSpecId } = location?.state || {};
  const CartLocal = JSON.parse(localStorage.getItem('cartLocal'));
  // console.log("CartLocal", CartLocal);

  // console.log("state???", productSpecId);
  // console.log("location???", location);
  // console.log("size???", size);
  // const loginTrue = localStorage.getItem("login");
  const loginTrue = localStorage.getItem("login");
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const api = new API();
  const [products, setProducts] = useState([]);
  const [specData, setSpecData] = useState([]);
  const [specData1, setSpecData1] = useState([]);
  const [activeButtonId, setActiveButtonId] = useState(null);
  const [isVideoVisible, setVideoVisible] = useState(false);
  const [count, setCount] = useState(1);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [wishListLoading, setWishListLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [totalProducts, setTotalProducts] = useState([]);
  const [filterSpecs, setFilterSpecs] = useState([]);
  const [foundSpectId, setFoundSpectId] = useState(null);
  const [foundSpectAll, setFoundSpectAll] = useState(null);
  const [foundSpectId1, setFoundSpectId1] = useState(null);
  const [foundSpectQty, setFoundSpectQty] = useState(0);
  const [foundSpectImg, setFoundSpectImg] = useState(null);
  const [allSpectImg, setallSpectImg] = useState(null);
  const [relatedProClicked, setRelatedProClicked] = useState(false);
  const [foundSpectImg1, setFoundSpectImg1] = useState([]);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState([]);
  const getUser = localStorage.getItem("user");
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const dispatch = useDispatch();
  const params = useParams();
  const [isSaving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [reviews, setreviews] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedRelatedProducts, setSelectedRelatedProducts] = useState([]);
  const [discountsrules, setProductsDiscountDetails] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  // const [filterSku, setFilterSku] = useState(specData[0]?.sku);
  // console.log("filterSku", filterSku);
  // console.log("specData", specData?.[0]?.discount_id?.discount_id?.discount_valid_to);
  // console.log("relatedProducts", relatedProducts);
  // console.log("totalProducts", totalProducts);
  // console.log("params", params);

  // console.log("showAlert", showAlert);
  let savingsAmount;

  const isDecimal = useSelector(
    (state) => state?.priceDecimalsSlice?.pricedecimal?.[0]
  );
  // console.log("isDecimal==>", isDecimal);

  const RelatedProductsFilter = relatedProducts?.filter(
    (item) => item?.sku !== specData?.[0]?.sku
  );
  // console.log("RelatedProductsFilter", RelatedProductsFilter);
  const [loginForm] = Form.useForm();

  const navigate = useNavigate();

  // console.log("loginTrue--->", loginTrue);

  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

  var isButtonDisabled;
  // console.log("params above??", params);

  // console.log("Total Products-->", totalProducts);
  useEffect(() => {
    getSingleProduct();
    getRating();
    getProductDiscountDetails();
  }, []);

  // console.log("totalProducts 148", totalProducts);

  const getSingleProduct = () => {
    // console.log("value", value);
    console.log("params", params);
    api.getSingleProduct(params?.id).then((res) => {
      // console.log("productspec", res?.data?.data?.productspec);
      setProducts(res?.data?.data?.product);
      setTotalProducts(res?.data?.data);
      setSpecData(res?.data?.data?.productspec);
      setreviews(res?.data?.data?.reviews);
      setRelatedProducts(res?.data?.data?.relatedProducts);
      // console.log("relatedProducts", res?.data?.data?.relatedProducts);

      if (specData1?.length <= 0) {
        setSpecData1(res?.data?.data?.productspec);
      }

      const initialParam = {
        spec_ids: [],
        detail_ids: [],
      };

      res?.data?.data?.product?.specification?.forEach((spec, index) => {
        const defaultDetailId = spec?.specification_details[0]?._id;
        initialParam.spec_ids.push(
          spec?.specification_details[index]?.specification_id
        );
        // initialParam.spec_ids.push(spec?.specification?._id);
        initialParam.detail_ids.push(defaultDetailId);
      });

      // setParam(initialParam);
      setInitialLoading(false);
    });
  };

  // useEffect(() => {
  //   var a = 0;

  //   for(a; a < totalProducts?.product?.specification?.specification_details?.length; a++) {
  //     console.log("a", a);
  //   }

  // }, [totalProducts]);

  const getSingleProduct1 = (value, index) => {
    // console.log("index", index);
    api.getSingleProduct(value ? value : params?.id).then((res) => {
      // console.log("setSpecData1", res?.data?.data?.relatedProducts[index]?._id);
      const setSpecData11 = res?.data?.data?.relatedProducts[index]?._id;
      addToCart1(setSpecData11);
    });
  };

  const getProductDiscountDetails = () => {
    api.getProductDiscountDetails(params?.id).then((res) => {
      //  console.log( res?.status)
      if (res?.status == 200) {
        //  console.log("product id ===>"+params?.id);
        //  console.log("ProductDiscountDetails fetched successfully");
        setProductsDiscountDetails(res?.data?.data?.discounts);
        //console.log("discountsrules 222222222===>"+res?.data?.data?.discounts);
        //  console.log("discountdata data===>"+discountsrules.length)
        setInitialLoading(false);
      } else {
        // console.log("ProductDiscountDetails not available");
      }
    });
  };

  const getRating = () => {
    api.getCommonApiRating().then((res) => {
      setRating(res?.data?.data);
      setInitialLoading(false);
    });
  };

  const addReview = (values) => {
    const combinedData = {
      title: {
        ...values,
        product_id: params?.id,
      },
      mode: "rating",
    };

    api.createCommonRatingApi(combinedData).then((res) => {
      if (res?.status == 201) {
        message.success("Rating added successfully");
        form.resetFields();
        getRating();
      } else {
        message.error("Something went wrong");
      }
    });
  };

  const item = [
    {
      href: "/",
      title: <HomeOutlined />,
    },
    // {
    //   href: "",
    //   title: (
    //     <p onClick={()=>navigate(`/Products?catName=${totalProducts?.product?.category[0]?.name
    //       ?.replace(" ", "-")
    //       ?.toLowerCase()
    //       ?.replace(/ /g, "")
    //       ?.replace(/[^\w-]+g/, "")}`)}>
    //       {totalProducts?.product?.category?.map((e) => {
    //         return <p>{e?.name}</p>;
    //       })}
    //     </p>
    //   ),
    // },
    {
      href: "/products",
      title: <>Products</>,
    },
    {
      title: <>{specData?.[0]?.product_id?.product_name}</>,
    },
  ];

  const allReviews = [];

  // const pro = [];
  // for (let i = 0; i < 4; i++) {
  //   pro.push(
  //     <div className="product_align_filter_box">
  //       <div className="p_image">
  //         <img src={Default} alt="Default" />
  //       </div>
  //       <div className="p_content">
  //         <h2>Keychain With Pendrive</h2>
  //         <div className="price">
  //           <div className="sp">₹ 950</div>
  //           <div className="mrp">₹ 1000</div>
  //         </div>
  //         <div>
  //           <Button size="medium">Buy Now</Button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // {
  //   relatedProducts?.map((item) => {
  //     <div className="product_align_filter_box">
  //       <div className="p_image">
  //         <img src={api.rootUrl1 + item?.product_images[0]?.image_path} alt="Default" />
  //       </div>
  //       <div className="p_content">
  //         <h2>{item?.product_id?.product_name}</h2>
  //         <div className="price">
  //           <div className="sp">₹ {item?.sp}</div>
  //           <div className="mrp">₹ {item?.mrp}</div>
  //         </div>
  //         <div>
  //           <Button size="medium">Buy Now</Button>
  //         </div>
  //       </div>
  //     </div>
  //   })
  // }

  const initialMainImage =
    totalProducts?.productspec?.product_images?.[0]?.image_path || null;
  const [mainImage, setMainImage] = useState(initialMainImage);

  const productspecification = products?.prospecdocs?.map(
    (item) => item?.specification
  );

  const handleThumbnailClick = (imagePath) => {
    setMainImage(imagePath);
  };

  const data4Checkout = {
    buyer_id: user?._id,
  };

  useEffect(() => {
    getAllCartss();
  }, []);
  const getAllCartss = () => {
    if (user) {
      api.getcart(data4Checkout).then((res) => {
        // console.log("res--->", res);
        if (res.response?.data?.status === 500) {
          message.error(res?.response?.data?.message);
          // navigate("/delivery-address");
          setCart({
            cart_item: [],
          });
        } else {
          setCart(res?.data?.data?.cart_item);
          setLoading(false);
          dispatch(cartSuccess(res?.data));
        }
      });
    }
  };

  const addToCart = () => {
    setLoading(true);
    // setAddToCartLoading(true);

    // console.log("addToCartID user", user)  `1;
    console.log("foundSpectId", foundSpectId);

    // console.log("addToCartID company_code", company_code);
    const combinedData = {
      qty: count,
      product_id: specData[0]?.product_id?._id,
      product_spec: foundSpectId ? foundSpectId : specData[0]?._id,
      company_code: company_code,
      // company_code: user?.company_code,
    };

    if (!loginTrue) {
      // setAddToCartLoading(false);
      // setOpen(true);

      let storedList = JSON.parse(localStorage.getItem('cartLocal')) || [];

    // Check if a matching entry exists
    const existingIndex = storedList.findIndex(
      item => 
        item.product_id === combinedData.product_id &&
        item.product_spec === combinedData.product_spec
    );

    if (existingIndex !== -1) {
      // Update the quantity of the existing item
      storedList[existingIndex].qty += combinedData.qty;
    } else {
      // Add the new combinedData object to the list
      storedList.push(combinedData);
    }

    // Store the updated list back to local storage
    localStorage.setItem('cartLocal', JSON.stringify(storedList));

    dispatch(updateLocalCartLength(storedList.length));

    message.success("Added to Cart Successfully");

    setLoading(false);
    } else {
      const combinedDataWithUser = {
        ...combinedData,
        buyer_id: user?._id,
      };
      // console.log("combinedDataCart", combinedData);
      api.addCart(combinedDataWithUser).then((res) => {
        // console.log("res--->", res);
        if (res?.status == 200) {
          dispatch(cartStart());
          // dispatch(cartStart);
          api.getcart().then((res) => {
            // console.log("res--->", res);
            dispatch(cartSuccess(res?.data));
          });
          message.success(res?.data?.message);
          setAddToCartLoading(false);
          getAllCartss();
          // window.location.reload();
        } else {
          // console.log("res--->", res?.response?.data?.message);
          setOpen1(true);
          dispatch(cartFailure());
          // dispatch(cartFailure);
          message.error(res?.response?.data?.message);
          setLoading(false);
          // setAddToCartLoading(false);
        }
      });
    }
  };
  const addToCart1 = (setSpecData11) => {
    // setAddToCartLoading(true);
    setLoading(true);

    if (!loginTrue) {
      // setAddToCartLoading(false);

      setOpen(true);
    } else {
      const combinedData = {
        buyer_id: user?._id,
        qty: count,
        product_id: specData[0]?.product_id?._id,
        product_spec: setSpecData11 ? setSpecData11 : specData?._id,
      };
      // console.log("combinedDataCart", combinedData);
      api.addCart(combinedData).then((res) => {
        if (res?.status == 200) {
          dispatch(cartStart);
          api.getcart().then((res) => {
            // console.log("res--->", res);
            dispatch(cartSuccess(res?.data));
          });
          message.success(res?.data?.message);
          // setAddToCartLoading(false);
          setLoading(false);
          getAllCartss();
          // window.location.reload();
        } else {
          // console.log("res--->", res?.response?.data?.message);
          dispatch(cartFailure);
          message.error(res?.response?.data?.message);
          setAddToCartLoading(false);
        }
      });
    }
  };
  const addToWiseList = () => {
    setWishListLoading(true);

    const combinedData = {
      qty: count,
      product_id: specData[0]?.product_id?._id,
      product_spec: foundSpectId1 ? foundSpectId1 : specData[0]?._id,
      company_code: user?.company_code,
    };

    if (!loginTrue) {
      // setWishListLoading(false);
      // setOpen(true);
      let storedList = JSON.parse(localStorage.getItem('wishList')) || [];

      // Check if a matching entry exists
      const existingIndex = storedList.findIndex(
        item => 
          item.product_id === combinedData.product_id &&
          item.product_spec === combinedData.product_spec
      );
  
      if (existingIndex !== -1) {
        // Update the quantity of the existing item
        storedList[existingIndex].qty += combinedData.qty;
      } else {
        // Add the new combinedData object to the list
        storedList.push(combinedData);
      }
  
      // Store the updated list back to local storage
      localStorage.setItem('wishList', JSON.stringify(storedList));
      dispatch(updateLocalWishlistLength(storedList.length));
  
      setWishListLoading(false);

    } else {
      const combinedDataWithUser = {
        ...combinedData,
        buyer_id: user?._id,        
      };
      // console.log("combinedDataWishlist", combinedData);
      api.addWiseList(combinedDataWithUser).then((res) => {
        // console.log(res);
        if (res?.status == 201) {
          dispatch(wiseListStart());
          // dispatch(wiseListStart);
          api.getWishList().then((res) => {
            // console.log("res--->", res);
            dispatch(wiseListSuccess(res?.data));
          });
          message.success(res?.data?.message);
          setWishListLoading(false);
          // window.location.reload();
        } else {
          dispatch(wiseListFailure());
          // dispatch(wiseListFailure(res?.data));
          message.error(res?.response?.data?.message);
          setWishListLoading(false);
        }
      });
    }
  };

  const handleClickVideo = () => {
    setVideoVisible(true);
  };

  const [param, setParam] = useState({
    spec_ids: [],
    detail_ids: [],
  });

  const handleSpecClick = async (index, detail_id, spec_id, prod_id) => {
    console.log("i am innnnn")
    setFoundSpectId(null);
    setFoundSpectId1(null);
    setFoundSpectQty(null);
    setFoundSpectImg(null);
    setSkeletonLoading(true);
    // console.log("param", param);
    const updatedParam = { ...param };
    // console.log("updatedParam ??", updatedParam);
    // console.log("spec_id", spec_id);

    const specIndex = updatedParam?.spec_ids?.indexOf(spec_id);
    // console.log("specIndex", specIndex);
    const detailIndex = updatedParam?.detail_ids?.indexOf(detail_id);

    if (specIndex !== -1) {
      // updatedParam.spec_ids.splice(specIndex, 1);
      // updatedParam.detail_ids.splice(specIndex, 1);

      // Replace the corresponding detail_id if spec_id is already in the array
      updatedParam.detail_ids[specIndex] = detail_id;
    } else {
      // Otherwise, push the new spec_id and detail_id
      updatedParam.spec_ids.push(spec_id);
      updatedParam.detail_ids.push(detail_id);
    }

    // updatedParam.spec_ids.push(spec_id);
    // updatedParam.detail_ids.push(detail_id);
    // console.log("updatedParam---->", updatedParam);

    try {
      const res = await api.getproductspec(updatedParam, prod_id); // Pass updatedParam instead of param
      const data = res?.data?.data?.productspec;
      setSpecData(data);
      setSkeletonLoading(false);

      const filteredSpecs = data?.map((spec) => {
        return spec?.specification?.map((insidespec) => {
          return insidespec?.specification_details;
        });
      });
      // console.log("i am here indside");
      setFilterSpecs([...filteredSpecs.flat()]);
      setParam(updatedParam);
      setMainImage("");
      localStorage.setItem("spec_data", JSON.stringify(data));
    } catch (err) {
      message.error("Something Went Wrong");
      setSkeletonLoading(false);
    }
  };

  // const handleSpecClick = async (index, detail_id, spec_id, prod_id) => {
  //   setSkeletonLoading(true);
  //   const updatedParam = { ...param };
  //   // console.log("updatedParam---->", updatedParam);

  //   const specIndex = updatedParam?.spec_ids?.indexOf(spec_id);
  //   const detailIndex = updatedParam?.detail_ids?.indexOf(detail_id);

  //   if (specIndex !== -1) {
  //     updatedParam?.spec_ids?.splice(specIndex, 1);
  //     updatedParam?.detail_ids?.splice(specIndex, 1);
  //   }

  //   if (detailIndex !== -1) {
  //     updatedParam?.spec_ids?.splice(detailIndex, 1);
  //     updatedParam?.detail_ids?.splice(detailIndex, 1);
  //   }

  //   updatedParam?.spec_ids?.push(spec_id);
  //   updatedParam?.detail_ids?.push(detail_id);

  //   try {
  //     const res = await api.getproductspec(param, prod_id);
  //     const data = res?.data?.data?.productspec;
  //     setSpecData(data);

  //     const filteredSpecs = data?.map((spec) => {
  //       // console.log("spec", spec);
  //       return spec?.specification?.map((insidespec) => {
  //         return insidespec?.specification_details;
  //       });
  //     });
  //     setFilterSpecs([...filteredSpecs.flat()]);
  //     setParam(updatedParam);
  //     setMainImage("");
  //     setSkeletonLoading(false);
  //     localStorage.setItem("spec_data", JSON.stringify(data));
  //   } catch (err) {
  //     message.error("Something Went Wrong");
  //     setSkeletonLoading(false);
  //   }
  // };

  // console.log("filter-->", filterSpecs);

  // const handleChangeIncrease = (type, data) => {
  //   // console.log("type", type);
  //   // console.log("data", data)
  //   if (type === "minus") {
  //     setCount(count > 1 ? count - 1 : 1);
  //   } else if (type === "plus") {
  //     setCount(count < data ? count + 1 : count);
  //   }
  // };
  const handleChangeIncrease = (type, data) => {
    if (type === "minus") {
      // If the count is at the minimum (e.g., 1), show an alert
      if (count <= 1) {
        alert("You cannot decrease the quantity below the minimum allowed.");
        return; // Prevent further action if it's at minimum
      }
  
      // Decrease the count if it's greater than 1
      setCount(count - 1);
    } else if (type === "plus") {
      // If count exceeds the maximum available quantity (based on `data`), show an alert
      if (count >= data) {
        alert(`You cannot increase the quantity the available stock of ${data}.`);
        return; // Prevent further action if it's beyond the maximum allowed
      }
  
      // Increase the count if it's less than the maximum allowed
      setCount(count + 1);
    }
  };
  const primaryArray = [];
  const secondaryArray = [];

  totalProducts?.product?.specification?.slice(0, 1)?.map((item) => {
    item?.specification_details?.map((e) => {
      return primaryArray.push(e?.specification_details);
    });
  });
  totalProducts?.product?.specification?.slice(1)?.map((item) => {
    item?.specification_details?.map((e) => {
      return secondaryArray.push(e?.specification_details);
    });
  });

  const htmlContent = totalProducts?.product?.product_description;
  // console.log("htmlContent", htmlContent);
  const htmlContentdasd = totalProducts?.product?.product_description?.split(" ")?.slice(0, 20)?.join(" ");

  const items = [
    {
      key: "1",
      label: (
        <div
          className="label"
          id="descriptionsec"
          style={{ display: "flex", alignItems: "center" }}
        >
          <SkinOutlined />
          <h6 style={{ marginBottom: "0px", marginLeft: "10px" }}>
            Descriptions
          </h6>
        </div>
      ),
      children: (
        <>
          <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            style={{ overflowWrap: "anywhere" }}
          ></div>
          {/* <Divider /> */}
        </>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="label"
          style={{ display: "flex", alignItems: "center" }}
        >
          <LayoutOutlined />
          <h6 style={{ marginBottom: "0px", marginLeft: "10px" }}>
            Specifications
          </h6>
        </div>
      ),
      children: (
        <>
          <div className="specifications">
            {totalProducts?.product?.specification?.map((spec, i) => (
            //  {totalProducts?.product?.specification?.slice(2)?.map((spec, i) => (
              <>
                <p key={i}>
                  <b>{spec?.specification?.name} :</b>{" "}
                  {spec?.specification_details?.map((item, index) => (
                    <>
                      {item?.specification_details}
                      {index < spec?.specification_details?.length - 1 &&
                        ", "}{" "}
                    </>
                  ))}
                </p>
              </>
            ))}
          </div>
          {/* <Divider /> */}
        </>
      ),
    },
    {
      key: "3",
      label: (
        <div className="label" style={{display: "flex", alignItems: "center"}}>
          <RightCircleOutlined /><h6 style={{marginBottom: "0px", marginLeft: "10px"}}>Additional Information</h6>
        </div>
      ),
      children: (
        <>
        <div>
          {/* <h5>Company Details</h5> */}
          <p>
            <b>Company Name:</b>{" "}
            {totalProducts?.productsellerinfo?.[0]?.company_name}
          </p>
          <p>
            <b>Website:</b> {totalProducts?.productsellerinfo?.[0]?.website}
          </p>
          <p>
            <b>Email:</b> {totalProducts?.productsellerinfo?.[0]?.email_id}
          </p>
          <p>
            <b>GST No:</b> {totalProducts?.productsellerinfo?.[0]?.gstin}
          </p>
          <p>
            <b>Contact Number:</b>{" "}
            {totalProducts?.productsellerinfo?.[0]?.mobile_number}
          </p>
          <p>
            <b>Company Addresss:</b>{" "}
            {totalProducts?.productsellerinfo?.[0]?.company_addresss} {totalProducts?.productsellerinfo?.[0]?.city}, {totalProducts?.productsellerinfo?.[0]?.state} -{" "}
            {totalProducts?.productsellerinfo?.[0]?.pin_code}
          </p>
          <p>
            <b>State:</b> {totalProducts?.productsellerinfo?.[0]?.state}
          </p>
        </div>
        </>
      ),
    },
    {
      key: "4",
      label: (
        <div className="label" style={{display: "flex", alignItems: "center"}}>
          <CheckCircleOutlined /><h6 style={{marginBottom: "0px", marginLeft: "10px"}}>Review</h6>
        </div>
      ),
      children: (
        <div className="review_section">
          <div className="review_left">
            {/* <h2>Review</h2> */}
            <Card
              bordered={true}
              className="Review_Section"
              title={reviews?.length + " " + "Reviews"}
              style={{ height: "400px", overflowX: "auto", width: "100%" }}
            >
              <List
                className="comment-list"
                itemLayout="horizontal"
                dataSource={reviews}
                renderItem={(item) => (
                  <li key={item?._id}>
                    <Comment
                      // actions={item?.actions}
                      author={item?.buyer_id?.buyer_name}
                      // avatar={Default}
                      content={item?.review}
                      datetime={moment
                        .utc(item?.createdAt)
                        .local()
                        .startOf("seconds")
                        .fromNow()}
                    />
                    <div className="rating_design">
                      <Rate disabled defaultValue={item?.rating} />
                    </div>
                  </li>
                )}
              />
            </Card>
            <br />
          </div>
        </div>
      ),
    },
  ];

  const onChangeTabs = (key) => {
    // console.log(key);
  };

  const login = async (values) => {
    setLoginLoading(true);
    let user;
    try {
      user = await api.create(
        dispatch,
        [userGetAllStart, userGetAllSuccess, userGetAllFail],
        "login",
        values
      );
    } catch (error) {
      message.error("Something went wrong");
      console.error("Login error:", error);
    }
    if (user?.status === 200) {
      // console.log("res--------->", user);
      message.success("Login Successful");
      localStorage.setItem("token", user?.data?.token);
      localStorage.setItem("user", JSON.stringify(user?.data?.data));
      localStorage.setItem("login", true);
      localStorage.setItem("buyer_name", values?.buyer_number);
      localStorage.setItem("buyer_pasword", values?.buyer_password);
      setLoginLoading(false);
      setOpen(false);
      window.location.reload();
    } else {
      message.error(user?.response?.data?.message);
      setLoginLoading(false);
    }
  };

  const handleOk = () => {
    loginForm.resetFields();
    setOpen(false);
  };
  const handleCancel = () => {
    loginForm.resetFields();
    setOpen(false);
  };
  const handleOk1 = () => {
    setOpen1(false);
  };
  const handleCancel1 = () => {
    setOpen1(false);
  };

  // console.log("specData 702", specData);
  // console.log("styles 703", styles);
  // console.log(specData?.[0]?.discount_id, 'specData?.[0]?.discount_id')

  const relatedProductClick = async (item, index) => {
    setSkeletonLoading(true);
    setFoundSpectId(null);
    setFoundSpectId1(null);
    setFoundSpectQty(null);
    setFoundSpectImg(null);
    setRelatedProClicked(true);

    // console.log("item", item);
    // console.log("index", index);
    const product_Id = item?.product_id?._id;

    const spec_ids = [];
    const detail_ids = [];

    item?.specification.map((e, i) => {
      spec_ids.push(e?.specification_id);
      detail_ids.push(e?._id);
    });
    // console.log("spec_ids", spec_ids);
    // console.log("detail_ids", detail_ids);

    const updatedParam = {
      detail_ids: detail_ids,
      spec_ids: spec_ids,
    };
    // console.log("updatedParam", updatedParam);
    // console.log("product_Id", product_Id);
    try {
      const res = await api.getproductspec(updatedParam, product_Id);
      const data = res?.data?.data?.productspec;
      setSpecData(data);
      setTotalProducts(res?.data?.data);
      setreviews(res?.data?.data?.reviews);
      setProducts(res?.data?.data?.product);

      const filteredSpecs = data?.map((spec) => {
        return spec?.specification?.map((insidespec) => {
          return insidespec?.specification_details;
        });
      });
      setFilterSpecs([...filteredSpecs.flat()]);
      setParam(updatedParam);
      setMainImage("");
      setSkeletonLoading(false);
      localStorage.setItem("spec_data", JSON.stringify(data));
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (err) {
      message.error("Something Went Wrong");
      setSkeletonLoading(false);
    }
  };

  const relatedProductClickCategory = async (item, index) => {
    console.log("item", item);
    // setSkeletonLoading(true);
    // setFoundSpectId(null);
    // setFoundSpectId1(null);
    // setFoundSpectQty(null);
    // setFoundSpectImg(null);
    // setRelatedProClicked(true);

    navigate(`/product/${item?.product_name}/${item?._id}`);
    window.location.reload();
  };

  // console.log(totalProducts?.product?.tax_percentage
  //   ?.tax_percentage / 100, '------');

  // useEffect(() => {
  //   if (totalProducts?.product?.specification) {

  //     const newSpecIds = [];
  //     const newDetailIds = [];

  //     totalProducts.product.specification.forEach((spec) => {
  //       const foundSpec = totalProducts?.productspec?.find(
  //         (prodSpec) =>
  //           prodSpec?.quantity > 0 &&
  //           prodSpec?.specification.some(
  //             (specDetail) =>
  //               specDetail?.specification_id === spec?.specification?._id
  //           )
  //       );

  //       // console.log("foundSpec??", foundSpec);

  //       if (foundSpec) {
  //         setFoundSpectId(foundSpec?._id);
  //         setFoundSpectId1(foundSpec?._id);
  //         setFoundSpectQty(foundSpec?.quantity);
  //         setFoundSpectImg(foundSpec?.product_images);
  //         // setFoundSpectImg1(foundSpec?.product_images?.[0]?.image_path);

  //         // Update initial param here instead of defaulting to 0th index
  //       const defaultSpecDetailId = foundSpec?.specification.find(
  //         (specDetail) => specDetail?.specification_id === spec?.specification?._id
  //       )?._id;

  //       // Update spec_ids and detail_ids arrays
  //       if (spec?.specification?._id && defaultSpecDetailId) {
  //         newSpecIds.push(spec?.specification?._id);
  //         newDetailIds.push(defaultSpecDetailId);
  //       }

  //       // // Update the param to reflect the found default specification detail ID
  //       // setParam((prev) => ({
  //       //   ...prev,
  //       //   spec_ids: [...prev.spec_ids, spec?.specification?._id],
  //       //   detail_ids: [...prev.detail_ids, defaultSpecDetailId],
  //       // }));
  //       }
  //     });

  //     // Update param with new specification and detail IDs
  //   setParam((prev) => ({
  //     ...prev,
  //     spec_ids: newSpecIds,
  //     detail_ids: newDetailIds,
  //   }));

  //   }
  // }, [totalProducts]);

  useEffect(() => {
    if (totalProducts?.product?.specification) {
      const newSpecIds = [];
      const newDetailIds = [];
      const allSpecImages = [];
      const imageArrays = [];

      const defaultProSpec1 = specData1?.filter(
        (item) => item?._id == productSpecId                                          
      )?.[0];  

      const isPricePresentindefaultProSpec1 =
      defaultProSpec1?.show_mrp === true && defaultProSpec1?.show_sp === false
              ? defaultProSpec1?.mrp != null
              : defaultProSpec1?.product_id?.show_common_mrp === true &&
              defaultProSpec1?.product_id?.show_common_sp === false
              ? defaultProSpec1?.product_id?.common_mrp != null
              : defaultProSpec1?.sp != null || defaultProSpec1?.product_id?.common_mrp != null;

      // Collect all image arrays from each spec
      totalProducts?.productspec.forEach((spec) => {
        const images =
          spec?.product_images?.map((img) => img?.image_path) || [];
        imageArrays.push(images);
      });

      // Find the maximum length of the image arrays
      const maxLength = Math.max(...imageArrays.map((arr) => arr.length));

      // Arrange images alternatively
      for (let i = 0; i < maxLength; i++) {
        imageArrays.forEach((images) => {
          if (images[i] !== undefined) {
            allSpecImages.push({
              image_path: images[i],
            });
          }
        });
      }

      // console.log("Alternately arranged images:", allSpecImages);

      setallSpectImg(allSpecImages);

      totalProducts.product.specification.forEach((spec) => {
        const sizeId = size?.[0]; // Check if size?.[0] is present

        let foundSpec;

        // console.log("totalProducts", totalProducts);

        // If sizeId exists, prioritize finding the specification that matches sizeId
        if (sizeId) {
          foundSpec = totalProducts?.productspec?.find((prodSpec) =>
            prodSpec?.specification.some(
              (specDetail) => specDetail?._id === sizeId
            )
          );
        }

        console.log("foundSpec---->", foundSpec);

        // If sizeId is not present or no match is found, fall back to the original logic
        // if (!foundSpec) {
        //   foundSpec = totalProducts?.productspec?.find(
        //     (prodSpec) =>
        //       console.log("prodSpec", prodSpec),
        //       prodSpec?.quantity > 0 &&
        //       prodSpec?.specification.some(
        //         (specDetail) =>
        //           specDetail?.specification_id === spec?.specification?._id
        //       )
        //   );
        // }

        if (!foundSpec) {
          foundSpec = totalProducts?.productspec?.find((prodSpec) => {
            console.log("prodSpec", prodSpec);

            const isPricePresent =
            prodSpec?.show_mrp === true && prodSpec?.show_sp === false
              ? prodSpec?.mrp != null
              : prodSpec?.product_id?.show_common_mrp === true &&
                prodSpec?.product_id?.show_common_sp === false
              ? prodSpec?.product_id?.common_mrp != null
              : prodSpec?.sp != null || prodSpec?.product_id?.common_mrp != null;
                
            return (
              prodSpec?.quantity > 0 &&
              isPricePresent
              &&
              prodSpec?.specification.some(
                (specDetail) =>
                  specDetail?.specification_id === spec?.specification?._id
              )
            );
          });
        }
        console.log("foundSpec---->", foundSpec);
        console.log("defaultProSpec1", defaultProSpec1);
        console.log("isPricePresentindefaultProSpec1", isPricePresentindefaultProSpec1);

        if (defaultProSpec1 && isPricePresentindefaultProSpec1) {
          if(defaultProSpec1?.quantity <= 0){
            setFoundSpectAll(foundSpec);
            setFoundSpectId(foundSpec?._id);
            setFoundSpectId1(foundSpec?._id);
            setFoundSpectQty(foundSpec?.quantity);
            setFoundSpectImg(foundSpec?.product_images); // Set the product images of the found spec
            // setFoundSpectImg1(foundSpec?.product_images?.[0]?.image_path);

            // Update initial param here instead of defaulting to 0th index
          const defaultSpecDetailId = foundSpec?.specification.find(
            (specDetail) =>
              specDetail?.specification_id === spec?.specification?._id
          )?._id;

          // Update spec_ids and detail_ids arrays
          if (spec?.specification?._id && defaultSpecDetailId) {
            newSpecIds.push(spec?.specification?._id);
            newDetailIds.push(defaultSpecDetailId);
          }
          } else {
            setFoundSpectAll(defaultProSpec1);
            setFoundSpectId(defaultProSpec1?._id);
            setFoundSpectId1(defaultProSpec1?._id);
            setFoundSpectQty(defaultProSpec1?.quantity);
            setFoundSpectImg(defaultProSpec1?.product_images); // Set the product images of the found spec
            // setFoundSpectImg1(foundSpec?.product_images?.[0]?.image_path);
  
            // Update initial param here instead of defaulting to 0th index
            const defaultSpecDetailId = defaultProSpec1?.specification.find(
              (specDetail) =>
                specDetail?.specification_id === spec?.specification?._id
            )?._id;
  
            // Update spec_ids and detail_ids arrays
            if (spec?.specification?._id && defaultSpecDetailId) {
              newSpecIds.push(spec?.specification?._id);
              newDetailIds.push(defaultSpecDetailId);
            }
          }
        } else if (foundSpec) {
          setFoundSpectAll(foundSpec);
          setFoundSpectId(foundSpec?._id);
          setFoundSpectId1(foundSpec?._id);
          setFoundSpectQty(foundSpec?.quantity);
          setFoundSpectImg(foundSpec?.product_images); // Set the product images of the found spec
          // setFoundSpectImg1(foundSpec?.product_images?.[0]?.image_path);

          // Update initial param here instead of defaulting to 0th index
          const defaultSpecDetailId = foundSpec?.specification.find(
            (specDetail) =>
              specDetail?.specification_id === spec?.specification?._id
          )?._id;

          // Update spec_ids and detail_ids arrays
          if (spec?.specification?._id && defaultSpecDetailId) {
            newSpecIds.push(spec?.specification?._id);
            newDetailIds.push(defaultSpecDetailId);
          }
        }
       
      });

      // Update param with new specification and detail IDs
      setParam((prev) => ({
        ...prev,
        spec_ids: newSpecIds,
        detail_ids: newDetailIds,
      }));
    }
  }, [totalProducts]);

  const RotateIcon = styled.div`
    transition: transform 0.3s ease;
    ${({ isActive }) => isActive && "transform: rotate(180deg);"}
  `;

  // console.log("foundSpectQty", foundSpectQty);
  // console.log("allSpectImg", allSpectImg);

  return (
    <>
      {initialLoading == true ? (
        <Spin
          tip="Loading"
          size="large"
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: 999,
          }}
        ></Spin>
      ) : (
        <>
          <ProductSection>
            {products && (
              <div className="wrapper" style={{ padding: "60px 40px" }}>
                <div className="breadcrumb_section">
                  <Breadcrumb items={item} />
                </div>

                <div className="individual_product_section">
                  <div className="individual_product_align">
                    <div className="individual_product_left">
                      {/* {console.log("specData", specData?.length)} */}
                      {/* {specData?.length <= 0 && (
                    <Alert
                      style={{ marginTop: "200px" }}
                      message="Stock Not Available."
                      type="warning"
                      showIcon
                    />
                  )} */}
                      {/* {specData?.length <= 0 && (
                      <Spin
                        tip="Loading"
                        size="large"
                        style={{
                          position: "fixed",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          zIndex: 999,
                        }}
                      ></Spin>
                    )} */}
                      {/* {console.log("specData---?", specData)} */}
                      {specData?.slice(0, 1)?.map((item, index) => (
                        // console.log("item----------->", item),
                        <div>
                          {skeletonLoading === true ? (
                            <Skeleton active />
                          ) : (
                            <>
                              <div className="individual_product_left_wrap">
                                <div className="thump_image">
                                  {/* Slider Navigation */}
                                  <div className="slider__prev">
                                    <UpOutlined />
                                  </div>

                                  <Swiper
                                    onSwiper={() =>
                                      setThumbsSwiper(thumbsSwiper)
                                    }
                                    mousewheel={true}
                                    slidesPerView={
                                      window.innerWidth <= 540 ? 2 : 3
                                    }
                                    direction="vertical"
                                    // spaceBetween={10}
                                    className="swiper"
                                    navigation={{
                                      nextEl: ".slider__next",
                                      prevEl: ".slider__prev",
                                    }}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[Navigation, Thumbs, Mousewheel]}
                                    style={{ userSelect: "none" }}
                                  >
                                    {(() => {
                                      // Determine which images to use
                                      // const images = (specData.length > 1 && item?.product_id?.common_mrp) ? allSpectImg : foundSpectImg || item?.product_images;

                                      const images =
                                        foundSpectImg || item?.product_images;

                                      return images?.map((e, i) => {
                                        // Ensure e is valid and has image_path
                                        if (e && e.image_path) {
                                          return (
                                            <SwiperSlide
                                              key={e?._id || i}
                                              className="thump_image_box"
                                              onClick={() =>
                                                handleThumbnailClick(
                                                  e.image_path
                                                )
                                              }
                                              style={{ userSelect: "none" }}
                                            >
                                              <img
                                                preview={false}
                                                // height={100}
                                                src={
                                                  api.rootUrl1 + e.image_path
                                                }
                                                alt={`Thumbnail ${e?._id || i}`}
                                                // style={{aspectRatio: '1/1'}}
                                              />
                                            </SwiperSlide>
                                          );
                                        }
                                        // Return null if e is invalid
                                        return null;
                                      });
                                    })()}
                                  </Swiper>

                                  {/* Modal for Video */}
                                  <Modal
                                    title={totalProducts?.product?.product_name}
                                    open={isVideoVisible}
                                    onCancel={() => setVideoVisible(false)}
                                    footer={null}
                                    width={400}
                                    centered
                                  >
                                    <br />
                                    <MediaPlayer
                                      title="Sprite Fight"
                                      src={
                                        api.rootUrl1 +
                                        specData[0]?.product_video[0]
                                          ?.video_path
                                      }
                                    >
                                      <MediaProvider />
                                      <DefaultVideoLayout
                                        thumbnails={VideoPlay}
                                        icons={defaultLayoutIcons}
                                      />
                                    </MediaPlayer>
                                  </Modal>

                                  {/* Slider Navigation */}
                                  <div className="slider__next">
                                    <DownOutlined />
                                  </div>
                                </div>

                                <div className="main_image">
                                  <Image.PreviewGroup
                                    // items={item?.product_images?.map(
                                    //   (image) => ({
                                    //     key: image?._id,
                                    //     src:
                                    //       api.rootUrl1 + image?.image_path ||
                                    //       api.rootUrl1 + products?.images,
                                    //   })
                                    // )}
                                  >
                                    <Image
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        objectPosition: "center",
                                        userSelect: "none",
                                      }}
                                      src={
                                        api.rootUrl1 +
                                        (mainImage ||
                                          (foundSpectImg
                                            ? foundSpectImg?.[0]?.image_path
                                            : item?.product_images?.[0]
                                                ?.image_path))
                                      }
                                      // preview={{
                                      //   images: item?.product_images?.map(
                                      //     (image) => ({
                                      //       src:
                                      //         api.rootUrl1 +
                                      //           image?.image_path ||
                                      //         api.rootUrl1 + products?.images,
                                      //     })
                                      //   ),
                                      //   visible: true,
                                      // }}
                                    />
                                  </Image.PreviewGroup>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                    {/* {
                    const sizeId1 = size?.[0];
                  } */}
                    {
                      // console.log("size?.[0]", size?.[0])
                      // console.log("specDataspecData", specData?.filter((item) => item?._id))
                    }
                    {
                      // console.log("size?.[0]", size?.[0])
                      // console.log("specDataspecData", specData)
                    }

                    <div className="individual_product_right">
                      <div className="individual_product_right_wrap">
                        <h1>
                          {specData?.[0]?.product_id?.product_name
                            ? specData?.[0]?.product_id?.product_name
                            : totalProducts?.product?.product_name}
                        </h1>
                        {/* <h1>{specData?.filter((item) => item?.specification?.map((item1) => item1?._id == size?.[0])).map((item) => item?.product_id?.product_name)}</h1> */}
                        {/* {
                        console.log("totalProducts?.product?.tax_percentage?.tax_percentage", totalProducts?.productspec?.[0]?.discount_id ? totalProducts?.productspec?.[0]?.discount_id?.discount_id?.discount : 0)
                        } */}
                        {
                          // console.log("totalProducts========>", totalProducts?.product?.tax_percentage?.tax_percentage)
                          // console.log("specData", specData)
                          // console.log("specData?????", (dayjs(specData?.[0]?.discount_id?.discount_id?.discount_valid_to).format("DD-MMM-YYYYhh:mm:ss") > dayjs(CurrentDate).format("DD-MMM-YYYYhh:mm:ss")))
                        }
                        {/* {console.log("specData?.[0]?.discount_id?.discount_id", specData?.[0]?.discount_id?.discount_id)} */}
                        {/* {console.log("totalProducts?.product?.tax_percentage?.tax_percentage / 100", totalProducts?.product?.tax_percentage?.tax_percentage / 100)} */}
                        <div>
                          <div>
                            <div className="sku">
                              {console.log("specData---->", specData?.[0])}
                              {console.log("foundSpectAll---->", foundSpectAll)}
                              SKU:{" "}
                              {(foundSpectAll ? foundSpectAll : specData?.[0])?.sku
                                ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sku
                                : specData?.[0]?.product_id?.common_sku || ""}
                            </div>
                            {/* {console.log("specData?.[0]", specData?.[0])} */}
                            {(foundSpectAll ? foundSpectAll : specData?.[0])?.show_mrp == true &&
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.show_sp == false && (
                                <div className="price">
                                  {/* <div></div> */}
                                  {(dayjs((foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount_valid_to).isAfter(dayjs(CurrentDate)) 
                                    && (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status) ? (
                                      <div className="discount_strike" style={{textDecoration: "line-through"}}>
                                         M.R.P: {styles?.currency + 
                                         (isDecimal?.isDecimal === false ? Math.round((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp +
                                                 ((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp *
                                                     totalProducts?.product
                                                       ?.tax_percentage
                                                       ?.tax_percentage) /
                                                     100) : ((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp +
                                                 ((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp * 
                                                     totalProducts?.product
                                                       ?.tax_percentage 
                                                       ?.tax_percentage) / 100).toFixed(isDecimal?.Decimal_points))}
                                      </div>
                                    ) : (<div></div>)}
                                  <div className="sp">
                                    {/* <span>M.R.P: </span> */}
                                    {(dayjs((foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount_valid_to).isAfter(dayjs(CurrentDate)) 
                                          && (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status) ? (
                                            <span style={{ fontSize: "14px" }}>
                                              Deal Price:{" "}
                                            </span>
                                          ) : (
                                            <span style={{ fontSize: "14px" }}>
                                              M.R.P:{" "}
                                            </span>
                                        )}
                                    {(foundSpectAll ? foundSpectAll : specData?.[0])?.mrp
                                      ? styles?.currency +
                                        (isDecimal?.isDecimal === false
                                          ? (
                                              // (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp +
                                              //   (specData?.[0]?.mrp *
                                              //     totalProducts?.product
                                              //       ?.tax_percentage
                                              //       ?.tax_percentage) /
                                              //     100
                                            // console.log("i am heree")
                                              (() => {
                                                const discountValidTo =
                                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                    ?.discount_id
                                                    ?.discount_valid_to;
                                                const discount =
                                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                    ?.discount_id?.discount;
                                                const discountType =
                                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                    ?.discount_id?.discounttype;
                                                const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp;
                                                const taxPercentage =
                                                  totalProducts?.product
                                                    ?.tax_percentage
                                                    ?.tax_percentage / 100;
                                                const discStatus =
                                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                    ?.discount_id?.status;
    
                                                const isDiscountValid = dayjs(
                                                  discountValidTo
                                                ).isAfter(dayjs(CurrentDate));
    
                                                if (sp) {
                                                  let finalPrice;
    
                                                  if (
                                                    isDiscountValid &&
                                                    discStatus
                                                  ) {
                                                    if (
                                                      discountType === "Percentage"
                                                    ) {
                                                      finalPrice =
                                                        (sp -
                                                          sp * (discount / 100)) *
                                                        (1 + taxPercentage);
                                                    } else if (
                                                      discountType === "Price"
                                                    ) {
                                                      finalPrice =
                                                        (sp - discount) *
                                                        (1 + taxPercentage);
                                                    } else {
                                                      finalPrice =
                                                        sp * (1 + taxPercentage);
                                                    }
                                                  } else {
                                                    finalPrice =
                                                      sp * (1 + taxPercentage);
                                                  }
    
                                                  return (                                                    
                                                    (isDecimal?.isDecimal === false
                                                      ? Math.round(finalPrice)
                                                      : finalPrice.toFixed(
                                                          isDecimal?.Decimal_points
                                                        ))
                                                  );
                                                } else {
                                                  return null; // Stock not available
                                                }
                                              })()
                                            )
                                          : (
                                              // specData?.[0]?.mrp +
                                              // (specData?.[0]?.mrp *
                                              //   totalProducts?.product
                                              //     ?.tax_percentage
                                              //     ?.tax_percentage) /
                                              //   100
                                              // console.log("i am theree")
                                              (() => {
                                                const discountValidTo =
                                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                    ?.discount_id
                                                    ?.discount_valid_to;
                                                const discount =
                                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                    ?.discount_id?.discount;
                                                const discountType =
                                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                    ?.discount_id?.discounttype;
                                                const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp;
                                                const taxPercentage =
                                                  totalProducts?.product
                                                    ?.tax_percentage
                                                    ?.tax_percentage / 100;
                                                const discStatus =
                                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                    ?.discount_id?.status;
    
                                                const isDiscountValid = dayjs(
                                                  discountValidTo
                                                ).isAfter(dayjs(CurrentDate));
    
                                                if (sp) {
                                                  let finalPrice;
    
                                                  if (
                                                    isDiscountValid &&
                                                    discStatus
                                                  ) {
                                                    if (
                                                      discountType === "Percentage"
                                                    ) {
                                                      finalPrice =
                                                        (sp -
                                                          sp * (discount / 100)) *
                                                        (1 + taxPercentage);
                                                    } else if (
                                                      discountType === "Price"
                                                    ) {
                                                      finalPrice =
                                                        (sp - discount) *
                                                        (1 + taxPercentage);
                                                    } else {
                                                      finalPrice =
                                                        sp * (1 + taxPercentage);
                                                    }
                                                  } else {
                                                    finalPrice =
                                                      sp * (1 + taxPercentage);
                                                  }
    
                                                  return (                                                    
                                                    (isDecimal?.isDecimal === false
                                                      ? Math.round(finalPrice)
                                                      : finalPrice.toFixed(
                                                          isDecimal?.Decimal_points
                                                        ))
                                                  );
                                                } else {
                                                  return null; // Stock not available
                                                }
                                              })()

                                            ))
                                      : // `Stock Not Available`
                                        null}
                                        <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#888",
                                        }}
                                      >
                                        &nbsp;(* Inclusive of tax)
                                      </span>
                                  </div>
                                </div>
                              )}

                            {(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp != null &&
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp != ""
                              ? (
                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_mrp == true &&
                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_sp == false
                                ) ? (
                                  <div className="price">
                                    {(dayjs((foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount_valid_to).isAfter(dayjs(CurrentDate)) 
                                    && (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status) ? (
                                      <div className="discount_strike" style={{textDecoration: "line-through"}}>
                                         M.R.P: {styles?.currency + 
                                         (isDecimal?.isDecimal === false ? Math.round((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp +
                                                 ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                     ?.common_mrp *
                                                     totalProducts?.product
                                                       ?.tax_percentage
                                                       ?.tax_percentage) /
                                                     100) : ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp +
                                                 ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                     ?.common_mrp * 
                                                     totalProducts?.product
                                                       ?.tax_percentage 
                                                       ?.tax_percentage) / 100).toFixed(isDecimal?.Decimal_points))}
                                      </div>
                                    ) : (<div></div>)}
                                    <div className="sp">
                                      {/* <span style={{ fontSize: "14px" }}> */}
                                        {(dayjs((foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount_valid_to).isAfter(dayjs(CurrentDate)) 
                                          && (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status) ? (
                                            <span style={{ fontSize: "14px" }}>
                                              Deal Price:{" "}
                                            </span>
                                          ) : (
                                            <span style={{ fontSize: "14px" }}>
                                              M.R.P:{" "}
                                            </span>
                                        )}
                                        {/* M.R.P:{" "} */}
                                      {/* </span> */}
                                      {(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp
                                        ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? (
                                                // specData?.[0]?.product_id
                                                //   ?.common_mrp +
                                                //   (specData?.[0]?.product_id
                                                //     ?.common_mrp *
                                                //     totalProducts?.product
                                                //       ?.tax_percentage
                                                //       ?.tax_percentage) /
                                                //     100

                                                (() => {
                                                  const discountValidTo =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id
                                                      ?.discount_valid_to;
                                                  const discount =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.discount;
                                                  const discountType =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.discounttype;
                                                  const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp;
                                                  const taxPercentage =
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage / 100;
                                                  const discStatus =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.status;
      
                                                  const isDiscountValid = dayjs(
                                                    discountValidTo
                                                  ).isAfter(dayjs(CurrentDate));
      
                                                  if (sp) {
                                                    let finalPrice;
      
                                                    if (
                                                      isDiscountValid &&
                                                      discStatus
                                                    ) {
                                                      if (
                                                        discountType === "Percentage"
                                                      ) {
                                                        finalPrice =
                                                          (sp -
                                                            sp * (discount / 100)) *
                                                          (1 + taxPercentage);
                                                      } else if (
                                                        discountType === "Price"
                                                      ) {
                                                        finalPrice =
                                                          (sp - discount) *
                                                          (1 + taxPercentage);
                                                      } else {
                                                        finalPrice =
                                                          sp * (1 + taxPercentage);
                                                      }
                                                    } else {
                                                      finalPrice =
                                                        sp * (1 + taxPercentage);
                                                    }
      
                                                    return (                                                      
                                                      (isDecimal?.isDecimal === false
                                                        ? Math.round(finalPrice)
                                                        : finalPrice.toFixed(
                                                            isDecimal?.Decimal_points
                                                          ))
                                                    );
                                                  } else {
                                                    return null; // Stock not available
                                                  }
                                                })()
                                              )
                                            : (
                                                // specData?.[0]?.product_id
                                                //   ?.common_mrp +
                                                // (specData?.[0]?.product_id
                                                //   ?.common_mrp *
                                                //   totalProducts?.product
                                                //     ?.tax_percentage
                                                //     ?.tax_percentage) /
                                                //   100

                                                (() => {
                                                  const discountValidTo =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id
                                                      ?.discount_valid_to;
                                                  const discount =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.discount;
                                                  const discountType =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.discounttype;
                                                  const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp;
                                                  const taxPercentage =
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage / 100;
                                                  const discStatus =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.status;
      
                                                  const isDiscountValid = dayjs(
                                                    discountValidTo
                                                  ).isAfter(dayjs(CurrentDate));
      
                                                  if (sp) {
                                                    let finalPrice;
      
                                                    if (
                                                      isDiscountValid &&
                                                      discStatus
                                                    ) {
                                                      if (
                                                        discountType === "Percentage"
                                                      ) {
                                                        finalPrice =
                                                          (sp -
                                                            sp * (discount / 100)) *
                                                          (1 + taxPercentage);
                                                      } else if (
                                                        discountType === "Price"
                                                      ) {
                                                        finalPrice =
                                                          (sp - discount) *
                                                          (1 + taxPercentage);
                                                      } else {
                                                        finalPrice =
                                                          sp * (1 + taxPercentage);
                                                      }
                                                    } else {
                                                      finalPrice =
                                                        sp * (1 + taxPercentage);
                                                    }
      
                                                    return (                                                      
                                                      (isDecimal?.isDecimal === false
                                                        ? Math.round(finalPrice)
                                                        : finalPrice.toFixed(
                                                            isDecimal?.Decimal_points
                                                          ))
                                                    );
                                                  } else {
                                                    return null; // Stock not available
                                                  }
                                                })()

                                              ))
                                        :  // `Stock Not Available`
                                          null}
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#888",
                                        }}
                                      >
                                        &nbsp;(* Inclusive of tax)
                                      </span>
                                    </div>
                                  </div>
                                ) :
                                (
                                  <div className="price">
                                    {/* {console.log("i ammmmmm onnnn")} */}
                                    <div 
                                    className="mrp" 
                                    // style={{ textDecoration: "line-through"}}
                                    >                                      
                                      <span>M.R.P: </span>
                                      {(foundSpectAll ? foundSpectAll : specData?.[0])?.mrp
                                        ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? Math.round(
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp +
                                                  ((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp *
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage) /
                                                    100
                                              )
                                            : (
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp +
                                                ((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp *
                                                  totalProducts?.product
                                                    ?.tax_percentage
                                                    ?.tax_percentage) /
                                                  100
                                              ).toFixed(
                                                isDecimal?.Decimal_points
                                              ))
                                      :(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp
                                      ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? Math.round(
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp +
                                                  ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                    ?.common_mrp *
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage) /
                                                    100
                                              )
                                            : (
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp +
                                                ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp *
                                                  totalProducts?.product
                                                    ?.tax_percentage
                                                    ?.tax_percentage) /
                                                  100
                                              ).toFixed(
                                                isDecimal?.Decimal_points
                                              ))
                                          : // `Stock Not Available`
                                          null
                                      }                                     
                                    </div>

                                    <div className="sp">
                                      <span style={{ fontSize: "14px" }}>
                                        Deal Price:{" "}
                                      </span>
                                      {specData?.length > 1 && !foundSpectAll
                                        ? (() => {
                                            const filteredSpecData =
                                              specData?.filter((item) =>
                                                item?.specification?.some(
                                                  (spec) =>
                                                    spec?._id === size?.[0]
                                                )
                                              );

                                            if (filteredSpecData?.length > 0) {
                                              const discountValidTo =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discount_valid_to;
                                              const discount =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discount;
                                              const discountType =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discounttype;
                                              const sp = filteredSpecData?.[0]?.sp ? filteredSpecData?.[0]?.sp : filteredSpecData?.[0]?.product_id?.common_sp;
                                              const taxPercentage =
                                                totalProducts?.product
                                                  ?.tax_percentage
                                                  ?.tax_percentage / 100;
                                              const discStatus =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.status;

                                              const isDiscountValid = dayjs(
                                                discountValidTo
                                              ).isAfter(dayjs(CurrentDate));

                                              if (sp) {
                                                let finalPrice;

                                                if (
                                                  isDiscountValid &&
                                                  discStatus
                                                ) {
                                                  if (
                                                    discountType ===
                                                    "Percentage"
                                                  ) {
                                                    finalPrice =
                                                      (sp -
                                                        sp * (discount / 100)) *
                                                      (1 + taxPercentage);
                                                  } else if (
                                                    discountType === "Price"
                                                  ) {
                                                    finalPrice =
                                                      (sp - discount) *
                                                      (1 + taxPercentage);
                                                  } else {
                                                    finalPrice =
                                                      sp * (1 + taxPercentage);
                                                  }
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }

                                                return (
                                                  styles?.currency +
                                                  (isDecimal?.isDecimal ===
                                                  false
                                                    ? Math.round(finalPrice)
                                                    : finalPrice.toFixed(
                                                        isDecimal?.Decimal_points
                                                      ))
                                                );
                                              } else {
                                                return null; // Stock not available
                                              }
                                            } else {
                                              const discountValidTo =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id
                                                  ?.discount_valid_to;
                                              const discount =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.discount;
                                              const discountType =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.discounttype;
                                              const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.sp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_sp;
                                              const taxPercentage =
                                                totalProducts?.product
                                                  ?.tax_percentage
                                                  ?.tax_percentage / 100;
                                              const discStatus =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.status;
                                              const isDiscountValid = dayjs(
                                                discountValidTo
                                              ).isAfter(dayjs(CurrentDate));

                                              if (sp) {
                                                let finalPrice;

                                                if (
                                                  isDiscountValid &&
                                                  discStatus
                                                ) {
                                                  if (
                                                    discountType ===
                                                    "Percentage"
                                                  ) {
                                                    finalPrice =
                                                      (sp -
                                                        sp * (discount / 100)) *
                                                      (1 + taxPercentage);
                                                  } else if (
                                                    discountType === "Price"
                                                  ) {
                                                    finalPrice =
                                                      (sp - discount) *
                                                      (1 + taxPercentage);
                                                  } else {
                                                    finalPrice =
                                                      sp * (1 + taxPercentage);
                                                  }
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }

                                                return (
                                                  styles?.currency +
                                                  (isDecimal?.isDecimal ===
                                                  false
                                                    ? Math.round(finalPrice)
                                                    : finalPrice.toFixed(
                                                        isDecimal?.Decimal_points
                                                      ))
                                                );
                                              } else {
                                                return null; // Stock not available
                                              }
                                            }
                                          })()
                                        : (() => {
                                            const discountValidTo =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id
                                                ?.discount_valid_to;
                                            const discount =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.discount;
                                            const discountType =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.discounttype;
                                            const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.sp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_sp;
                                            const taxPercentage =
                                              totalProducts?.product
                                                ?.tax_percentage
                                                ?.tax_percentage / 100;
                                            const discStatus =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.status;

                                            const isDiscountValid = dayjs(
                                              discountValidTo
                                            ).isAfter(dayjs(CurrentDate));

                                            if (sp) {
                                              let finalPrice;

                                              if (
                                                isDiscountValid &&
                                                discStatus
                                              ) {
                                                if (
                                                  discountType === "Percentage"
                                                ) {
                                                  finalPrice =
                                                    (sp -
                                                      sp * (discount / 100)) *
                                                    (1 + taxPercentage);
                                                } else if (
                                                  discountType === "Price"
                                                ) {
                                                  finalPrice =
                                                    (sp - discount) *
                                                    (1 + taxPercentage);
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }
                                              } else {
                                                finalPrice =
                                                  sp * (1 + taxPercentage);
                                              }

                                              return (
                                                styles?.currency +
                                                (isDecimal?.isDecimal === false
                                                  ? Math.round(finalPrice)
                                                  : finalPrice.toFixed(
                                                      isDecimal?.Decimal_points
                                                    ))
                                              );
                                            } else {
                                              return null; // Stock not available
                                            }
                                          })()}
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#888",
                                        }}
                                      >
                                        {/* &nbsp;(* Exclusive of tax) */}
                                        &nbsp;(* Inclusive of tax)
                                      </span>
                                    </div>
                                  </div>
                                )
                              : (
                                !((foundSpectAll ? foundSpectAll : specData?.[0])?.show_mrp == true &&
                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.show_sp == false) &&
                                !(
                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_mrp == true &&
                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_sp == false
                                ) &&
                                  <div className="price">
                                    {/* {console.log("i ammmmmm innnnnn")} */}
                                    {/* <div className="sp">
                              {dayjs(
                                specData?.[0]?.discount_id?.discount_id
                                  ?.discount_valid_to
                              ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") >
                              dayjs(CurrentDate).format(
                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                              )
                                ? specData?.[0]?.sp
                                  ? styles?.currency +
                                    // parseFloat(
                                    //   specData?.[0]?.sp -
                                    //   specData?.[0]?.sp *
                                    //   (specData?.[0]?.discount_id && specData?.[0]?.discount_id
                                    //     ?.discount_id?.discount /
                                    //     100) +
                                    //   ((specData?.[0]?.sp -
                                    //     specData?.[0]?.sp *
                                    //     (specData?.[0]?.discount_id && specData?.[0]?.discount_id
                                    //       ?.discount_id?.discount /
                                    //       100)) *
                                    //     totalProducts?.product?.tax_percentage
                                    //       ?.tax_percentage) /
                                    //   100
                                    // ).toFixed(2)
                                    parseFloat(
                                      specData?.[0]?.discount_id?.discount_id
                                        ?.discount
                                        ? specData?.[0]?.sp +
                                            (specData?.[0]?.sp -
                                              specData?.[0]?.discount_id
                                                ?.discount_id?.discount /
                                                100) *
                                              (totalProducts?.product
                                                ?.tax_percentage
                                                ?.tax_percentage /
                                                100)
                                        : specData?.[0]?.sp +
                                            specData?.[0]?.sp *
                                              (totalProducts?.product
                                                ?.tax_percentage
                                                ?.tax_percentage /
                                                100)
                                    ).toFixed(2)
                                  : // `Stock Not Avalilable`
                                    null
                                : specData?.[0]?.sp
                                ? styles?.currency +
                                  parseFloat(
                                    specData?.[0]?.sp +
                                      specData?.[0]?.sp *
                                        (totalProducts?.product?.tax_percentage
                                          ?.tax_percentage /
                                          100)
                                  ).toFixed(2)
                                : // `Stock Not Avalilable`
                                  null}
                            </div> */}
                                    {/* <div className="sp">
                              {(() => {
                                const discountValidTo = specData?.[0]?.discount_id?.discount_id?.discount_valid_to;
                                const discount = specData?.[0]?.discount_id?.discount_id?.discount;
                                const discountType = specData?.[0]?.discount_id?.discount_id?.discounttype;
                                const sp = specData?.[0]?.product_id?.common_sp ? specData?.[0]?.product_id?.common_sp : specData?.[0]?.sp;
                                const taxPercentage = totalProducts?.product?.tax_percentage?.tax_percentage / 100;
                                const discStatus = specData?.[0]?.discount_id?.discount_id?.status

                                // console.log("specData?.[0]?.discount_id?.discount_id", specData?.[0]?.discount_id?.discount_id?.status);

                                // Check if the discount is valid
                                const isDiscountValid = dayjs(discountValidTo).isAfter(dayjs(CurrentDate));

                                if (sp) {
                                  let finalPrice;

                                  if (isDiscountValid && discStatus) {
                                    if (discountType == "Percentage") {
                                      // Percentage discount calculation
                                      finalPrice = (sp - (sp * (discount / 100))) * (1 + taxPercentage);
                                    } else if (discountType == "Price") {
                                      // Flat price discount calculation
                                      finalPrice = (sp - discount) * (1 + taxPercentage);
                                    } else {
                                      // No valid discount type, just apply tax
                                      finalPrice = sp * (1 + taxPercentage);
                                    }
                                  } else {
                                    // No valid discount, apply only tax
                                    finalPrice = sp * (1 + taxPercentage);
                                  }

                                  return styles?.currency + finalPrice.toFixed(2);
                                } else {
                                  return null; // Stock not available
                                }
                              })()}
                            </div> */}

                                    <div className="mrp"
                                      // style={{textDecoration: "line-through"}}
                                    >
                                      {/* {specData?.[0]?.mrp
                                ? styles?.currency +
                                  (
                                    specData?.[0]?.mrp +
                                    (specData?.[0]?.mrp *
                                      totalProducts?.product?.tax_percentage
                                        ?.tax_percentage) /
                                      100
                                  ).toFixed(2)
                                : // `Stock Not Avalilable`
                                  null} */}
                                      <span>M.R.P: </span>
                                      {(foundSpectAll ? foundSpectAll : specData?.[0])?.mrp
                                        ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? Math.round(
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp +
                                                  ((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp *
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage) /
                                                    100
                                              )
                                            : (
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp +
                                                ((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp *
                                                  totalProducts?.product
                                                    ?.tax_percentage
                                                    ?.tax_percentage) /
                                                  100
                                              ).toFixed(
                                                isDecimal?.Decimal_points
                                              ))
                                      :(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp
                                      ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? Math.round(
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp +
                                                  ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                    ?.common_mrp *
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage) /
                                                    100
                                              )
                                            : (
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp +
                                                ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp *
                                                  totalProducts?.product
                                                    ?.tax_percentage
                                                    ?.tax_percentage) /
                                                  100
                                              ).toFixed(
                                                isDecimal?.Decimal_points
                                              ))
                                          : // `Stock Not Available`
                                          null

                                      }

                                      {/* {(() => {
                                    const filteredSpecData = specData?.filter(item =>
                                      item?.specification?.some(spec => spec?._id === size?.[0])
                                    );

                                    if (filteredSpecData?.length > 0) {
                                      const commonMrp = filteredSpecData?.[0]?.product_id?.common_mrp;
                                      const mrp = filteredSpecData?.[0]?.mrp;
                                      const taxPercentage = totalProducts?.product?.tax_percentage?.tax_percentage / 100;

                                      if (commonMrp) {
                                        return (
                                          styles?.currency +
                                          (commonMrp + commonMrp * taxPercentage).toFixed(2)
                                        );
                                      } else if (mrp) {
                                        return (
                                          styles?.currency +
                                          (mrp + mrp * taxPercentage).toFixed(2)
                                        );
                                      } else {
                                        return null; // Stock Not Available
                                      }
                                    } else {
                                      return null; // No matching specification
                                    }
                                  })()} */}
                                    </div>

                                    <div className="sp">
                                      <span style={{ fontSize: "14px" }}>
                                        Deal Price:{" "}
                                      </span>
                                      {specData?.length > 1 && !foundSpectAll
                                        ? (() => {
                                            const filteredSpecData =
                                              specData?.filter((item) =>
                                                item?.specification?.some(
                                                  (spec) =>
                                                    spec?._id === size?.[0]
                                                )
                                              );

                                            if (filteredSpecData?.length > 0) {
                                              const discountValidTo =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discount_valid_to;
                                              const discount =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discount;
                                              const discountType =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discounttype;
                                              const sp = filteredSpecData?.[0]?.sp ? filteredSpecData?.[0]?.sp : filteredSpecData?.[0]?.product_id?.common_sp;
                                              const taxPercentage =
                                                totalProducts?.product
                                                  ?.tax_percentage
                                                  ?.tax_percentage / 100;
                                              const discStatus =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.status;

                                              const isDiscountValid = dayjs(
                                                discountValidTo
                                              ).isAfter(dayjs(CurrentDate));

                                              if (sp) {
                                                let finalPrice;

                                                if (
                                                  isDiscountValid &&
                                                  discStatus
                                                ) {
                                                  if (
                                                    discountType ===
                                                    "Percentage"
                                                  ) {
                                                    finalPrice =
                                                      (sp -
                                                        sp * (discount / 100)) *
                                                      (1 + taxPercentage);
                                                  } else if (
                                                    discountType === "Price"
                                                  ) {
                                                    finalPrice =
                                                      (sp - discount) *
                                                      (1 + taxPercentage);
                                                  } else {
                                                    finalPrice =
                                                      sp * (1 + taxPercentage);
                                                  }
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }

                                                return (
                                                  styles?.currency +
                                                  (isDecimal?.isDecimal ===
                                                  false
                                                    ? Math.round(finalPrice)
                                                    : finalPrice.toFixed(
                                                        isDecimal?.Decimal_points
                                                      ))
                                                );
                                              } else {
                                                return null; // Stock not available
                                              }
                                            } else {
                                              const discountValidTo =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id
                                                  ?.discount_valid_to;
                                              const discount =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.discount;
                                              const discountType =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.discounttype;
                                              const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.sp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_sp;
                                              const taxPercentage =
                                                totalProducts?.product
                                                  ?.tax_percentage
                                                  ?.tax_percentage / 100;
                                              const discStatus =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.status;
                                              const isDiscountValid = dayjs(
                                                discountValidTo
                                              ).isAfter(dayjs(CurrentDate));

                                              if (sp) {
                                                let finalPrice;

                                                if (
                                                  isDiscountValid &&
                                                  discStatus
                                                ) {
                                                  if (
                                                    discountType ===
                                                    "Percentage"
                                                  ) {
                                                    finalPrice =
                                                      (sp -
                                                        sp * (discount / 100)) *
                                                      (1 + taxPercentage);
                                                  } else if (
                                                    discountType === "Price"
                                                  ) {
                                                    finalPrice =
                                                      (sp - discount) *
                                                      (1 + taxPercentage);
                                                  } else {
                                                    finalPrice =
                                                      sp * (1 + taxPercentage);
                                                  }
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }

                                                return (
                                                  styles?.currency +
                                                  (isDecimal?.isDecimal ===
                                                  false
                                                    ? Math.round(finalPrice)
                                                    : finalPrice.toFixed(
                                                        isDecimal?.Decimal_points
                                                      ))
                                                );
                                              } else {
                                                return null; // Stock not available
                                              }
                                            }
                                          })()
                                        : (() => {
                                            const discountValidTo =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id
                                                ?.discount_valid_to;
                                            const discount =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.discount;
                                            const discountType =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.discounttype;
                                            const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.sp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_sp;
                                            const taxPercentage =
                                              totalProducts?.product
                                                ?.tax_percentage
                                                ?.tax_percentage / 100;
                                            const discStatus =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.status;

                                            const isDiscountValid = dayjs(
                                              discountValidTo
                                            ).isAfter(dayjs(CurrentDate));

                                            if (sp) {
                                              let finalPrice;

                                              if (
                                                isDiscountValid &&
                                                discStatus
                                              ) {
                                                if (
                                                  discountType === "Percentage"
                                                ) {
                                                  finalPrice =
                                                    (sp -
                                                      sp * (discount / 100)) *
                                                    (1 + taxPercentage);
                                                } else if (
                                                  discountType === "Price"
                                                ) {
                                                  finalPrice =
                                                    (sp - discount) *
                                                    (1 + taxPercentage);
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }
                                              } else {
                                                finalPrice =
                                                  sp * (1 + taxPercentage);
                                              }

                                              return (
                                                styles?.currency +
                                                (isDecimal?.isDecimal === false
                                                  ? Math.round(finalPrice)
                                                  : finalPrice.toFixed(
                                                      isDecimal?.Decimal_points
                                                    ))
                                              );
                                            } else {
                                              return null; // Stock not available
                                            }
                                          })()}
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#888",
                                        }}
                                      >
                                        {/* &nbsp;(* Exclusive of tax) */}
                                        &nbsp;(* Inclusive of tax)
                                      </span>
                                    </div>
                                  </div>
                                )}
                          </div>
                        </div>
                        {/* {dayjs(
                        specData?.[0]?.discount_id?.discount_id
                          ?.discount_valid_to
                      ).isAfter(
                        dayjs(CurrentDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                      ) ? (
                        <div style={{ color: "green" }}>
                          {
                            specData?.[0]?.discount_id?.discount_id
                              ?.discount_title
                          }{" "}
                          Discount{" "}
                          {specData?.[0]?.discount_id?.discount_id?.discount}%
                          Applied
                        </div>
                      ) : null} */}

                        {/* {((dayjs(
                          specData?.[0]?.discount_id?.discount_id?.discount_valid_to
                        ).isAfter(dayjs(CurrentDate))) && (specData?.[0]?.discount_id?.discount_id?.status == true)) ? (
                          <div style={{ color: "green" }}>
                            {specData?.[0]?.discount_id?.discount_id?.discount_title}                             
                            {specData?.[0]?.discount_id?.discount_id?.discounttype === "Percentage"
                              ? `${specData?.[0]?.discount_id?.discount_id?.discount}% Applied`
                              : `${styles?.currency}${specData?.[0]?.discount_id?.discount_id?.discount} Applied`}
                          </div>
                        ) : null} */}
                        {(() => {
                          // const mrp = specData?.[0]?.product_id?.common_mrp
                          //   ? specData?.[0]?.product_id?.common_mrp
                          //   : specData?.[0]?.mrp;

                          const taxPercentage =
                            totalProducts?.product?.tax_percentage
                              ?.tax_percentage / 100;
                          const discountValidTo =
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                              ?.discount_valid_to;
                          const isDiscountValid = dayjs(
                            discountValidTo
                          ).isAfter(dayjs(CurrentDate));
                          const discStatus =
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status;

                          const calculateMRP = () => {
                            const baseMRP = (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp;

                            const mrpWithTax =
                              baseMRP + baseMRP * taxPercentage;

                            return isDecimal?.isDecimal === false
                              ? Math.round(mrpWithTax)
                              : mrpWithTax.toFixed(isDecimal?.Decimal_points);
                          };

                          const spWithDiscount = () => {
                            const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.sp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_sp;

                            const discountValidTo =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                                ?.discount_valid_to;
                            const discount =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount;
                            const discountType =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                                ?.discounttype;
                            const discStatus =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status;
                            const isDiscountValid = dayjs(
                              discountValidTo
                            ).isAfter(dayjs(CurrentDate));

                            let finalPrice = sp * (1 + taxPercentage);

                            if (isDiscountValid && discStatus) {
                              if (discountType === "Percentage") {
                                // console.log("discountType in percentage", discountType);
                                finalPrice =
                                  (sp - sp * (discount / 100)) *
                                  (1 + taxPercentage);
                              } else if (discountType === "Price") {
                                // console.log("discountType in price", discountType);
                                finalPrice =
                                  (sp - discount) * (1 + taxPercentage);
                              }
                            }
                            // console.log("finalPrice", finalPrice);
                            return finalPrice;
                          };
                          // console.log("spWithDiscount()", spWithDiscount());
                          // console.log("mrp", mrp);
                          const mrpWithDiscount = () => {
                            const mrp = (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp;

                            const discountValidTo =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                                ?.discount_valid_to;
                            const discount =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount;
                            const discountType =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                                ?.discounttype;
                            const discStatus =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status;
                            const isDiscountValid = dayjs(
                              discountValidTo
                            ).isAfter(dayjs(CurrentDate));

                            let finalPrice = mrp * (1 + taxPercentage);

                            if (isDiscountValid && discStatus) {
                              if (discountType === "Percentage") {
                                finalPrice =
                                  (mrp - mrp * (discount / 100)) *
                                  (1 + taxPercentage);
                              } else if (discountType === "Price") {
                                finalPrice =
                                  (mrp - discount) * (1 + taxPercentage);
                              }
                            }
                            return finalPrice;
                          };
                          const mrp = calculateMRP();
                          console.log("(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id", (foundSpectAll ? foundSpectAll : specData?.[0]))
                          if ( (foundSpectAll ? foundSpectAll : specData?.[0])?.show_mrp == true &&
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.show_sp == false) {
                            savingsAmount = mrp - mrpWithDiscount();
                          } else if ( (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_mrp == true &&
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_sp == false) {
                            savingsAmount = mrp - mrpWithDiscount();
                          } else {
                            savingsAmount = mrp - spWithDiscount();
                          }
                          // savingsAmount = mrp - spWithDiscount();
                          if (isDiscountValid && discStatus) {
                            return (
                              <div>
                                {/* <div>MRP: {styles?.currency + mrp}</div> */}
                                {/* <div>SP with Discount: {styles?.currency + (isDecimal?.isDecimal === false ? Math.round(spWithDiscount()) : spWithDiscount().toFixed(isDecimal?.Decimal_points))}</div> */}
                                <div style={{ color: "green" }}>
                                  Amount Saved:{" "}
                                  {styles?.currency +
                                    (isDecimal?.isDecimal === false
                                      ? Math.round(savingsAmount)
                                      : savingsAmount.toFixed(
                                          isDecimal?.Decimal_points
                                        ))}
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })()}

                        {specData?.length > 1 && !foundSpectAll ? (
                          size?.[0] ? (
                            (() => {
                              const filteredSpecData = specData?.filter(
                                (item) =>
                                  item?.specification?.some(
                                    (spec) => spec?._id === size?.[0]
                                  )
                              );

                              if (filteredSpecData?.length > 0) {
                                const discountData =
                                  filteredSpecData?.[0]?.discount_id
                                    ?.discount_id;
                                const discountValidTo =
                                  discountData?.discount_valid_to;
                                const discountStatus = discountData?.status;
                                const discountTitle =
                                  discountData?.discount_title;
                                const discountType = discountData?.discounttype;
                                const discountValue = discountData?.discount;

                                // Check if discount is valid and active
                                const isDiscountValid =
                                  dayjs(discountValidTo).isAfter(
                                    dayjs(CurrentDate)
                                  ) && discountStatus === true;

                                if (isDiscountValid) {
                                  return (
                                    <div style={{ color: "green" }}>
                                      {discountTitle}{" "}
                                      {discountType === "Percentage"
                                        ? `${discountValue}% Applied`
                                        : `${styles?.currency}${discountValue} Applied`}
                                    </div>
                                  );
                                } else {
                                  return null; // No valid discount
                                }
                              } else {
                                return null; // No matching specification
                              }
                            })()
                          ) : (
                            (() => {
                              const filteredSpecData = specData?.filter(
                                (item) =>
                                  item?.specification?.some((spec) => spec?._id)
                              );

                              if (filteredSpecData?.length > 0) {
                                const discountData =
                                  filteredSpecData?.[0]?.discount_id
                                    ?.discount_id;
                                const discountValidTo =
                                  discountData?.discount_valid_to;
                                const discountStatus = discountData?.status;
                                const discountTitle =
                                  discountData?.discount_title;
                                const discountType = discountData?.discounttype;
                                const discountValue = discountData?.discount;

                                // Check if discount is valid and active
                                const isDiscountValid =
                                  dayjs(discountValidTo).isAfter(
                                    dayjs(CurrentDate)
                                  ) && discountStatus === true;

                                if (isDiscountValid) {
                                  return (
                                    <div style={{ color: "green" }}>
                                      {discountTitle}{" "}
                                      {discountType === "Percentage"
                                        ? `${discountValue}% Applied`
                                        : `${styles?.currency}${discountValue} Applied`}
                                    </div>
                                  );
                                } else {
                                  return null; // No valid discount
                                }
                              } else {
                                return null; // No matching specification
                              }
                            })()
                          )
                        ) : dayjs(
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                              ?.discount_valid_to
                          ).isAfter(dayjs(CurrentDate)) &&
                          (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status ==
                            true ? (
                          <div style={{ color: "green" }}>
                            {
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                                ?.discount_title
                            }
                            {(foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                              ?.discounttype === "Percentage"
                              ? `${(foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount}% Applied`
                              : `${styles?.currency}${(foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount} Applied`}
                          </div>
                        ) : null}

                        {/* <a
                        className="product_description"
                        href="#descriptionsec"
                        onClick={(e) => {
                          e.preventDefault();
                          const element = document.getElementById("descriptionsec");
                          const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                          const offset = window.innerHeight / 3; // Adjust this value to position the section in the middle or slightly above
                          window.scrollTo({
                            top: elementPosition - offset,
                            behavior: "smooth",
                          });
                        }}
                        style={{
                          textDecoration: "none",
                          overflowWrap: "anywhere",
                          display: "flex",
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: htmlContent?.split(" ").slice(0, 4).join(" ") + "...",
                          }}
                        />
                        <Button
                          size="small"
                          style={{
                            marginLeft: "8px", // Add some margin if needed      
                            backgroundColor: "#007bff", // Button background color
                            color: "#fff", // Button text color
                            border: "none", // Remove border
                            borderRadius: "4px", // Rounded corners
                            cursor: "pointer", // Pointer cursor on hover
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            const element = document.getElementById("descriptionsec");
                            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                            const offset = window.innerHeight / 3;
                            window.scrollTo({
                              top: elementPosition - offset,
                              behavior: "smooth",
                            });
                          }}
                        >
                          View more
                        </Button>
                      </a> */}
                      </div>
                      <Divider />

                      <div className="product_specification">
                        {console.log(
                          "totalProducts?.product?.specification",
                          totalProducts?.product?.specification
                        )}
                        {/* {console.log("(foundSpectAll ? foundSpectAll : specData?.[0])", (foundSpectAll ? foundSpectAll : specData?.[0]))} */}
                        {totalProducts?.product?.specification &&
                          (((specData?.[0])?.show_mrp == true &&
                            (specData?.[0])?.show_sp == false) ||
                          ((specData?.[0])?.product_id?.show_common_mrp == true &&
                            (specData?.[0])?.product_id?.show_common_sp ==
                              false) ||
                          []
                            // ? totalProducts?.product?.specification.slice(0, 2)
                            ? totalProducts?.product?.specification
                            : totalProducts?.product?.specification
                          )?.map((spec, specIndex) => {
                            const sizeId = size?.[0];

                            // console.log("size", size);
                            // console.log("sizeId", sizeId);
                            // console.log("spec----", spec);

                            // Check if the current row contains the sizeId
                            const currentRowContainsSizeId =
                              totalProducts?.productspec?.some((prodSpec) =>
                                prodSpec?.specification.some(
                                  (specDetail) =>
                                    specDetail?._id === sizeId &&
                                    specDetail?.specification_id ===
                                      spec?.specification?._id
                                )
                              );

                              const defaultProSpec1 = specData1?.filter(
                                (item) => item?._id == productSpecId                                          
                              )?.[0];

                              const isPricePresentindefaultProSpec1 =
                                defaultProSpec1?.show_mrp === true && defaultProSpec1?.show_sp === false
                                ? defaultProSpec1?.mrp != null
                                : defaultProSpec1?.product_id?.show_common_mrp === true &&
                                defaultProSpec1?.product_id?.show_common_sp === false
                                ? defaultProSpec1?.product_id?.common_mrp != null
                                : defaultProSpec1?.sp != null || defaultProSpec1?.product_id?.common_mrp != null;

                              // console.log("defaultProSpec1---", defaultProSpec1);

                            // Find the matching product specification with quantity > 0 as before
                            // const foundSpec = totalProducts?.productspec?.find(
                            //   (prodSpec) =>
                            //     prodSpec?.quantity > 0 &&
                            //     prodSpec?.specification.some(
                            //       (specDetail) =>
                            //         specDetail?.specification_id ===
                            //         spec?.specification?._id
                            //     )
                            // );

                            const foundSpec = totalProducts?.productspec?.find((prodSpec) => {
                              console.log("prodSpec", prodSpec);
                  
                              const isPricePresent =
                              prodSpec?.show_mrp === true && prodSpec?.show_sp === false
                                ? prodSpec?.mrp != null
                                : prodSpec?.product_id?.show_common_mrp === true &&
                                  prodSpec?.product_id?.show_common_sp === false
                                ? prodSpec?.product_id?.common_mrp != null
                                : prodSpec?.sp != null || prodSpec?.product_id?.common_mrp != null;
                                  
                              return (
                                prodSpec?.quantity > 0 &&
                                isPricePresent
                                &&
                                prodSpec?.specification.some(
                                  (specDetail) =>
                                    specDetail?.specification_id === spec?.specification?._id
                                )
                              );
                            });

                            // console.log("sizeId", sizeId);
                            // console.log("spec", spec);
                            // console.log("foundSpec????", foundSpec);

                            // Determine the default spec detail ID
                            // const defaultSpecDetailId = currentRowContainsSizeId
                            //   ? sizeId // If the current row contains sizeId, use it
                            //   : foundSpec?.specification.find(
                            //       (specDetail) =>
                            //         specDetail?.specification_id ==
                            //         spec?.specification?._id
                            //     )?._id; // Otherwise, fall back to the normal logic

                            //     console.log("defaultSpecDetailId", defaultSpecDetailId);

                            const defaultSpecDetailId = currentRowContainsSizeId
                              ? sizeId // If the current row contains sizeId, use it
                              : (defaultProSpec1?.quantity > 0 && isPricePresentindefaultProSpec1) ?  
                              defaultProSpec1?.specification.find(
                                (specDetail) =>
                                  specDetail?.specification_id ==
                                  spec?.specification?._id
                              )?._id
                              : foundSpec?.specification.find(
                                  (specDetail) =>
                                    specDetail?.specification_id ==
                                    spec?.specification?._id
                                )?._id; // Otherwise, fall back to the normal logic

                                // console.log("defaultSpecDetailId", defaultSpecDetailId);
                                // console.log("foundSpectAll?.specification[0]?._id", foundSpectAll?.specification[0]?._id);

                            // Check if common_mrp is present to handle "All" button logic
                            const isCommonMrpPresent =
                              totalProducts?.product?.common_mrp;

                            return (
                              <div key={spec?._id} className="col_flex">
                                <strong className="spec_title">
                                  {spec?.specification?.name} :
                                </strong>
                                {/* {console.log("spec?._id", spec?._id)} */}
                                {/* {console.log("productSpecId", productSpecId)} */}
                                {/* {console.log("spec?.specification_details[0]?._id", spec?.specification_details[0]?._id)} */}
                                {/* {console.log("defaultSpecDetailId", defaultSpecDetailId)} */}
                                <Radio.Group
                                  key={spec?._id}
                                  className="row_flex"
                                  optionType="button"
                                  buttonStyle="solid"
                                  // defaultValue={isCommonMrpPresent && specIndex === 0 && spec?.specification_details?.length > 1 ? "all" : defaultSpecDetailId || spec?.specification_details[0]?._id}
                                  // defaultValue={"6731feef11ee0b9bd6e2a111"}
                                  // defaultValue={`"${foundSpectAll?.specification[0]?._id}"`}
                                  // defaultValue={defaultSpecDetailId ? defaultSpecDetailId : spec?.specification_details[0]?._id }
                                  defaultValue={
                                    defaultSpecDetailId 
                                    ||
                                    spec?.specification_details[0]?._id
                                  }
                                >
                                  {/* Loop through specification details */}
                                  {spec?.specification_details?.map(
                                    (detail, index) => {
                                      // console.log("detail", detail);
                                      // console.log("specData1", specData1);
                                      // console.log("spec", spec);

                                      let colorId;

                                      if(spec?.specification?.name?.toLowerCase() == "color") {
                                        colorId = spec?.specification?._id;
                                      }

                                      // console.log("foundSpectAll", foundSpectAll);

                                      const defaultProSpec = specData1?.filter(
                                        (item) => item?._id == productSpecId                                          
                                      )?.[0];             

                                      const defaultProSpec1 = foundSpectAll?.specification?.[0]?._id;                   

                                      // console.log("defaultProSpec1", defaultProSpec1);

                                     

                                      const normalize = (str) =>
                                        str?.trim().toLowerCase();

                                      const compareSpecificationDetails = (
                                        specItemDetails,
                                        detailDetails
                                      ) => {
                                        const normalizedSpecItemDetails =
                                          normalize(specItemDetails);
                                        const normalizedDetailDetails =
                                          normalize(detailDetails);
                                        return (
                                          normalizedSpecItemDetails ===
                                          normalizedDetailDetails
                                        );
                                      };

                                      // Check if the current specification type is 'color'
                                      const isColorSpec = spec?.specification?.name?.toLowerCase() === "color";
                                      
                                      const defaultColorSpec = defaultProSpec?.specification?.filter((item) => 
                                        item?.specification_id == colorId
                                      )?.[0]?._id;

                                      // console.log("defaultColorSpec", defaultColorSpec)
                                      // console.log("isColorSpec", isColorSpec)

                                      // Check if the current specification detail has a valid combination
                                      const hasValidCombination =
                                        totalProducts?.productspec?.some(
                                          (prodSpec) =>
                                            prodSpec?.quantity > 0 &&
                                            prodSpec?.specification.some(
                                              (specDetail) =>
                                                compareSpecificationDetails(
                                                  specDetail?.specification_details,
                                                  detail?.specification_details
                                                )
                                            )
                                        );

                                      const quantity =
                                        totalProducts?.productspec?.reduce(
                                          (acc, prodSpec) => {
                                            if (
                                              prodSpec?.specification.some(
                                                (specDetail) =>
                                                  compareSpecificationDetails(
                                                    specDetail?.specification_details,
                                                    detail?.specification_details
                                                  )
                                              )
                                            ) {
                                              console.log("prodSpec-prices", prodSpec?.mrp, prodSpec?.sp, prodSpec?.product_id?.common_mrp, prodSpec?.product_id?.common_sp);
                                              console.log("prodSpec", prodSpec);
                                              return acc + prodSpec?.quantity;
                                            }
                                            return acc;
                                          },
                                          0
                                        );

                                        const isPricePresent = totalProducts?.productspec?.reduce(
                                          (acc, prodSpec) => {
                                            if (
                                              prodSpec?.specification.some((specDetail) =>
                                                compareSpecificationDetails(
                                                  specDetail?.specification_details,
                                                  detail?.specification_details
                                                )
                                              )
                                            ) {  
                                              if (prodSpec?.show_mrp === true && prodSpec?.show_sp === false) {
                                                return prodSpec?.mrp != null ? true : false;
                                              } else if (
                                                prodSpec?.product_id?.show_common_mrp === true &&
                                                prodSpec?.product_id?.show_common_sp === false
                                              ) {
                                                return prodSpec?.product_id?.common_mrp != null ? true : false;
                                              } else {
                                                return prodSpec?.sp != null
                                                  ? true
                                                  : prodSpec?.product_id?.common_mrp != null
                                                  ? true
                                                  : false;
                                              }
                                            }
                                            return acc;
                                          },
                                          false
                                        );
                                        

                                      // console.log("specData", specData);
                                      console.log("isPricePresent", isPricePresent);
                                      // console.log("detail?.specification_details", detail?.specification_details);
                                      // console.log("specDetail?.specification_details", specData1.map((item) => item));

                                      const matchingProductImage = specData1
                                        ?.filter((item) =>
                                          item?.specification?.some(
                                            (specDetail) =>
                                              specDetail?.specification_details ==
                                              detail?.specification_details
                                          )
                                        )
                                        ?.map(
                                          (matchedItem) =>
                                            matchedItem?.product_images?.[0]
                                        )
                                        ?.filter(Boolean)[0];

                                      // console.log("Matching Product Image:", matchingProductImage);

                                      return (
                                        <>
                                          {isColorSpec ? (
                                            <Radio.Button
                                            key={detail?._id}
                                            // value={detail?._id}
                                            value={detail?._id}
                                            defaultChecked={defaultColorSpec}
                                            // className={index === 0 ? "active" : ""}
                                            className={activeButtonId ? (activeButtonId === detail?._id ? "active" : "") : defaultColorSpec ? (defaultColorSpec === detail?._id ? "active" : "") : index === 0 ? "active" : "" }
                                            // disabled={
                                            //   specData?.[index]?.product_id?.common_stock
                                            //     ? specData?.[index]?.product_id?.common_stock <= 0
                                            //     : quantity <= 0
                                            // }
                                            disabled={
                                              quantity ? quantity <= 0 : specData?.[index]?.product_id?.common_stock <= 0                                              
                                            }
                                              // disabled={!hasValidCombination || specData?.[index]?.product_id?.common_stock ? specData?.[index]?.product_id?.common_stock <= 0 : quantity <= 0}

                                            onClick={() => {
                                              handleSpecClick(
                                                index,
                                                detail?._id,
                                                spec?.specification?._id,
                                                totalProducts?.product?._id
                                              );
                                              setCount(1);
                                              setActiveButtonId(detail?._id);
                                            }}
                                            style={{
                                              position: "relative",
                                              // pointerEvents:
                                              //   (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock <= 0 || quantity <= 0
                                              //     ? "none"
                                              //     : "auto",
                                              border: "none",
                                              outline: "none",
                                              width: "70px",  // Set width equal to image's width
                                              height: "60px", // Set height equal to image's height
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              background: "transparent",
                                              backgroundColor: activeButtonId ? (activeButtonId === detail?._id ? "#fff" : "#fff") : defaultColorSpec ? (defaultColorSpec === detail?._id ? "#fff" : "#fff") : index === 0 ? "#fff" : "#fff",
                                              // zIndex: 1000,
                                            }}
                                          >
                                            <img
                                              src={api.rootUrl1 + matchingProductImage?.image_path}
                                              alt={detail?.specification_details}
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                              }}
                                            />
                                            <div
                                              style={{
                                                // zIndex: 1000,
                                                color: activeButtonId ? (activeButtonId === detail?._id ? "blue" : "inherit") : defaultColorSpec ? (defaultColorSpec == detail?._id ? "blue" : "inherit") : index === 0 ? "blue" : "inherit", // Change color if active
                                                fontSize: "10px",
                                                textAlign: "center",
                                                // width: "135%",
                                                width: "100%",
                                                // marginLeft: "-15%",
                                                marginLeft: "0",
                                                lineHeight: "1em",
                                                height: "1em",
                                              }}
                                            >
                                              {detail?.specification_details}
                                            </div>
                                          </Radio.Button>
                                          ) : (
                                            // console.log("activeButtonId", activeButtonId),
                                            // console.log("defaultProSpec1??", defaultProSpec1),
                                            <Radio.Button
                                              key={`${detail?._id}-${index}`}
                                              value={detail?._id}
                                              // defaultChecked={defaultProSpec}
                                              defaultChecked={defaultProSpec1}
                                              type="primary"
                                              size="small"
                                              // className={index === 0 ? "active" : ""}
                                              // className={activeButtonId != null ? (activeButtonId === detail?._id ? "active" : "") : defaultProSpec1 ? (defaultProSpec1 == detail?._id ? "active" : "") : index == 0 ? "active" : "" }
                                              // disabled={!hasValidCombination || specData?.[index]?.product_id?.common_stock ? specData?.[index]?.product_id?.common_stock <= 0 : quantity <= 0}
                                              // disabled={
                                              //   specData?.[index]?.product_id
                                              //     ?.common_stock
                                              //     ? specData?.[index]
                                              //         ?.product_id
                                              //         ?.common_stock <= 0
                                              //     : quantity <= 0
                                              // }
                                              disabled={
                                                // quantity ? quantity <= 0 : specData?.[index]?.product_id?.common_stock <= 0                                              
                                                quantity ? (!isPricePresent ? true : quantity <= 0) : specData?.[index]?.product_id?.common_stock <= 0                                              
                                              }
                                              onClick={() => {
                                                handleSpecClick(
                                                  index,
                                                  detail?._id,
                                                  spec?.specification?._id,
                                                  totalProducts?.product?._id
                                                );
                                                setCount(1);
                                              }}
                                              style={{
                                                position: "relative",
                                                pointerEvents: (specData?.[0])
                                                  ?.product_id?.common_stock
                                                  ? (specData?.[0])?.product_id
                                                      ?.common_stock <= 0
                                                    ? "none"
                                                    : "auto"
                                                  : quantity <= 0
                                                  ? "none"
                                                  : "auto",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  opacity: (specData?.[0])
                                                    ?.product_id?.common_stock
                                                    ? (specData?.[0])?.product_id
                                                        ?.common_stock <= 0
                                                      ? 0.5
                                                      : 1
                                                    : quantity <= 0
                                                    ? 0.5
                                                    : 1,
                                                }}
                                              >
                                                {detail?.specification_details}
                                              </span>
                                              {/* {(specData?.[0]?.product_id?.common_stock ? (specData?.[0]?.product_id?.common_stock <= 0 || !specData?.[0]?.product_id?.common_stock) : (quantity <= 0 || !quantity)) && ( */}
                                              {(specData?.[index]?.product_id
                                                ?.common_stock
                                                ? specData?.[index]?.product_id
                                                    ?.common_stock <= 0 ||
                                                  !specData?.[index]?.product_id
                                                    ?.common_stock
                                                : (!isPricePresent ? true : quantity <= 0) ||
                                                  !quantity) && (
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    width: "30px",
                                                    height: "30px",
                                                    transform:
                                                      "translate(-50%, -50%)",
                                                    zIndex: 2,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      position: "absolute",
                                                      width: "170%",
                                                      height: "1px",
                                                      backgroundColor:
                                                        "#ced4da",
                                                      top: "50%",
                                                      left: "-10px",
                                                      transform:
                                                        "rotate(34deg)",
                                                    }}
                                                  />
                                                  <div
                                                    style={{
                                                      position: "absolute",
                                                      width: "170%",
                                                      height: "1px",
                                                      backgroundColor:
                                                        "#ced4da",
                                                      top: "50%",
                                                      left: "-10px",
                                                      transform:
                                                        "rotate(-35deg)",
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </Radio.Button>
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                                </Radio.Group>
                              </div>
                            );
                          })}
                      </div>

                      {/* <Divider /> */}
                      <div className="individual_product_right_wrap">
                        <div className="purchase">
                          *Delivery assurance is subject to our delivery
                          locations staying open as per govt. regulations.
                        </div>

                        <div className="shop_btn">
                          {/* {console.log("specdata==>", specData?.[0]?.product_id)}                         */}
                          <div className="cart_quantity">
                            <Button
                              onClick={() => handleChangeIncrease("minus")}
                              disabled={
                                (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                  ? (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock <= 0
                                  : foundSpectQty
                                  ? foundSpectQty <= 0
                                  : specData?.length <= 0
                              }
                              // disabled={foundSpectQty ? foundSpectQty <= 0 : specData?.length <= 0}
                              // style={{
                              //   marginTop: "-1%",
                              // }}
                            >
                              {/* - */}
                              <MinusOutlined />
                            </Button>
                            {((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_mrp == true && (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_sp == false) ? 
                              (
                                <InputNumber
                                  value={count}
                                  type="number"
                                  // readOnly
                                  onChange={(e) => setCount(e)}
                                  min={1}
                                  style={{
                                    textAlign: "center",
                                    outline: "none",
                                    border: "none",
                                  }}
                                />
                              )
                              :
                              (
                                <InputNumber
                                  value={count}
                                  type="number"
                                  readOnly
                                  // onChange={(e) => setCount(e)}
                                  min={1}
                                  style={{
                                    textAlign: "center",
                                    outline: "none",
                                    border: "none",
                                  }}
                                />
                              )
                            }
                            
                            {/* {console.log("(foundSpectAll ? foundSpectAll : specData?.[0])?.quantity", specData)} */}                            
                            <Button
                              onClick={() =>
                                handleChangeIncrease(
                                  "plus",
                                  // (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                  //   ? (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                  //   : 
                                    (foundSpectQty != null)
                                    ? foundSpectQty
                                    // : (foundSpectAll ? foundSpectAll : specData?.[0])?.quantity
                                    : specData?.[0]?.quantity
                                )
                              }
                              disabled={
                                (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                  ? Number(
                                      (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                    ) <= 0
                                  : foundSpectQty
                                  ? foundSpectQty <= 0
                                  : specData?.length <= 0
                              }
                              // style={{
                              //   marginTop: "-1%",
                              // }}
                            >
                              {/* + */}
                              <PlusOutlined />
                            </Button>
                          </div>
                          {/* {console.log("cart", cart)} */}
                          {/* {console.log("(foundSpectAll ? foundSpectAll : specData?.[0])", (foundSpectAll ? foundSpectAll : specData?.[0]))} */}
                          {(() => {
                            const matchingCartItem = Array.isArray(cart)
                              ? cart.find(
                                  (cartItem) =>
                                    cartItem?.product_id?.product_name ===
                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.product_name
                                )
                              : (foundSpectAll ? foundSpectAll : specData?.[0]);

                            // console.log("Matching cart item:", matchingCartItem);

                            const cartQuantity = matchingCartItem
                              ? matchingCartItem?.product_id?.common_stock
                                ? matchingCartItem?.product_id?.common_stock
                                : matchingCartItem?.product_spec?.quantity
                              : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                              ? (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                              : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_spec?.quantity;

                            const cartAvailableQuantity = matchingCartItem?.qty;

                            // console.log("Cart quantity:", cartQuantity);
                            // console.log("Cart available quantity:", cartAvailableQuantity);

                            // Disable the button if current count reaches cart quantity
                            isButtonDisabled =
                              Number(cartAvailableQuantity) ==
                                Number(cartQuantity) ||
                              !((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                ? Number(
                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                  ) > 0
                                : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_spec?.quantity
                                ? (foundSpectAll ? foundSpectAll : specData?.[0])?.product_spec?.quantity > 0
                                : (foundSpectAll ? foundSpectAll : specData?.[0])?.quantity > 0);

                            // console.log("isButtonDisabled:", isButtonDisabled);
                            // console.log("isButtonDisabled111:", cartAvailableQuantity == cartQuantity);
                            // console.log("isButtonDisabled333:", specData?.[0]?.quantity > 0);
                            // console.log("isButtonDisabled444:", specData?.[0]?.product_spec?.quantity);
                            // console.log("isButtonDisabled555:", specData?.[0]?.product_id?.common_stock);
                            // console.log("isButtonDisabled222:", (specData?.[0]?.product_id?.common_stock
                            //   ? Number(specData?.[0]?.product_id?.common_stock) > 0
                            //   : specData?.[0]?.product_spec?.quantity ? specData?.[0]?.product_spec?.quantity > 0
                            // : specData?.[0]?.quantity > 0));

                            return null;
                          })()}
                          {/* {console.log("foundSpectQty", foundSpectQty)} */}
                          <Button
                            htmlType="submit"
                            loading={loading}
                            // loading={addToCartLoading}
                            type="primary"
                            onClick={addToCart}
                            // disabled={foundSpectQty ? !(foundSpectQty > 0) : !(specData?.[0]?.quantity > 0)}
                          //   disabled={
                          //     isButtonDisabled
                          //       ? isButtonDisabled
                          //       : specData?.[0]?.product_id?.common_stock
                          //       ? !(
                          //           Number(
                          //             specData?.[0]?.product_id?.common_stock
                          //           ) > 0
                          //         )
                          //       : foundSpectQty
                          //       ? !(foundSpectQty > 0)
                          //       : !(specData?.[0]?.quantity > 0)
                          //   }
                          // >
                          // disabled={
                          //   isButtonDisabled || 
                          //   // Disable if MRP is 0 or missing
                          //   (specData?.[0]?.product_id?.common_mrp <= 0 &&
                          //     !specData?.[0]?.mrp) ||
                          //   // Disable if SP is 0 or missing
                          //     (specData?.[0]?.product_id?.common_sp <= 0 && !specData?.[0]?.sp) ||
                          //   (specData?.[0]?.product_id?.common_stock
                          //     ? !(Number(specData?.[0]?.product_id?.common_stock) > 0)
                          //     : foundSpectQty
                          //     ? !(foundSpectQty > 0)
                          //     : !(specData?.[0]?.quantity > 0))
                          // }
                          disabled={
                            foundSpectQty
                              ? !(foundSpectQty > 0)
                              : !((foundSpectAll ? foundSpectAll : specData?.[0])?.quantity > 0)
                          }
                        >
                            <ShoppingCartOutlined
                              style={{ display: "inline-flex" }}
                            />
                            Add to Cart
                          </Button>
                          <Button
                            htmlType="submit"
                            loading={wishListLoading}
                            type="primary"
                            onClick={addToWiseList}
                            disabled={
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                ? Number(
                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                  ) < 0
                                : foundSpectQty
                                ? foundSpectQty < 0
                                : specData?.length < 0
                            }
                          >
                            <HeartOutlined style={{ display: "inline-flex" }} />
                            WishList
                          </Button>
                        </div>
                        <div style={{ color: "red" }}>
                          {/* {console.log("foundSpectQty==>+", foundSpectQty)} */}
                          {/* {console.log("specData?.[0]?.quantity==>+", specData?.[0]?.quantity)} */}
                          {(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                            ? Number((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock) >
                              0
                              ? ""
                              : `*Out of Stock`
                            : foundSpectQty
                            ? foundSpectQty > 0
                              ? ""
                              : `*Out of Stock`
                            : (foundSpectAll ? foundSpectAll : specData?.[0])?.quantity > 0
                            ? ""
                            : `*Out of Stock`}
                          {/* {foundSpectQty ? (foundSpectQty > 0 ? "" : `*Out of Stock`) : (specData?.[0]?.quantity > 0 ? "" : `*Out of Stock`)} */}
                        </div>
                      </div>

                      <Divider />

                      <Collapse
                        expandIconPosition="end"
                        ghost
                        items={items}
                        // expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
                        expandIcon={({ isActive }) => (
                          <RotateIcon isActive={isActive}>
                            {isActive ? <MinusOutlined /> : <PlusOutlined />}
                          </RotateIcon>
                        )}
                      />

                      {/* <Divider />
                  <div className="product_specification_list">
                    <table>
                      <tbody>
                        <tr>
                          <td>Material</td>
                          <td> : </td>
                          <td>Metal</td>
                        </tr>
                        <tr>
                          <td>Colour</td>
                          <td> : </td>
                          <td>Black</td>
                        </tr>
                        <tr>
                          <td>Capacity</td>
                          <td> : </td>
                          <td>8GB</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                    </div>
                  </div>
                </div>

                <div className="responsive_Slider">
                  {specData?.slice(0, 1)?.map((item, index) => {
                    return (
                      <Swiper spaceBetween={10} slidesPerView={3}>
                        {item?.product_images &&
                          item?.product_images?.map((e, i) => (
                            <SwiperSlide key={i}>
                              <img
                                preview={false}
                                width={100}
                                src={api.rootUrl1 + e?.image_path}
                                alt={`Thumbnail ${e?._id}`}
                              />
                            </SwiperSlide>
                          ))}
                      </Swiper>
                    );
                  })}
                </div>
                {/* 
              {specData?.slice(0, 1)?.map((item, index) => (
                <div
                  key={index}
                  className="video_section"
                  style={{
                    // border: "1px solid red",
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 10px",
                  }}
                >
                  <div className="video_icon2" style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {item?.product_video?.length > 0 && (
                      <div
                        className="video_icon1"
                        onClick={() => setVideoVisible(true)}
                      >
                        <Tooltip title="Play Video">
                          <img src={VideoPlay} alt="Video Play" />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <Modal
                    title={totalProducts?.product?.product_name}
                    open={isVideoVisible}
                    onCancel={() => setVideoVisible(false)}
                    footer={null}
                    width={1000}
                    centered
                  >
                    <br />
                    <MediaPlayer
                      title="Sprite Fight"
                      src={api.rootUrl1 + item?.product_video[0]?.video_path}
                    >
                      <MediaProvider />
                      <DefaultVideoLayout
                        thumbnails={VideoPlay}
                        icons={defaultLayoutIcons}
                      />
                    </MediaPlayer>
                  </Modal>
                </div>
              ))} */}

                {/* <div className="description_section">
                <Tabs
                  className="description_tab"
                  defaultActiveKey="1"
                  items={items}
                  onChange={onChangeTabs}
                  indicatorSize={(origin) => origin - 16}
                />
              </div> */}
                <Divider />
                <div className="related_product">
                  {/* <h2>Related Product</h2> */}
                  <h2>You May Also Like</h2>
                  <div className="related_grid">
                    {/* {RelatedProductsFilter?.slice(0, 4).map((item, index) => { */}
                    {relatedProducts?.slice(0, 4).map((item, index) => {
                      // console.log("Related Products:", item);
                      // console.log("Current Item:", item?.specification[index]?.specification_id);
                      return (
                        <div
                          className="product_align_filter_box"
                          key={index}
                          // onClick={() => relatedProductClick(item, index)}
                          onClick={() =>
                            relatedProductClickCategory(item, index)
                          }
                        >
                          <div className="p_image">
                            <img
                              // src={api.rootUrl1 + item?.product_images?.[0]?.image_path }
                              src={api.rootUrl1 + item?.images}
                              // src={api.rootUrl1 + item?.product_id?.images}
                              alt="Default"
                            />
                          </div>
                          <div className="p_content">
                            <h2 style={{ color: "black", lineHeight: "25px" }}>
                              {item?.product_name}&nbsp;
                              {/* {item?.product_id?.product_name}&nbsp; */}
                              {/* {item?.product_id?.product_name}&nbsp; - &nbsp; */}
                              {item?.specification?.map((spec) => (
                                <Tag color="green">
                                  {spec?.specification_details}
                                </Tag>
                              ))}
                            </h2>
                            <div>
                              {/* <Button size="medium" onClick={() => (getSingleProduct1(item?.product_id?._id, index))}>Add to Cart</Button> */}
                              {/* <Tag color="green">{item?.specification?.map((spec) => spec?.specification_details)}</Tag> */}
                            </div>
                            <div className="price">
                              {/* <div className="sp">
                              ₹{" "}
                              {(item?.sp +
                                (item?.sp *
                                  item?.product_id?.tax_percentage
                                    ?.tax_percentage) /
                                  100).toFixed(0)}
                            </div>
                            <div className="mrp">
                              ₹{" "}
                              {item?.mrp +
                                (item?.mrp *
                                  item?.product_id?.tax_percentage
                                    ?.tax_percentage) /
                                  100}
                            </div> */}
                              <Button>Click to view more details</Button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            <Modal
              open={open}
              onCancel={handleCancel}
              onOk={handleOk}
              footer={null}
              title="Login"
              width={400}
            >
              <Form
                layout="vertical"
                form={loginForm}
                name="Login_Form"
                onFinish={login}
              >
                <Form.Item
                  label="Enter Mobile number"
                  name="buyer_number"
                  rules={[
                    {
                      required: true,
                      pattern: /^[0-9]{10}$/,
                      message: "Please enter your Mobile number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Enter Password"
                  name="buyer_password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={loginLoading}
                    className="primary_btn"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
              <Or>or</Or>

              <NewAccount>
                <Link to="/register">Click Here</Link>
                to Register a Account
              </NewAccount>
            </Modal>
            <Modal
              open={open1}
              onCancel={handleCancel1}
              onOk={handleOk1}
              footer={null}
              title="Add Address"
              width={300}
            >
              <Button type="primary" onClick={() => navigate("/add-address")}>
                {" "}
                Go to Address Screen{" "}
              </Button>
            </Modal>
          </ProductSection>

          <MobileProductSection>
            {products && (
              <>
                <div className="Mb_image_grp">
                  {specData?.slice(0, 1)?.map((item, index) => (
                    <>
                      <Image.PreviewGroup
                        items={item?.product_images?.map((image) => ({
                          key: image?._id,
                          src:
                            api.rootUrl1 + image?.image_path ||
                            api.rootUrl1 + products?.images,
                        }))}
                      >
                        <Image
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            objectPosition: "center",
                            userSelect: "none",
                          }}
                          src={
                            api.rootUrl1 +
                            (mainImage ||
                              (foundSpectImg
                                ? foundSpectImg?.[0]?.image_path
                                : item?.product_images?.[0]?.image_path))
                          }
                          preview={{
                            images: item?.product_images?.map((image) => ({
                              src:
                                api.rootUrl1 + image?.image_path ||
                                api.rootUrl1 + products?.images,
                            })),
                            visible: true,
                          }}
                        />
                      </Image.PreviewGroup>
                      <div className="thump_image">
                        <Swiper
                          onSwiper={() => setThumbsSwiper(thumbsSwiper)}
                          mousewheel={true}
                          slidesPerView={window.innerWidth <= 540 ? 6 : 3}
                          spaceBetween={2}
                          className="swiper"
                          navigation={{
                            nextEl: ".slider__next",
                            prevEl: ".slider__prev",
                          }}
                          thumbs={{ swiper: thumbsSwiper }}
                          modules={[Navigation, Thumbs, Mousewheel]}
                          style={{ userSelect: "none" }}
                        >
                          {(() => {
                            // Determine which images to use
                            // const images = (specData.length > 1 && item?.product_id?.common_mrp) ? allSpectImg : foundSpectImg || item?.product_images;
                            const images =
                              foundSpectImg || item?.product_images;
                            return images?.map((e, i) => {
                              // Ensure e is valid and has image_path
                              if (e && e.image_path) {
                                return (
                                  <SwiperSlide
                                    key={e?._id || i}
                                    className="thump_image_box"
                                    onClick={() =>
                                      handleThumbnailClick(e.image_path)
                                    }
                                    style={{ userSelect: "none" }}
                                  >
                                    <img
                                      preview={false}
                                      // height={100}
                                      src={api.rootUrl1 + e.image_path}
                                      alt={`Thumbnail ${e?._id || i}`}
                                      // style={{aspectRatio: '1/1'}}
                                    />
                                  </SwiperSlide>
                                );
                              }
                              // Return null if e is invalid
                              return null;
                            });
                          })()}
                        </Swiper>
                        <Modal
                          title={totalProducts?.product?.product_name}
                          open={isVideoVisible}
                          onCancel={() => setVideoVisible(false)}
                          footer={null}
                          width={400}
                          centered
                        >
                          <br />
                          <MediaPlayer
                            title="Sprite Fight"
                            src={
                              api.rootUrl1 +
                              specData[0]?.product_video[0]?.video_path
                            }
                          >
                            <MediaProvider />
                            <DefaultVideoLayout
                              thumbnails={VideoPlay}
                              icons={defaultLayoutIcons}
                            />
                          </MediaPlayer>
                        </Modal>
                      </div>
                    </>
                  ))}
                </div>
                <div className="Mb_ProName_sec">
                  <div className="Tag_div"></div>
                  <div className="Mb_ProName">
                    {/* {console.log("specDataspecData", specData)} */}
                    {/* <h1>
                      {specData?.[0]?.product_id?.category?.[0]?.name.toUpperCase()}
                    </h1> */}
                    <p
                      style={{
                        fontSize: "large",
                        fontWeight: "500",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {specData?.[0]?.product_id?.product_name}
                    </p>
                    <div className="Mb_ReviewAvg"></div>
                    <p style={{ marginBottom: "0.5rem" }}>
                      SKU:{" "}
                      {(foundSpectAll ? foundSpectAll : specData?.[0])?.sku
                                ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sku
                                : specData?.[0]?.product_id?.common_sku || ""}
                    </p>

                    {(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp != null &&
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp != ""
                              ? (
                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_mrp == true &&
                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_sp == false
                                ) ? (
                                  <div className="price">
                                    {(dayjs((foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount_valid_to).isAfter(dayjs(CurrentDate)) 
                                    && (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status) ? (
                                      <div className="discount_strike" style={{textDecoration: "line-through"}}>
                                         M.R.P: {styles?.currency + 
                                         (isDecimal?.isDecimal === false ? Math.round((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp +
                                                 ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                     ?.common_mrp *
                                                     totalProducts?.product
                                                       ?.tax_percentage
                                                       ?.tax_percentage) /
                                                     100) : ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp +
                                                 ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                     ?.common_mrp * 
                                                     totalProducts?.product
                                                       ?.tax_percentage 
                                                       ?.tax_percentage) / 100).toFixed(isDecimal?.Decimal_points))}
                                      </div>
                                    ) : (<div></div>)}
                                    <div className="sp">
                                      {/* <span style={{ fontSize: "14px" }}> */}
                                        {(dayjs((foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount_valid_to).isAfter(dayjs(CurrentDate)) 
                                          && (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status) ? (
                                            <span style={{ fontSize: "14px" }}>
                                              Deal Price:{" "}
                                            </span>
                                          ) : (
                                            <span style={{ fontSize: "14px" }}>
                                              M.R.P:{" "}
                                            </span>
                                        )}
                                        {/* M.R.P:{" "} */}
                                      {/* </span> */}
                                      {(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp
                                        ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? (
                                                // specData?.[0]?.product_id
                                                //   ?.common_mrp +
                                                //   (specData?.[0]?.product_id
                                                //     ?.common_mrp *
                                                //     totalProducts?.product
                                                //       ?.tax_percentage
                                                //       ?.tax_percentage) /
                                                //     100

                                                (() => {
                                                  const discountValidTo =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id
                                                      ?.discount_valid_to;
                                                  const discount =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.discount;
                                                  const discountType =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.discounttype;
                                                  const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp;
                                                  const taxPercentage =
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage / 100;
                                                  const discStatus =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.status;
      
                                                  const isDiscountValid = dayjs(
                                                    discountValidTo
                                                  ).isAfter(dayjs(CurrentDate));
      
                                                  if (sp) {
                                                    let finalPrice;
      
                                                    if (
                                                      isDiscountValid &&
                                                      discStatus
                                                    ) {
                                                      if (
                                                        discountType === "Percentage"
                                                      ) {
                                                        finalPrice =
                                                          (sp -
                                                            sp * (discount / 100)) *
                                                          (1 + taxPercentage);
                                                      } else if (
                                                        discountType === "Price"
                                                      ) {
                                                        finalPrice =
                                                          (sp - discount) *
                                                          (1 + taxPercentage);
                                                      } else {
                                                        finalPrice =
                                                          sp * (1 + taxPercentage);
                                                      }
                                                    } else {
                                                      finalPrice =
                                                        sp * (1 + taxPercentage);
                                                    }
      
                                                    return (                                                      
                                                      (isDecimal?.isDecimal === false
                                                        ? Math.round(finalPrice)
                                                        : finalPrice.toFixed(
                                                            isDecimal?.Decimal_points
                                                          ))
                                                    );
                                                  } else {
                                                    return null; // Stock not available
                                                  }
                                                })()
                                              )
                                            : (
                                                // specData?.[0]?.product_id
                                                //   ?.common_mrp +
                                                // (specData?.[0]?.product_id
                                                //   ?.common_mrp *
                                                //   totalProducts?.product
                                                //     ?.tax_percentage
                                                //     ?.tax_percentage) /
                                                //   100

                                                (() => {
                                                  const discountValidTo =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id
                                                      ?.discount_valid_to;
                                                  const discount =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.discount;
                                                  const discountType =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.discounttype;
                                                  const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp;
                                                  const taxPercentage =
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage / 100;
                                                  const discStatus =
                                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                      ?.discount_id?.status;
      
                                                  const isDiscountValid = dayjs(
                                                    discountValidTo
                                                  ).isAfter(dayjs(CurrentDate));
      
                                                  if (sp) {
                                                    let finalPrice;
      
                                                    if (
                                                      isDiscountValid &&
                                                      discStatus
                                                    ) {
                                                      if (
                                                        discountType === "Percentage"
                                                      ) {
                                                        finalPrice =
                                                          (sp -
                                                            sp * (discount / 100)) *
                                                          (1 + taxPercentage);
                                                      } else if (
                                                        discountType === "Price"
                                                      ) {
                                                        finalPrice =
                                                          (sp - discount) *
                                                          (1 + taxPercentage);
                                                      } else {
                                                        finalPrice =
                                                          sp * (1 + taxPercentage);
                                                      }
                                                    } else {
                                                      finalPrice =
                                                        sp * (1 + taxPercentage);
                                                    }
      
                                                    return (                                                      
                                                      (isDecimal?.isDecimal === false
                                                        ? Math.round(finalPrice)
                                                        : finalPrice.toFixed(
                                                            isDecimal?.Decimal_points
                                                          ))
                                                    );
                                                  } else {
                                                    return null; // Stock not available
                                                  }
                                                })()

                                              ))
                                        :  // `Stock Not Available`
                                          null}
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#888",
                                        }}
                                      >
                                        &nbsp;(* Inclusive of tax)
                                      </span>
                                    </div>
                                  </div>
                                ) :
                                (
                                  <div className="price">
                                    {/* {console.log("i ammmmmm onnnn")} */}
                                    <div 
                                    className="mrp" 
                                    // style={{ textDecoration: "line-through"}}
                                    >                                      
                                      <span>M.R.P: </span>
                                      {(foundSpectAll ? foundSpectAll : specData?.[0])?.mrp
                                        ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? Math.round(
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp +
                                                  ((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp *
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage) /
                                                    100
                                              )
                                            : (
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp +
                                                ((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp *
                                                  totalProducts?.product
                                                    ?.tax_percentage
                                                    ?.tax_percentage) /
                                                  100
                                              ).toFixed(
                                                isDecimal?.Decimal_points
                                              ))
                                      :(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp
                                      ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? Math.round(
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp +
                                                  ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                    ?.common_mrp *
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage) /
                                                    100
                                              )
                                            : (
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp +
                                                ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp *
                                                  totalProducts?.product
                                                    ?.tax_percentage
                                                    ?.tax_percentage) /
                                                  100
                                              ).toFixed(
                                                isDecimal?.Decimal_points
                                              ))
                                          : // `Stock Not Available`
                                          null
                                      }                                     
                                    </div>

                                    <div className="sp">
                                      <span style={{ fontSize: "14px" }}>
                                        Deal Price:{" "}
                                      </span>
                                      {specData?.length > 1 && !foundSpectAll
                                        ? (() => {
                                            const filteredSpecData =
                                              specData?.filter((item) =>
                                                item?.specification?.some(
                                                  (spec) =>
                                                    spec?._id === size?.[0]
                                                )
                                              );

                                            if (filteredSpecData?.length > 0) {
                                              const discountValidTo =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discount_valid_to;
                                              const discount =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discount;
                                              const discountType =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discounttype;
                                              const sp = filteredSpecData?.[0]?.sp ? filteredSpecData?.[0]?.sp : filteredSpecData?.[0]?.product_id?.common_sp;
                                              const taxPercentage =
                                                totalProducts?.product
                                                  ?.tax_percentage
                                                  ?.tax_percentage / 100;
                                              const discStatus =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.status;

                                              const isDiscountValid = dayjs(
                                                discountValidTo
                                              ).isAfter(dayjs(CurrentDate));

                                              if (sp) {
                                                let finalPrice;

                                                if (
                                                  isDiscountValid &&
                                                  discStatus
                                                ) {
                                                  if (
                                                    discountType ===
                                                    "Percentage"
                                                  ) {
                                                    finalPrice =
                                                      (sp -
                                                        sp * (discount / 100)) *
                                                      (1 + taxPercentage);
                                                  } else if (
                                                    discountType === "Price"
                                                  ) {
                                                    finalPrice =
                                                      (sp - discount) *
                                                      (1 + taxPercentage);
                                                  } else {
                                                    finalPrice =
                                                      sp * (1 + taxPercentage);
                                                  }
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }

                                                return (
                                                  styles?.currency +
                                                  (isDecimal?.isDecimal ===
                                                  false
                                                    ? Math.round(finalPrice)
                                                    : finalPrice.toFixed(
                                                        isDecimal?.Decimal_points
                                                      ))
                                                );
                                              } else {
                                                return null; // Stock not available
                                              }
                                            } else {
                                              const discountValidTo =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id
                                                  ?.discount_valid_to;
                                              const discount =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.discount;
                                              const discountType =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.discounttype;
                                              const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.sp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_sp;
                                              const taxPercentage =
                                                totalProducts?.product
                                                  ?.tax_percentage
                                                  ?.tax_percentage / 100;
                                              const discStatus =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.status;
                                              const isDiscountValid = dayjs(
                                                discountValidTo
                                              ).isAfter(dayjs(CurrentDate));

                                              if (sp) {
                                                let finalPrice;

                                                if (
                                                  isDiscountValid &&
                                                  discStatus
                                                ) {
                                                  if (
                                                    discountType ===
                                                    "Percentage"
                                                  ) {
                                                    finalPrice =
                                                      (sp -
                                                        sp * (discount / 100)) *
                                                      (1 + taxPercentage);
                                                  } else if (
                                                    discountType === "Price"
                                                  ) {
                                                    finalPrice =
                                                      (sp - discount) *
                                                      (1 + taxPercentage);
                                                  } else {
                                                    finalPrice =
                                                      sp * (1 + taxPercentage);
                                                  }
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }

                                                return (
                                                  styles?.currency +
                                                  (isDecimal?.isDecimal ===
                                                  false
                                                    ? Math.round(finalPrice)
                                                    : finalPrice.toFixed(
                                                        isDecimal?.Decimal_points
                                                      ))
                                                );
                                              } else {
                                                return null; // Stock not available
                                              }
                                            }
                                          })()
                                        : (() => {
                                            const discountValidTo =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id
                                                ?.discount_valid_to;
                                            const discount =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.discount;
                                            const discountType =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.discounttype;
                                            const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.sp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_sp;
                                            const taxPercentage =
                                              totalProducts?.product
                                                ?.tax_percentage
                                                ?.tax_percentage / 100;
                                            const discStatus =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.status;

                                            const isDiscountValid = dayjs(
                                              discountValidTo
                                            ).isAfter(dayjs(CurrentDate));

                                            if (sp) {
                                              let finalPrice;

                                              if (
                                                isDiscountValid &&
                                                discStatus
                                              ) {
                                                if (
                                                  discountType === "Percentage"
                                                ) {
                                                  finalPrice =
                                                    (sp -
                                                      sp * (discount / 100)) *
                                                    (1 + taxPercentage);
                                                } else if (
                                                  discountType === "Price"
                                                ) {
                                                  finalPrice =
                                                    (sp - discount) *
                                                    (1 + taxPercentage);
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }
                                              } else {
                                                finalPrice =
                                                  sp * (1 + taxPercentage);
                                              }

                                              return (
                                                styles?.currency +
                                                (isDecimal?.isDecimal === false
                                                  ? Math.round(finalPrice)
                                                  : finalPrice.toFixed(
                                                      isDecimal?.Decimal_points
                                                    ))
                                              );
                                            } else {
                                              return null; // Stock not available
                                            }
                                          })()}
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#888",
                                        }}
                                      >
                                        {/* &nbsp;(* Exclusive of tax) */}
                                        &nbsp;(* Inclusive of tax)
                                      </span>
                                    </div>
                                  </div>
                                )
                              : (
                                !((foundSpectAll ? foundSpectAll : specData?.[0])?.show_mrp == true &&
                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.show_sp == false) &&
                                !(
                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_mrp == true &&
                                  (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_sp == false
                                ) &&
                                  <div className="price">
                                    {/* {console.log("i ammmmmm innnnnn")} */}
                                    {/* <div className="sp">
                              {dayjs(
                                specData?.[0]?.discount_id?.discount_id
                                  ?.discount_valid_to
                              ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") >
                              dayjs(CurrentDate).format(
                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                              )
                                ? specData?.[0]?.sp
                                  ? styles?.currency +
                                    // parseFloat(
                                    //   specData?.[0]?.sp -
                                    //   specData?.[0]?.sp *
                                    //   (specData?.[0]?.discount_id && specData?.[0]?.discount_id
                                    //     ?.discount_id?.discount /
                                    //     100) +
                                    //   ((specData?.[0]?.sp -
                                    //     specData?.[0]?.sp *
                                    //     (specData?.[0]?.discount_id && specData?.[0]?.discount_id
                                    //       ?.discount_id?.discount /
                                    //       100)) *
                                    //     totalProducts?.product?.tax_percentage
                                    //       ?.tax_percentage) /
                                    //   100
                                    // ).toFixed(2)
                                    parseFloat(
                                      specData?.[0]?.discount_id?.discount_id
                                        ?.discount
                                        ? specData?.[0]?.sp +
                                            (specData?.[0]?.sp -
                                              specData?.[0]?.discount_id
                                                ?.discount_id?.discount /
                                                100) *
                                              (totalProducts?.product
                                                ?.tax_percentage
                                                ?.tax_percentage /
                                                100)
                                        : specData?.[0]?.sp +
                                            specData?.[0]?.sp *
                                              (totalProducts?.product
                                                ?.tax_percentage
                                                ?.tax_percentage /
                                                100)
                                    ).toFixed(2)
                                  : // `Stock Not Avalilable`
                                    null
                                : specData?.[0]?.sp
                                ? styles?.currency +
                                  parseFloat(
                                    specData?.[0]?.sp +
                                      specData?.[0]?.sp *
                                        (totalProducts?.product?.tax_percentage
                                          ?.tax_percentage /
                                          100)
                                  ).toFixed(2)
                                : // `Stock Not Avalilable`
                                  null}
                            </div> */}
                                    {/* <div className="sp">
                              {(() => {
                                const discountValidTo = specData?.[0]?.discount_id?.discount_id?.discount_valid_to;
                                const discount = specData?.[0]?.discount_id?.discount_id?.discount;
                                const discountType = specData?.[0]?.discount_id?.discount_id?.discounttype;
                                const sp = specData?.[0]?.product_id?.common_sp ? specData?.[0]?.product_id?.common_sp : specData?.[0]?.sp;
                                const taxPercentage = totalProducts?.product?.tax_percentage?.tax_percentage / 100;
                                const discStatus = specData?.[0]?.discount_id?.discount_id?.status

                                // console.log("specData?.[0]?.discount_id?.discount_id", specData?.[0]?.discount_id?.discount_id?.status);

                                // Check if the discount is valid
                                const isDiscountValid = dayjs(discountValidTo).isAfter(dayjs(CurrentDate));

                                if (sp) {
                                  let finalPrice;

                                  if (isDiscountValid && discStatus) {
                                    if (discountType == "Percentage") {
                                      // Percentage discount calculation
                                      finalPrice = (sp - (sp * (discount / 100))) * (1 + taxPercentage);
                                    } else if (discountType == "Price") {
                                      // Flat price discount calculation
                                      finalPrice = (sp - discount) * (1 + taxPercentage);
                                    } else {
                                      // No valid discount type, just apply tax
                                      finalPrice = sp * (1 + taxPercentage);
                                    }
                                  } else {
                                    // No valid discount, apply only tax
                                    finalPrice = sp * (1 + taxPercentage);
                                  }

                                  return styles?.currency + finalPrice.toFixed(2);
                                } else {
                                  return null; // Stock not available
                                }
                              })()}
                            </div> */}

                                    <div className="mrp"
                                      // style={{textDecoration: "line-through"}}
                                    >
                                      {/* {specData?.[0]?.mrp
                                ? styles?.currency +
                                  (
                                    specData?.[0]?.mrp +
                                    (specData?.[0]?.mrp *
                                      totalProducts?.product?.tax_percentage
                                        ?.tax_percentage) /
                                      100
                                  ).toFixed(2)
                                : // `Stock Not Avalilable`
                                  null} */}
                                      <span>M.R.P: </span>
                                      {(foundSpectAll ? foundSpectAll : specData?.[0])?.mrp
                                        ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? Math.round(
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp +
                                                  ((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp *
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage) /
                                                    100
                                              )
                                            : (
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp +
                                                ((foundSpectAll ? foundSpectAll : specData?.[0])?.mrp *
                                                  totalProducts?.product
                                                    ?.tax_percentage
                                                    ?.tax_percentage) /
                                                  100
                                              ).toFixed(
                                                isDecimal?.Decimal_points
                                              ))
                                      :(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp
                                      ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? Math.round(
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp +
                                                  ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                    ?.common_mrp *
                                                    totalProducts?.product
                                                      ?.tax_percentage
                                                      ?.tax_percentage) /
                                                    100
                                              )
                                            : (
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp +
                                                ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id
                                                  ?.common_mrp *
                                                  totalProducts?.product
                                                    ?.tax_percentage
                                                    ?.tax_percentage) /
                                                  100
                                              ).toFixed(
                                                isDecimal?.Decimal_points
                                              ))
                                          : // `Stock Not Available`
                                          null

                                      }

                                      {/* {(() => {
                                    const filteredSpecData = specData?.filter(item =>
                                      item?.specification?.some(spec => spec?._id === size?.[0])
                                    );

                                    if (filteredSpecData?.length > 0) {
                                      const commonMrp = filteredSpecData?.[0]?.product_id?.common_mrp;
                                      const mrp = filteredSpecData?.[0]?.mrp;
                                      const taxPercentage = totalProducts?.product?.tax_percentage?.tax_percentage / 100;

                                      if (commonMrp) {
                                        return (
                                          styles?.currency +
                                          (commonMrp + commonMrp * taxPercentage).toFixed(2)
                                        );
                                      } else if (mrp) {
                                        return (
                                          styles?.currency +
                                          (mrp + mrp * taxPercentage).toFixed(2)
                                        );
                                      } else {
                                        return null; // Stock Not Available
                                      }
                                    } else {
                                      return null; // No matching specification
                                    }
                                  })()} */}
                                    </div>

                                    <div className="sp">
                                      <span style={{ fontSize: "14px" }}>
                                        Deal Price:{" "}
                                      </span>
                                      {specData?.length > 1 && !foundSpectAll
                                        ? (() => {
                                            const filteredSpecData =
                                              specData?.filter((item) =>
                                                item?.specification?.some(
                                                  (spec) =>
                                                    spec?._id === size?.[0]
                                                )
                                              );

                                            if (filteredSpecData?.length > 0) {
                                              const discountValidTo =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discount_valid_to;
                                              const discount =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discount;
                                              const discountType =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.discounttype;
                                              const sp = filteredSpecData?.[0]?.sp ? filteredSpecData?.[0]?.sp : filteredSpecData?.[0]?.product_id?.common_sp;
                                              const taxPercentage =
                                                totalProducts?.product
                                                  ?.tax_percentage
                                                  ?.tax_percentage / 100;
                                              const discStatus =
                                                filteredSpecData?.[0]
                                                  ?.discount_id?.discount_id
                                                  ?.status;

                                              const isDiscountValid = dayjs(
                                                discountValidTo
                                              ).isAfter(dayjs(CurrentDate));

                                              if (sp) {
                                                let finalPrice;

                                                if (
                                                  isDiscountValid &&
                                                  discStatus
                                                ) {
                                                  if (
                                                    discountType ===
                                                    "Percentage"
                                                  ) {
                                                    finalPrice =
                                                      (sp -
                                                        sp * (discount / 100)) *
                                                      (1 + taxPercentage);
                                                  } else if (
                                                    discountType === "Price"
                                                  ) {
                                                    finalPrice =
                                                      (sp - discount) *
                                                      (1 + taxPercentage);
                                                  } else {
                                                    finalPrice =
                                                      sp * (1 + taxPercentage);
                                                  }
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }

                                                return (
                                                  styles?.currency +
                                                  (isDecimal?.isDecimal ===
                                                  false
                                                    ? Math.round(finalPrice)
                                                    : finalPrice.toFixed(
                                                        isDecimal?.Decimal_points
                                                      ))
                                                );
                                              } else {
                                                return null; // Stock not available
                                              }
                                            } else {
                                              const discountValidTo =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id
                                                  ?.discount_valid_to;
                                              const discount =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.discount;
                                              const discountType =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.discounttype;
                                              const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.sp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_sp;
                                              const taxPercentage =
                                                totalProducts?.product
                                                  ?.tax_percentage
                                                  ?.tax_percentage / 100;
                                              const discStatus =
                                                (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                  ?.discount_id?.status;
                                              const isDiscountValid = dayjs(
                                                discountValidTo
                                              ).isAfter(dayjs(CurrentDate));

                                              if (sp) {
                                                let finalPrice;

                                                if (
                                                  isDiscountValid &&
                                                  discStatus
                                                ) {
                                                  if (
                                                    discountType ===
                                                    "Percentage"
                                                  ) {
                                                    finalPrice =
                                                      (sp -
                                                        sp * (discount / 100)) *
                                                      (1 + taxPercentage);
                                                  } else if (
                                                    discountType === "Price"
                                                  ) {
                                                    finalPrice =
                                                      (sp - discount) *
                                                      (1 + taxPercentage);
                                                  } else {
                                                    finalPrice =
                                                      sp * (1 + taxPercentage);
                                                  }
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }

                                                return (
                                                  styles?.currency +
                                                  (isDecimal?.isDecimal ===
                                                  false
                                                    ? Math.round(finalPrice)
                                                    : finalPrice.toFixed(
                                                        isDecimal?.Decimal_points
                                                      ))
                                                );
                                              } else {
                                                return null; // Stock not available
                                              }
                                            }
                                          })()
                                        : (() => {
                                            const discountValidTo =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id
                                                ?.discount_valid_to;
                                            const discount =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.discount;
                                            const discountType =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.discounttype;
                                            const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.sp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_sp;
                                            const taxPercentage =
                                              totalProducts?.product
                                                ?.tax_percentage
                                                ?.tax_percentage / 100;
                                            const discStatus =
                                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id
                                                ?.discount_id?.status;

                                            const isDiscountValid = dayjs(
                                              discountValidTo
                                            ).isAfter(dayjs(CurrentDate));

                                            if (sp) {
                                              let finalPrice;

                                              if (
                                                isDiscountValid &&
                                                discStatus
                                              ) {
                                                if (
                                                  discountType === "Percentage"
                                                ) {
                                                  finalPrice =
                                                    (sp -
                                                      sp * (discount / 100)) *
                                                    (1 + taxPercentage);
                                                } else if (
                                                  discountType === "Price"
                                                ) {
                                                  finalPrice =
                                                    (sp - discount) *
                                                    (1 + taxPercentage);
                                                } else {
                                                  finalPrice =
                                                    sp * (1 + taxPercentage);
                                                }
                                              } else {
                                                finalPrice =
                                                  sp * (1 + taxPercentage);
                                              }

                                              return (
                                                styles?.currency +
                                                (isDecimal?.isDecimal === false
                                                  ? Math.round(finalPrice)
                                                  : finalPrice.toFixed(
                                                      isDecimal?.Decimal_points
                                                    ))
                                              );
                                            } else {
                                              return null; // Stock not available
                                            }
                                          })()}
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#888",
                                        }}
                                      >
                                        {/* &nbsp;(* Exclusive of tax) */}
                                        &nbsp;(* Inclusive of tax)
                                      </span>
                                    </div>
                                  </div>
                                )}
                  </div>

                  {(() => {
                          // const mrp = specData?.[0]?.product_id?.common_mrp
                          //   ? specData?.[0]?.product_id?.common_mrp
                          //   : specData?.[0]?.mrp;

                          const taxPercentage =
                            totalProducts?.product?.tax_percentage
                              ?.tax_percentage / 100;
                          const discountValidTo =
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                              ?.discount_valid_to;
                          const isDiscountValid = dayjs(
                            discountValidTo
                          ).isAfter(dayjs(CurrentDate));
                          const discStatus =
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status;

                          const calculateMRP = () => {
                            const baseMRP = (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp;

                            const mrpWithTax =
                              baseMRP + baseMRP * taxPercentage;

                            return isDecimal?.isDecimal === false
                              ? Math.round(mrpWithTax)
                              : mrpWithTax.toFixed(isDecimal?.Decimal_points);
                          };

                          const spWithDiscount = () => {
                            const sp = (foundSpectAll ? foundSpectAll : specData?.[0])?.sp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.sp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_sp;

                            const discountValidTo =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                                ?.discount_valid_to;
                            const discount =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount;
                            const discountType =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                                ?.discounttype;
                            const discStatus =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status;
                            const isDiscountValid = dayjs(
                              discountValidTo
                            ).isAfter(dayjs(CurrentDate));

                            let finalPrice = sp * (1 + taxPercentage);

                            if (isDiscountValid && discStatus) {
                              if (discountType === "Percentage") {
                                // console.log("discountType in percentage", discountType);
                                finalPrice =
                                  (sp - sp * (discount / 100)) *
                                  (1 + taxPercentage);
                              } else if (discountType === "Price") {
                                // console.log("discountType in price", discountType);
                                finalPrice =
                                  (sp - discount) * (1 + taxPercentage);
                              }
                            }
                            // console.log("finalPrice", finalPrice);
                            return finalPrice;
                          };
                          // console.log("spWithDiscount()", spWithDiscount());
                          // console.log("mrp", mrp);
                          const mrpWithDiscount = () => {
                            const mrp = (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp ? (foundSpectAll ? foundSpectAll : specData?.[0])?.mrp : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_mrp;

                            const discountValidTo =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                                ?.discount_valid_to;
                            const discount =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount;
                            const discountType =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                                ?.discounttype;
                            const discStatus =
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status;
                            const isDiscountValid = dayjs(
                              discountValidTo
                            ).isAfter(dayjs(CurrentDate));

                            let finalPrice = mrp * (1 + taxPercentage);

                            if (isDiscountValid && discStatus) {
                              if (discountType === "Percentage") {
                                finalPrice =
                                  (mrp - mrp * (discount / 100)) *
                                  (1 + taxPercentage);
                              } else if (discountType === "Price") {
                                finalPrice =
                                  (mrp - discount) * (1 + taxPercentage);
                              }
                            }
                            return finalPrice;
                          };
                          const mrp = calculateMRP();
                          console.log("(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id", (foundSpectAll ? foundSpectAll : specData?.[0]))
                          if ( (foundSpectAll ? foundSpectAll : specData?.[0])?.show_mrp == true &&
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.show_sp == false) {
                            savingsAmount = mrp - mrpWithDiscount();
                          } else if ( (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_mrp == true &&
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_sp == false) {
                            savingsAmount = mrp - mrpWithDiscount();
                          } else {
                            savingsAmount = mrp - spWithDiscount();
                          }
                          // savingsAmount = mrp - spWithDiscount();
                          if (isDiscountValid && discStatus) {
                            return (
                              <div>
                                {/* <div>MRP: {styles?.currency + mrp}</div> */}
                                {/* <div>SP with Discount: {styles?.currency + (isDecimal?.isDecimal === false ? Math.round(spWithDiscount()) : spWithDiscount().toFixed(isDecimal?.Decimal_points))}</div> */}
                                <div style={{ color: "green" }}>
                                  Amount Saved:{" "}
                                  {styles?.currency +
                                    (isDecimal?.isDecimal === false
                                      ? Math.round(savingsAmount)
                                      : savingsAmount.toFixed(
                                          isDecimal?.Decimal_points
                                        ))}
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })()}

                        {specData?.length > 1 && !foundSpectAll ? (
                          size?.[0] ? (
                            (() => {
                              const filteredSpecData = specData?.filter(
                                (item) =>
                                  item?.specification?.some(
                                    (spec) => spec?._id === size?.[0]
                                  )
                              );

                              if (filteredSpecData?.length > 0) {
                                const discountData =
                                  filteredSpecData?.[0]?.discount_id
                                    ?.discount_id;
                                const discountValidTo =
                                  discountData?.discount_valid_to;
                                const discountStatus = discountData?.status;
                                const discountTitle =
                                  discountData?.discount_title;
                                const discountType = discountData?.discounttype;
                                const discountValue = discountData?.discount;

                                // Check if discount is valid and active
                                const isDiscountValid =
                                  dayjs(discountValidTo).isAfter(
                                    dayjs(CurrentDate)
                                  ) && discountStatus === true;

                                if (isDiscountValid) {
                                  return (
                                    <div style={{ color: "green" }}>
                                      {discountTitle}{" "}
                                      {discountType === "Percentage"
                                        ? `${discountValue}% Applied`
                                        : `${styles?.currency}${discountValue} Applied`}
                                    </div>
                                  );
                                } else {
                                  return null; // No valid discount
                                }
                              } else {
                                return null; // No matching specification
                              }
                            })()
                          ) : (
                            (() => {
                              const filteredSpecData = specData?.filter(
                                (item) =>
                                  item?.specification?.some((spec) => spec?._id)
                              );

                              if (filteredSpecData?.length > 0) {
                                const discountData =
                                  filteredSpecData?.[0]?.discount_id
                                    ?.discount_id;
                                const discountValidTo =
                                  discountData?.discount_valid_to;
                                const discountStatus = discountData?.status;
                                const discountTitle =
                                  discountData?.discount_title;
                                const discountType = discountData?.discounttype;
                                const discountValue = discountData?.discount;

                                // Check if discount is valid and active
                                const isDiscountValid =
                                  dayjs(discountValidTo).isAfter(
                                    dayjs(CurrentDate)
                                  ) && discountStatus === true;

                                if (isDiscountValid) {
                                  return (
                                    <div style={{ color: "green" }}>
                                      {discountTitle}{" "}
                                      {discountType === "Percentage"
                                        ? `${discountValue}% Applied`
                                        : `${styles?.currency}${discountValue} Applied`}
                                    </div>
                                  );
                                } else {
                                  return null; // No valid discount
                                }
                              } else {
                                return null; // No matching specification
                              }
                            })()
                          )
                        ) : dayjs(
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                              ?.discount_valid_to
                          ).isAfter(dayjs(CurrentDate)) &&
                          (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.status ==
                            true ? (
                          <div style={{ color: "green" }}>
                            {
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                                ?.discount_title
                            }
                            {(foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id
                              ?.discounttype === "Percentage"
                              ? `${(foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount}% Applied`
                              : `${styles?.currency}${(foundSpectAll ? foundSpectAll : specData?.[0])?.discount_id?.discount_id?.discount} Applied`}
                          </div>
                        ) : null}

                  <div className="Mb_ReviewAvg"></div>
                </div>
                <div className="Mb_Coupon_sec"></div>
                <div className="product_specification">
                  {(((foundSpectAll ? foundSpectAll : specData?.[0])?.show_mrp == true &&
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.show_sp == false) ||
                          ((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_mrp == true &&
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.show_common_sp ==
                              false) ||
                          []
                      // ? totalProducts?.product?.specification.slice(0, 2)
                      ? totalProducts?.product?.specification
                      : totalProducts?.product?.specification
                    )?.map((spec, specIndex) => {
                      const sizeId = size?.[0];

                      // Check if the current row contains the sizeId
                      const currentRowContainsSizeId =
                        totalProducts?.productspec?.some((prodSpec) =>
                          prodSpec?.specification.some(
                            (specDetail) =>
                              specDetail?._id === sizeId &&
                              specDetail?.specification_id ===
                                spec?.specification?._id
                          )
                        );

                        const defaultProSpec1 = specData1?.filter(
                          (item) => item?._id == productSpecId                                          
                        )?.[0];

                        const isPricePresentindefaultProSpec1 =
                                defaultProSpec1?.show_mrp === true && defaultProSpec1?.show_sp === false
                                ? defaultProSpec1?.mrp != null
                                : defaultProSpec1?.product_id?.show_common_mrp === true &&
                                defaultProSpec1?.product_id?.show_common_sp === false
                                ? defaultProSpec1?.product_id?.common_mrp != null
                                : defaultProSpec1?.sp != null || defaultProSpec1?.product_id?.common_mrp != null;

                      // Find the matching product specification with quantity > 0 as before
                      // const foundSpec = totalProducts?.productspec?.find(
                      //   (prodSpec) =>
                      //     prodSpec?.quantity > 0 &&
                      //     prodSpec?.specification.some(
                      //       (specDetail) =>
                      //         specDetail?.specification_id ===
                      //         spec?.specification?._id
                      //     )
                      // );

                      const foundSpec = totalProducts?.productspec?.find((prodSpec) => {
                        console.log("prodSpec", prodSpec);
            
                        const isPricePresent =
                        prodSpec?.show_mrp === true && prodSpec?.show_sp === false
                          ? prodSpec?.mrp != null
                          : prodSpec?.product_id?.show_common_mrp === true &&
                            prodSpec?.product_id?.show_common_sp === false
                          ? prodSpec?.product_id?.common_mrp != null
                          : prodSpec?.sp != null || prodSpec?.product_id?.common_mrp != null;
                            
                        return (
                          prodSpec?.quantity > 0 &&
                          isPricePresent
                          &&
                          prodSpec?.specification.some(
                            (specDetail) =>
                              specDetail?.specification_id === spec?.specification?._id
                          )
                        );
                      });

                      // Determine the default spec detail ID
                      // const defaultSpecDetailId = currentRowContainsSizeId
                      //   ? sizeId
                      //   : foundSpec?.specification.find(
                      //       (specDetail) =>
                      //         specDetail?.specification_id ==
                      //         spec?.specification?._id
                      //     )?._id;

                      const defaultSpecDetailId = currentRowContainsSizeId
                              ? sizeId // If the current row contains sizeId, use it
                              : (defaultProSpec1?.quantity > 0 && isPricePresentindefaultProSpec1) ?  
                              defaultProSpec1?.specification.find(
                                (specDetail) =>
                                  specDetail?.specification_id ==
                                  spec?.specification?._id
                              )?._id
                              : foundSpec?.specification.find(
                                  (specDetail) =>
                                    specDetail?.specification_id ==
                                    spec?.specification?._id
                                )?._id;

                      // Check if common_mrp is present to handle "All" button logic
                      const isCommonMrpPresent =
                        totalProducts?.product?.common_mrp;

                      return (
                        <div key={spec?._id} className="col_flex">
                          <strong className="spec_title">
                            {spec?.specification?.name} :
                          </strong>
                          <Radio.Group
                            key={spec?._id}
                            className="row_flex"
                            optionType="button"
                            buttonStyle="solid"
                            // size="small"
                            size="large"
                            // defaultValue={isCommonMrpPresent && specIndex === 0 && spec?.specification_details?.length > 1 ? "all" : defaultSpecDetailId || spec?.specification_details[0]?._id}
                            defaultValue={
                              defaultSpecDetailId ||
                              spec?.specification_details[0]?._id
                            }
                            style={{
                              gap: "10px",
                            }}
                          >                            

                            {spec?.specification_details?.map(
                              (detail, index) => {
                                // console.log("spec->>", spec);
                                // console.log("detail->>", detail);
                                const normalize = (str) =>
                                  str?.trim().toLowerCase();

                                const compareSpecificationDetails = (
                                  specItemDetails,
                                  detailDetails
                                ) => {
                                  const normalizedSpecItemDetails =
                                    normalize(specItemDetails);
                                  const normalizedDetailDetails =
                                    normalize(detailDetails);
                                  return (
                                    normalizedSpecItemDetails ===
                                    normalizedDetailDetails
                                  );
                                };

                                let colorId;

                                if(spec?.specification?.name?.toLowerCase() === "color") {
                                  colorId = spec?.specification?._id;
                                };

                                const defaultProSpec = specData1?.filter(
                                  (item) => item?._id == productSpecId                                          
                                )?.[0];             

                                const defaultProSpec1 = foundSpectAll?.specification?.[0]?._id;  

                                const isColorSpec =
                                  spec?.specification?.name?.toLowerCase() ===
                                  "color";

                                const defaultColorSpec = defaultProSpec?.specification?.filter((item) => 
                                    item?.specification_id == colorId
                                  )?.[0]?._id;

                                const hasValidCombination =
                                  totalProducts?.productspec?.some(
                                    (prodSpec) =>
                                      prodSpec?.quantity > 0 &&
                                      prodSpec?.specification.some(
                                        (specDetail) =>
                                          compareSpecificationDetails(
                                            specDetail?.specification_details,
                                            detail?.specification_details
                                          )
                                      )
                                  );

                                const quantity =
                                  totalProducts?.productspec?.reduce(
                                    (acc, prodSpec) => {
                                      if (
                                        prodSpec?.specification.some(
                                          (specDetail) =>
                                            compareSpecificationDetails(
                                              specDetail?.specification_details,
                                              detail?.specification_details
                                            )
                                        )
                                      ) {
                                        return acc + prodSpec?.quantity;
                                      }
                                      return acc;
                                    },
                                    0
                                  );

                                // console.log("specData", specData);

                                const isPricePresent = totalProducts?.productspec?.reduce(
                                  (acc, prodSpec) => {
                                    if (
                                      prodSpec?.specification.some((specDetail) =>
                                        compareSpecificationDetails(
                                          specDetail?.specification_details,
                                          detail?.specification_details
                                        )
                                      )
                                    ) {  
                                      if (prodSpec?.show_mrp === true && prodSpec?.show_sp === false) {
                                        return prodSpec?.mrp != null ? true : false;
                                      } else if (
                                        prodSpec?.product_id?.show_common_mrp === true &&
                                        prodSpec?.product_id?.show_common_sp === false
                                      ) {
                                        return prodSpec?.product_id?.common_mrp != null ? true : false;
                                      } else {
                                        return prodSpec?.sp != null
                                          ? true
                                          : prodSpec?.product_id?.common_mrp != null
                                          ? true
                                          : false;
                                      }
                                    }
                                    return acc;
                                  },
                                  false
                                );

                                const matchingProductImage = specData1
                                  ?.filter((item) =>
                                    item?.specification?.some(
                                      (specDetail) =>
                                        specDetail?.specification_details ===
                                        detail?.specification_details
                                    )
                                  )
                                  ?.map(
                                    (matchedItem) =>
                                      matchedItem?.product_images?.[0]
                                  )
                                  ?.filter(Boolean)[0];

                                // console.log("Matching Product Image:", matchingProductImage);

                                return (
                                  <>  
                                   {isColorSpec ? (
                                           <Radio.Button
                                           key={detail?._id}
                                           value={detail?._id}
                                           defaultChecked={defaultColorSpec}
                                           // className={index === 0 ? "active" : ""}
                                          //  className={activeButtonId === detail?._id ? "active" : ""}
                                          className={activeButtonId ? (activeButtonId === detail?._id ? "active" : "") : defaultColorSpec ? (defaultColorSpec === detail?._id ? "active" : "") : index === 0 ? "active" : "" } 
                                          // disabled={
                                          //    specData?.[index]?.product_id?.common_stock
                                          //      ? specData?.[index]?.product_id?.common_stock <= 0
                                          //      : quantity <= 0
                                          //  }
                                          disabled={
                                            quantity ? quantity <= 0 : specData?.[index]?.product_id?.common_stock <= 0                                              
                                          }
                                           onClick={() => {
                                             handleSpecClick(
                                               index,
                                               detail?._id,
                                               spec?.specification?._id,
                                               totalProducts?.product?._id
                                             );
                                             setCount(1);
                                             setActiveButtonId(detail?._id);
                                           }}
                                           style={{
                                             position: "relative",
                                            //  pointerEvents:
                                            //    specData?.[0]?.product_id?.common_stock <= 0 || quantity <= 0
                                            //      ? "none"
                                            //      : "auto",
                                             border: "none",
                                             outline: "none",
                                             width: "70px",  // Set width equal to image's width
                                             height: "60px", // Set height equal to image's height
                                             display: "flex",
                                             justifyContent: "center",
                                             alignItems: "center",
                                             background: "transparent",
                                             backgroundColor: activeButtonId ? (activeButtonId === detail?._id ? "#fff" : "#fff") : index === 0 ? "#fff" : "#fff",
                                           }}
                                         >
                                           <img
                                             src={api.rootUrl1 + matchingProductImage?.image_path}
                                             alt={detail?.specification_details}
                                             style={{
                                               width: "100%",
                                               height: "100%",
                                               objectFit: "contain",
                                             }}
                                           />
                                           <div
                                             style={{
                                               zIndex: 1000,
                                              //  color: activeButtonId ? (activeButtonId === detail?._id ? "blue" : "inherit") : index === 0 ? "blue" : "inherit", // Change color if active
                                              color: activeButtonId ? (activeButtonId === detail?._id ? "blue" : "inherit") : defaultColorSpec ? (defaultColorSpec == detail?._id ? "blue" : "inherit") : index === 0 ? "blue" : "inherit", // Change color if active
                                               fontSize: "10px",
                                               textAlign: "center",
                                              //  width: "135%",
                                               width: "100%",
                                              //  marginLeft: "-15%",
                                               marginLeft: "0",
                                               lineHeight: "1em",
                                               height: "1em",
                                             }}
                                           >
                                             {detail?.specification_details}
                                           </div>
                                         </Radio.Button>
                                          ) : (                                  
                                    <Radio.Button
                                      key={detail?._id}
                                      value={detail?._id}
                                      type="primary"
                                      // size="small"
                                      size="large"
                                      className={index === 0 ? "active" : ""}
                                      // disabled={!hasValidCombination || specData?.[0]?.product_id?.common_stock ? specData?.[0]?.product_id?.common_stock <= 0 : quantity <= 0}
                                      // disabled={
                                      //   specData?.[0]?.product_id?.common_stock
                                      //     ? specData?.[0]?.product_id
                                      //         ?.common_stock <= 0
                                      //     : quantity <= 0
                                      // }
                                      disabled={
                                        quantity ? (!isPricePresent ? true : quantity <= 0) : specData?.[index]?.product_id?.common_stock <= 0                                              
                                      }
                                      onClick={() => {
                                        handleSpecClick(
                                          index,
                                          detail?._id,
                                          spec?.specification?._id,
                                          totalProducts?.product?._id
                                        );
                                        setCount(1);
                                      }}
                                      style={{
                                        position: "relative",
                                        pointerEvents: specData?.[0]?.product_id
                                          ?.common_stock
                                          ? specData?.[0]?.product_id
                                              ?.common_stock <= 0
                                            ? "none"
                                            : "auto"
                                          : quantity <= 0
                                          ? "none"
                                          : "auto",
                                        borderTopRightRadius: "0px",
                                        borderTopLeftRadius: "0px",
                                        borderBottomLeftRadius: "0px",
                                        borderBottomRightRadius: "0px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          opacity: specData?.[0]?.product_id
                                            ?.common_stock
                                            ? specData?.[0]?.product_id
                                                ?.common_stock <= 0
                                              ? 0.5
                                              : 1
                                            : quantity <= 0
                                            ? 0.5
                                            : 1,
                                        }}
                                      >
                                        {detail?.specification_details}
                                      </span>
                                      {(specData?.[0]?.product_id?.common_stock
                                        ? specData?.[0]?.product_id
                                            ?.common_stock <= 0 ||
                                          !specData?.[0]?.product_id
                                            ?.common_stock
                                        : quantity <= 0 || !quantity) && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            width: "30px",
                                            height: "30px",
                                            transform: "translate(-50%, -50%)",
                                            zIndex: 2,
                                          }}
                                        >
                                          <div
                                            style={{
                                              position: "absolute",
                                              width: "170%",
                                              height: "1px",
                                              backgroundColor: "#ced4da",
                                              top: "50%",
                                              left: "-10px",
                                              transform: "rotate(34deg)",
                                            }}
                                          />
                                          <div
                                            style={{
                                              position: "absolute",
                                              width: "170%",
                                              height: "1px",
                                              backgroundColor: "#ced4da",
                                              top: "50%",
                                              left: "-10px",
                                              transform: "rotate(-35deg)",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Radio.Button>
                                     )} 
                                  </>
                                );
                              }
                            )}
                          </Radio.Group>
                        </div>
                      );
                    })}
                </div>
                <Divider />
                <div className="individual_product_right_wrap">
                  <div className="Mb_qty_sft">
                    <div className="Mb_qty_sft1">
                      <CheckCircleOutlined style={{ fontSize: "30px" }} />
                      <p>Quality Guaranteed</p>
                    </div>
                    <Divider type="vertical" style={{ height: "5em" }} />
                    <div className="Mb_qty_sft1">
                      <LockOutlined style={{ fontSize: "30px" }} />
                      <p>Secure Payment</p>
                    </div>
                    <Divider type="vertical" style={{ height: "5em" }} />
                    <div className="Mb_qty_sft1">
                      <FireOutlined style={{ fontSize: "30px" }} />
                      <p>Trending Collections</p>
                    </div>
                  </div>

                  <div className="purchase">
                    *Delivery assurance is subject to our delivery locations
                    staying open as per govt. regulations.
                  </div>

                  <div className="shop_btn">
                    <div className="cart_quantity">
                      <Button
                        onClick={() => handleChangeIncrease("minus")}
                        disabled={
                          (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                  ? (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock <= 0
                                  : foundSpectQty
                                  ? foundSpectQty <= 0
                                  : specData?.length <= 0
                              }
                        // disabled={foundSpectQty ? foundSpectQty <= 0 : specData?.[0]?.product_id?.common_stock ? specData?.[0]?.product_id?.common_stock <= 0 : specData?.length <= 0}
                        style={{
                          borderTopRightRadius: "0px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                      >
                        <MinusOutlined />
                      </Button>
                      <InputNumber
                        value={count}
                        type="number"
                        readOnly
                        min={1}
                        style={{
                          textAlign: "center",
                          outline: "none",
                          border: "none",
                          width: "70%",
                          borderTopRightRadius: "0px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                      />
                      <Button
                        onClick={() =>
                          handleChangeIncrease(
                            "plus",
                            (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                    ? (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                    : foundSpectQty
                                    ? foundSpectQty
                                    : (foundSpectAll ? foundSpectAll : specData?.[0])?.quantity
                                )
                        }
                        disabled={
                          (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                          ? Number(
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                            ) <= 0
                          : foundSpectQty
                          ? foundSpectQty <= 0
                          : specData?.length <= 0
                        }
                        style={{
                          borderTopRightRadius: "0px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                      >
                        <PlusOutlined />
                      </Button>
                    </div>
                    {(() => {
                            const matchingCartItem = Array.isArray(cart)
                              ? cart.find(
                                  (cartItem) =>
                                    cartItem?.product_id?.product_name ===
                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.product_name
                                )
                              : (foundSpectAll ? foundSpectAll : specData?.[0]);

                            // console.log("Matching cart item:", matchingCartItem);

                            const cartQuantity = matchingCartItem
                              ? matchingCartItem?.product_id?.common_stock
                                ? matchingCartItem?.product_id?.common_stock
                                : matchingCartItem?.product_spec?.quantity
                              : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                              ? (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                              : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_spec?.quantity;

                            const cartAvailableQuantity = matchingCartItem?.qty;

                            // console.log("Cart quantity:", cartQuantity);
                            // console.log("Cart available quantity:", cartAvailableQuantity);

                            // Disable the button if current count reaches cart quantity
                            isButtonDisabled =
                              Number(cartAvailableQuantity) ==
                                Number(cartQuantity) ||
                              !((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                ? Number(
                                    (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                                  ) > 0
                                : (foundSpectAll ? foundSpectAll : specData?.[0])?.product_spec?.quantity
                                ? (foundSpectAll ? foundSpectAll : specData?.[0])?.product_spec?.quantity > 0
                                : (foundSpectAll ? foundSpectAll : specData?.[0])?.quantity > 0);

                            // console.log("isButtonDisabled:", isButtonDisabled);
                            // console.log("isButtonDisabled111:", cartAvailableQuantity == cartQuantity);
                            // console.log("isButtonDisabled333:", specData?.[0]?.quantity > 0);
                            // console.log("isButtonDisabled444:", specData?.[0]?.product_spec?.quantity);
                            // console.log("isButtonDisabled555:", specData?.[0]?.product_id?.common_stock);
                            // console.log("isButtonDisabled222:", (specData?.[0]?.product_id?.common_stock
                            //   ? Number(specData?.[0]?.product_id?.common_stock) > 0
                            //   : specData?.[0]?.product_spec?.quantity ? specData?.[0]?.product_spec?.quantity > 0
                            // : specData?.[0]?.quantity > 0));

                            return null;
                          })()}
                    {/* {console.log("isButtonDisabled", isButtonDisabled)} */}
                    {/* {console.log("foundSpectQty", foundSpectQty)} */}
                    
                    <Button
                      htmlType="submit"
                      loading={loading}
                      type="primary"
                      onClick={addToCart}
                      disabled={
                        foundSpectQty
                        ? !(foundSpectQty > 0)
                        : !((foundSpectAll ? foundSpectAll : specData?.[0])?.quantity > 0)
                    }
                      // disabled={
                      //   isButtonDisabled
                      //     ? isButtonDisabled
                      //     : specData?.[0]?.product_id?.common_stock
                      //     ? !(
                      //         Number(specData?.[0]?.product_id?.common_stock) >
                      //         0
                      //       )
                      //     : foundSpectQty
                      //     ? !(foundSpectQty > 0)
                      //     : !(specData?.[0]?.quantity > 0)
                      // }
                      style={{
                        width: "100%",
                        borderTopRightRadius: "0px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: "0px",
                        marginTop: "20px",
                      }}
                    >
                      <ShoppingCartOutlined
                        style={{ display: "inline-flex" }}
                      />
                      Add to Cart
                    </Button>
                    <Button
                      htmlType="submit"
                      loading={wishListLoading}
                      type="primary"
                      onClick={addToWiseList}
                      disabled={
                        (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                          ? Number(
                              (foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                            ) < 0
                          : foundSpectQty
                          ? foundSpectQty < 0
                          : specData?.length < 0
                      }
                      style={{
                        width: "100%",
                        borderTopRightRadius: "0px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: "0px",
                        marginTop: "20px",
                      }}
                    >
                      <HeartOutlined style={{ display: "inline-flex" }} />
                      WishList
                    </Button>
                  </div>
                  <div style={{ color: "red" }}>
                  {(foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock
                            ? Number((foundSpectAll ? foundSpectAll : specData?.[0])?.product_id?.common_stock) >
                              0
                              ? ""
                              : `*Out of Stock`
                            : foundSpectQty
                            ? foundSpectQty > 0
                              ? ""
                              : `*Out of Stock`
                            : (foundSpectAll ? foundSpectAll : specData?.[0])?.quantity > 0
                            ? ""
                            : `*Out of Stock`}
                  </div>
                </div>
                <Divider />
                <div className="Mb_Pro_info">
                  <h5>Product Information</h5>
                  <Collapse
                    expandIconPosition="end"
                    ghost
                    items={items}
                    // expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
                    expandIcon={({ isActive }) => (
                      <RotateIcon isActive={isActive}>
                        {isActive ? <MinusOutlined /> : <PlusOutlined />}
                      </RotateIcon>
                    )}
                  />
                </div>
                <Divider />
                <div className="Mb_related_product">
                  <h5>You May Also Like</h5>
                  <div className="related_grid">
                    {/* {relatedProducts?.slice(0, 4).map((item, index) => {
                          return (
                            <div
                              className="product_align_filter_box"
                              key={index}
                              onClick={() => relatedProductClickCategory(item, index)}
                            >
                              <div className="p_image">
                                <img
                                  src={api.rootUrl1 + item?.images }
                                  alt="Default"
                                />
                              </div>
                              <div className="p_content">
                                <h6 style={{ color: "black", lineHeight: "25px" }}>
                                  {item?.product_name}&nbsp;
                                    {item?.specification?.map((spec) => (
                                      <Tag color="green">
                                          {spec?.specification_details}
                                      </Tag>)
                                    )}
                                </h6>
                                <div>
                                </div>
                                <div className="price">                                  
                                </div>
                              </div>
                            </div>
                          );
                        })} */}

                    <Swiper
                      modules={[Navigation]}
                      spaceBetween={10}
                      slidesPerView={3}
                      navigation={false}
                      // loop={true}
                      breakpoints={{
                        540: { slidesPerView: 3 },
                      }}
                    >
                      {relatedProducts?.map((item, index) => (
                        <SwiperSlide key={index}>
                          <div
                            className="product_align_filter_box"
                            onClick={() =>
                              relatedProductClickCategory(item, index)
                            }
                          >
                            <div className="p_image">
                              <img
                                src={api.rootUrl1 + item?.images}
                                alt="Default"
                              />
                            </div>
                            <div className="p_content">
                              <h6
                                style={{ color: "black", lineHeight: "25px" }}
                              >
                                {item?.product_name}&nbsp;
                                {item?.specification?.map((spec, specIndex) => (
                                  <Tag color="green" key={specIndex}>
                                    {spec?.specification_details}
                                  </Tag>
                                ))}
                              </h6>
                              <div className="price">
                                {/* You can add price details here */}
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
                <Modal
                  open={open}
                  onCancel={handleCancel}
                  onOk={handleOk}
                  footer={null}
                  title="Login"
                  width={400}
                >
                  <Form
                    layout="vertical"
                    form={loginForm}
                    name="Login_Form"
                    onFinish={login}
                  >
                    <Form.Item
                      label="Enter Mobile number"
                      name="buyer_number"
                      rules={[
                        {
                          required: true,
                          pattern: /^[0-9]{10}$/,
                          message: "Please enter your Mobile number!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Enter Password"
                      name="buyer_password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loginLoading}
                        className="primary_btn"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                  <Or>or</Or>

                  <NewAccount>
                    <Link to="/register">Click Here</Link>
                    to Register a Account
                  </NewAccount>
                </Modal>
                <Modal
                  open={open1}
                  onCancel={handleCancel1}
                  onOk={handleOk1}
                  footer={null}
                  title="Add Address"
                  width={300}
                >
                  <Button
                    type="primary"
                    onClick={() => navigate("/add-address")}
                  >
                    {" "}
                    Go to Address Screen{" "}
                  </Button>
                </Modal>
              </>
            )}
          </MobileProductSection>
        </>
      )}
    </>
  );
};

export default Products;

const ProductSection = styled.section`
  width: 100%;
  margin: 60px 0;

  .breadcrumb_section {
    margin: 0 0 15px;
    padding: 0 0 10px;
  }
  .individual_product_section {
    display: flex;
    flex-direction: column;
    gap: 100px;
    width: 100%;
  }

  .individual_product_left_wrap {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }

  .individual_product_align {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .individual_product_left {
    width: 47%;
    display: inline-block;
  }
  .individual_product_right {
    width: 47%;
    display: inline-block;
  }

  .video_icon {
    position: absolute;
    left: 43%;
    bottom: -70px;
  }

  .video_icon img {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .video_icon img:hover {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
  }

  .description_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
    border: #cccccc 1px solid;
    padding: 10px;
    border-radius: 10px;

    /* .description_tab{} */
  }

  .remove-button {
    background-color: #ffffff; /* White background, you can change it */
    color: #555555; /* Dark gray text, you can change it */
    border: 1px solid #cccccc; /* Light gray border, you can change it */
    padding: 6px 10px;
    margin-left: 5px; /* Adjust the margin as needed */
    cursor: pointer;
    border-radius: 4px;
    position: relative;
  }

  .slider__prev,
  .slider__next {
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    padding: 0;
    text-align: center;
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.3);
    background-color: hsla(0, 0%, 100%, 0.98);
    cursor: pointer;
    border: 1px solid #e0e0e0;
  }
  .slider__prev:focus,
  .slider__next:focus {
  }

  .cart_quantity {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* gap: 9px; */
  }
  .cart_quantity button {
    padding: 0;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    margin-left: 1px;
  }
  .cart_quantity button span {
    font-size: 16px;
    font-family: var(--semibold);
  }
  .cart_quantity input {
    padding: 5px 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: 60px;
    text-align: center;
    outline: none;
  }
  /* 
  .active:before,
  .active:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 1px;
    background-color: #555555;
    opacity: 0.5;
  } */

  /* .active:before {
    transform: translate(-50%, -50%) rotate(60deg);
  } */
  .swiper {
    flex-direction: column;
    display: flex;
    transition-duration: 0ms;
    transform: translate3d(0px, 0px, 0px);
    /* border: 1px solid #f5f5f5; */
  }

  /* .active:after {
    transform: translate(75%, -50%) rotate(-60deg);
  } */

  /* .active:hover {
    border-color: #464444;
  } */

  .individual_product_left .main_image {
    /* border: 1px solid #f5f5f5; */
    border-radius: 7px;
    overflow: hidden;
    display: flex;
    width: 600px;
    height: 500px;

    img {
      border-radius: 7px;
    }
  }

  .individual_product_left .thump_image {
    display: flex;
    flex-direction: column;
    gap: 6px;
    /* grid-template-columns: repeat(5, 1fr);
    gap: 12px 10px; */
    /* margin: 0 16px; */
  }

  .swiper-wrapper {
    width: 100px !important;
    height: 400px !important;
  }

  .individual_product_left .thump_image .thump_image_box {
    /* border: 1px solid #eeeaea; */
    border-radius: 5px;
    overflow: hidden;
    width: 100px;
    /* height: 25% !important; */
    height: 35% !important;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      /* border-radius: 5px; */
      /* aspect-ratio: 1/1; */
    }
  }

  .individual_product_left .thump_image .thump_image_box_video {
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    overflow: hidden;
    height: 100px;
    width: 100px;
  }

  .swiper-slide .thump_image_box {
    margin-bottom: 0px !important;
  }

  .video_icon1 {
    /* position: absolute; */
    /* left: 43%; */
    /* bottom: -70px; */
    /* bottom: -6%; */
    /* left: 54%; */
    /* transform: translate(-50%, -50%); */
    z-index: 100;
  }

  .video_icon1 img {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .video_icon1 img:hover {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
  }

  .individual_product_right_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-direction: column;
  }

  .individual_product_right_wrap h1 {
    font-size: 22px;
    font-family: ${font?.semibold};
  }
  .sku {
    font-size: 15px;
    font-family: ${font?.semibold};
  }
  .price {
    /* display: flex; */
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .price div:nth-child(2) {
    font-size: 20px;
    font-family: ${font?.bold};
    color: ${theme?.black};
    line-height: 1.5;
    font-weight: 600;
  }
  .price div:nth-child(1) {
    font-size: 12px;
    font-family: ${font?.light};
    color: ${theme?.gray};
    /* text-decoration: line-through; */
  }
  .product_description {
    font-size: 15px;
    color: ${theme?.gray};
    line-height: 1.5;
    font-family: ${font?.semibold};
    margin-top: 20px;
    margin-bottom: 20px;
    text-overflow: ellipsis;
    max-lines: 4;
  }

  .row_flex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 20px;
    /* gap: 9px 9px; */
  }
  .row_flex button span {
    font-size: 13px;
  }
  .purchase {
    font-size: 15px;
    color: ${theme?.gray};
    line-height: 1.5;
  }

  .shop_btn {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .product_specification {
    display: flex;
    flex-direction: column;
  }
  .product_specification .col_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    padding: 5px;
    line-height: 5em;
  }

  .product_specification .row_flex {
    display: flex;
    flex-direction: row;
    line-height: 3em;
    align-items: center;
  }

  .product_specification_list table tbody tr td {
    padding: 5px 0;
  }
  .product_specification_list table tbody tr td:nth-child(1) {
    font-size: 15px;
    color: ${theme?.black};
    font-family: ${font?.medium};
  }
  .product_specification_list table tbody tr td:nth-child(2) {
    padding: 0 10px;
  }
  .product_specification_list table tbody tr td:nth-child(3) {
    font-size: 15px;
    color: ${theme?.gray};
    font-family: ${font?.light};
  }

  .review_section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .Review_Section {
    height: 300px;
    overflow-x: auto;
    width: 75%;
  }
  .Review_Section::-webkit-scrollbar {
    width: 8px;
  }

  .Review_Section::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  .Review_Section::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  .review_section .review_left {
    width: 100%;
    /* width: 65%; */
    display: inline-block;
  }
  .review_section .review_right {
    width: 30%;
    display: inline-block;
  }
  .review_section h2 {
    font-size: 23px;
    margin: 0 0 18px;
  }

  .product_align_filter_box {
    width: 100%;
    border-radius: 7px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
  }
  .p_image {
    width: 100%;
    height: 70%;
    /* border: 1px solid blue; */
    overflow: hidden;
    display: inline-block;
  }
  .p_image img {
    width: 100%;
    height: 100%;
    padding: 3%;
    object-fit: contain;
  }
  .p_content {
    padding: 1px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 25%;
  }
  .related_product .p_content h2 {
    font-size: 17px;
    font-family: ${font?.bold};
    color: ${font?.color};
    margin: 0;
    height: 75%;
  }
  .price {
    /* display: flex; */
    align-items: center;
    gap: 8px 11px;
    height: 25%;
  }
  .price div:nth-child(2) {
    color: ${theme?.color};
  }
  .price div:nth-child(1) {
    font-size: 12px;
    color: ${theme?.gray};
    /* text-decoration: line-through; */
  }
  .p_content button {
    padding: 2px 9px;
    height: auto;
    font-size: 14px;
  }
  .p_content button * {
    font-size: 14px;
  }

  .related_product h2 {
    font-size: 23px;
    margin: 0 0 18px;
  }

  .related_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px 20px;
  }

  .responsive_Slider {
    display: none;
    width: 50%;
    height: 100px;
    margin: 20px auto !important;
    overflow: hidden;
    border: 1px solid red;
  }

  .ant-collapse-header {
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  @media screen and (max-width: 1024px) {
    max-width: 1024px;
    .related_grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px 20px;
    }
    .shop_btn {
      flex-direction: column;
    }
    .main_image {
      height: 325px !important;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 768px;
    .related_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 25px 20px;
    }
    .main_image {
      height: 323px !important;
    }
    .shop_btn {
      flex-direction: column;
    }
    .thump_image {
      display: block !important;
    }
    /* .responsive_Slider {
      display: block;
      border: none;
    } */
    .col_flex .spec_title {
      font-size: small !important;
    }
    .col_flex {
      margin-left: 0px !important;
      flex-wrap: wrap;
      padding: 0px !important;
    }
    .ant-radio-button-wrapper {
      padding: 0px 10px !important;
    }
  }

  @media screen and (max-width: 540px) {
    display: none;
    max-width: 540px;
    .related_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 25px 20px;
    }
    .main_image {
      height: 323px !important;
    }
    .shop_btn {
      flex-direction: column;
    }
    .thump_image {
      display: block !important;
      /* border: 1px solid red; */
    }
    .swiper {
      height: 280px !important;
    }
    .thump_image_box {
      height: 130px !important;
    }
    .responsive_Slider {
      display: none !important;
      /* border: none; */
    }
    .individual_product_align {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      max-width: 540px;
      /* border: 1px solid red; */
    }
    .individual_product_left {
      width: 63% !important;
      /* margin: 0 auto; */
      /* border: 1px solid red; */
    }
    .individual_product_right {
      width: 63% !important;
      /* border: 1px solid red; */
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 425px) {
    display: none;
    max-width: 425px;
    .related_grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 25px 20px;
    }
    .main_image {
      height: 275px !important;
    }
    .shop_btn {
      flex-direction: column;
    }
    .thump_image {
      display: block !important;
      /* border: 1px solid red; */
    }
    .swiper {
      height: 230px !important;
    }
    .thump_image_box {
      height: 100px !important;
    }
    .responsive_Slider {
      display: none !important;
    }
    .individual_product_align {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      max-width: 425px;
      /* border: 1px solid red; */
    }
    .individual_product_left {
      width: 47% !important;
    }
    .individual_product_right {
      width: 47% !important;
      margin-top: 20px;
    }
    .individual_product_right_wrap h1 {
      font-size: 20px;
    }
    .individual_product_right_wrap .sku {
      font-size: 14px;
    }
    .individual_product_right_wrap .price .sp {
      font-size: 16px;
    }
    .individual_product_right_wrap .price .mrp {
      font-size: 14px;
    }
    .description_tab {
      font-size: 13px;
    }
    .label h5 {
      font-size: 20px !important;
    }
    .related_product h2 {
      font-size: 20px;
    }
    .p_content h2 {
      font-size: 16px !important;
    }
    .p_content .price .sp {
      font-size: 16px !important;
    }
    .p_content .price .mrp {
      font-size: 14px !important;
    }
  }

  @media screen and (max-width: 375px) {
    display: none;
    max-width: 375px;
    .wrapper {
      padding: 60px 20px !important;
    }
    .related_grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 25px 20px;
    }
    .main_image {
      height: 260px !important;
    }
    .shop_btn {
      flex-direction: column;
    }
    .thump_image {
      display: block !important;
      /* border: 1px solid red; */
    }
    .swiper {
      height: 210px !important;
    }
    .thump_image_box {
      height: 100px !important;
    }
    .responsive_Slider {
      display: none !important;
    }
    .individual_product_align {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      max-width: 425px;
      /* border: 1px solid red; */
    }
    .individual_product_left {
      width: 46% !important;
    }
    .individual_product_right {
      width: 46% !important;
      margin-top: 20px;
    }
    .individual_product_right_wrap h1 {
      font-size: 18px;
    }
    .individual_product_right_wrap .sku {
      font-size: 12px;
    }
    .individual_product_right_wrap .price .sp {
      font-size: 14px;
    }
    .individual_product_right_wrap .price .mrp {
      font-size: 12px;
    }
    .description_tab {
      font-size: 12px;
    }
    .label h5 {
      font-size: 18px !important;
    }
    .related_product h2 {
      font-size: 18px;
    }
    .p_content h2 {
      font-size: 14px !important;
    }
    .p_content .price .sp {
      font-size: 12px !important;
    }
    .p_content .price .mrp {
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 320px) {
    display: none;
    max-width: 320px;
    .wrapper {
      padding: 60px 20px !important;
    }
    .related_grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 25px 20px;
    }
    .main_image {
      height: 200px !important;
    }
    .shop_btn {
      flex-direction: column;
    }
    .thump_image {
      display: block !important;
      /* border: 1px solid red; */
    }
    .swiper {
      height: 150px !important;
    }
    .thump_image_box {
      height: 100px !important;
    }
    .responsive_Slider {
      display: none !important;
    }
    .individual_product_align {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      max-width: 425px;
      /* border: 1px solid red; */
    }
    .individual_product_left {
      width: 39% !important;
    }
    .individual_product_right {
      width: 39% !important;
      margin-top: 20px;
    }
    .individual_product_left_wrap {
      gap: 10px !important;
    }
    .individual_product_right_wrap h1 {
      font-size: 16px;
    }
    .individual_product_right_wrap .sku {
      font-size: 10px;
    }
    .individual_product_right_wrap .price .sp {
      font-size: 12px;
    }
    .individual_product_right_wrap .price .mrp {
      font-size: 10px;
    }
    .description_tab {
      font-size: 10px;
    }
    .label h5 {
      font-size: 16px !important;
    }
    .related_product h2 {
      font-size: 16px;
    }
    .p_content h2 {
      font-size: 12px !important;
    }
    .p_content .price .sp {
      font-size: 10px !important;
    }
    .p_content .price .mrp {
      font-size: 10px !important;
    }
    .breadcrumb_section {
      .ant-breadcrumb-link {
        font-size: 10px !important;
      }
    }
  }
`;

const MobileProductSection = styled.section`
  display: none;

  @media screen and (max-width: 540px) {
    display: block;

    .Mb_ProName_sec {
      margin-top: 5%;
      padding: 0 4%;
    }

    .price {
      /* display: flex; */
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }
    .price div:nth-child(2) {
      font-size: 20px;
      font-family: ${font?.bold};
      color: ${theme?.black};
      line-height: 1.5;
      font-weight: 600;
    }
    .price div:nth-child(1) {
      font-size: 12px;
      font-family: ${font?.light};
      color: ${theme?.gray};
      /* text-decoration: line-through; */
    }

    .product_specification {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
    .product_specification .col_flex {
      /* display: flex; */
      flex-direction: row;
      align-items: center;
      margin-left: 10px;
      padding: 5px;
      line-height: 3em;
    }

    .product_specification .row_flex {
      display: flex;
      flex-direction: row;
      line-height: 3em;
      align-items: center;
    }

    .product_specification .col_flex .spec_title {
      padding-right: 10px;
    }

    .individual_product_right_wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      flex-direction: column;
      padding: 0 4%;
    }

    .purchase {
      font-size: 12px;
      color: ${theme?.gray};
      line-height: 1.5;
      text-align: center;
      margin-bottom: 25px;
    }

    .shop_btn {
      /* display: flex; */
      /* flex-wrap: wrap; */
      /* gap: 15px; */
    }

    .cart_quantity {
      display: flex;
      flex-wrap: wrap !important;
      align-items: center;
      justify-content: center;
      /* gap: 9px; */
    }

    .cart_quantity button {
      padding: 0;
      height: 40px;
      width: 40px;
      /* border-radius: 100%; */
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -1px;
      margin-left: 1px;
    }
    .cart_quantity button span {
      font-size: 16px;
      font-family: var(--semibold);
    }
    .cart_quantity input {
      padding: 8px 8px;
      border: 1px solid #d9d9d9;
      /* border-radius: 4px; */
      /* width: 60px; */
      width: 80%;
      text-align: center;
      outline: none;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .Mb_Pro_info {
      padding: 0 4%;
    }

    .Mb_related_product {
      padding: 0 4%;
    }

    /* .Mb_related_product .p_content h2 {
      font-size: 17px;
      font-family: ${font?.bold};
      color: ${font?.color};
      margin: 0;
      height: 75%;
    }

    .Mb_related_product h2 {
      font-size: 23px;
      margin: 0 0 18px;
    } */

    /* .related_grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 25px 20px;
    } */

    .Mb_qty_sft {
      display: flex;
    }

    .Mb_qty_sft1 {
      /* margin: 0 auto !important; */
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        text-align: center;
        word-wrap: break-word;
        margin-bottom: 0px !important;
        margin-top: 10px;
        font-weight: 700;
        line-height: 1.1;
        font-size: small;
      }
    }
  }
`;

const LoginSection = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 580px) {
    padding: 0 20px;
  }
  .primary_btn {
    background: ${styles?.background1};
    /* border: 1px solid ${styles?.colorapi}; */
  }
`;
const LoginAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  flex-wrap: wrap;
  box-shadow: 0 0 40px rgb(0 0 0 / 9%);
  border-radius: 5px;
  margin: 70px 0;
  min-height: 300px;

  @media screen and (max-width: 580px) {
    width: 100%;
    margin: 40px 0;
  }
`;

const LoginRight = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 35px 35px;
  input {
    width: 100%;
    padding: 8px 14px;
  }
  .Forgot_Password {
    width: 100%;
    text-align: right;
    margin: 0;
    Link {
      color: #000;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  input[type="password"] {
    width: 100%;
    padding: 4px 0px;
  }
  .ant-space {
    width: 100%;
    margin: 0 0 10px;
  }
  button {
    padding: 7px 20px;
    height: auto;
    font-size: 15px;
    background: ${styles.background};
    border: 1px solid ${styles.background};
  }

  .ant-row.ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form label {
    width: 100%;
    display: inline-block;
    text-align: left;
  }

  @media screen and (max-width: 580px) {
    padding: 30px 25px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  color: #000;
  font-weight: 700;
  width: 100%;
  margin: 0 0 20px;
  text-transform: uppercase;
`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;
const LoginOtp = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin: 10px 0 0 0;
  width: 100%;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
const ForgetPassword = styled.div`
  margin: 25px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
`;
const NewAccount = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
