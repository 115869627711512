import React, {useEffect, useState} from "react";
import styled from "styled-components";
import API from "../ApiService/ApiService";
import CheckoutAddress from "../Components/Checkout/CheckoutAddress";
import CheckoutStepper from "../Components/Checkout/CheckoutStepper";
import {useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {message} from "antd";
const PriceList = ({setTotalAmount, totalAmount, refresh}) => {
  // console.log("totalAmount--->", totalAmount);
  // console.log("setTotalAmount--->", setTotalAmount);
  // console.log("PriceListrefresh--->", refresh);
  const navigate = useNavigate();
  // const [totalAmount, setTotalAmount] = useState(0);
  const [cart, setCart] = useState([]);
  const api = new API();

  const isDecimal = useSelector((state) => state?.priceDecimalsSlice?.pricedecimal?.[0]);
  // console.log("isDecimal==>", isDecimal);

  // useEffect(() => {
  //   calculateTotalAmount();
  // }, [cart]);

  useEffect(() => {
    getAllCart();
  }, []);

  useEffect(() => {
    // window.location.reload();
    getAllCart();
  }, [refresh]);

  const getAllCart = () => {
    api.getcart().then((res) => {
      if (res?.response?.data?.status === 500) {
        message.error(res?.response?.data?.message);
        navigate("/delivery-address");
      } else {
        console.log("res--->", res?.data?.data?.cart_item?.length);
        if (res?.data?.data?.cart_item?.length == 0) {
          message.warning("Cart is empty... Add Product to Cart");
          navigate("/");
        } else {          
          setCart(res?.data?.data);
        }
      }
    });
  };

  // const calculateTotalAmount = () => {
  //   let totalPrice = 0;

  //   for (const item of cart) {
  //     const itemPrice = item?.title?.quantity * item?.title?.special_price;
  //     totalPrice += itemPrice;
  //   }

  //   setTotalAmount(totalPrice);
  // };

  const totalAmountAll = cart?.cart_item?.reduce((total, item) => {
    // console.log("item", item);
    let productAmount = 0;
    
    if (item?.show_mrp === true && item?.show_sp == false) {
      productAmount = item?.product_spec?.discount_id?.discount_id?.discount > 0 ? 
                      (item?.discount_type === "Percentage" ? (                        
                          Number(item?.mrp - (item?.mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) + // Apply percentage discount
                          Number((item?.mrp - (item?.mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation                       
                      ) 
                      : item?.discount_type === "Price" ?
                      (                        
                          Number(item?.mrp - item?.product_spec?.discount_id?.discount_id?.discount) + // Apply flat discount
                          Number((item?.mrp - item?.product_spec?.discount_id?.discount_id?.discount) * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation                       
                      )
                      :
                      (
                        (item?.mrp || 0) + 
                        ((item?.mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      )
                      )
                      :
                      ((item?.mrp || 0) + 
                      ((item?.mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100)));
    } else if (item?.product_id?.show_common_mrp === true && item?.product_id?.show_common_sp == false) {
      productAmount = item?.product_spec?.discount_id?.discount_id?.discount > 0 ?
                      (item?.discount_type === "Percentage" ? (                        
                          Number(item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) + // Apply percentage discount
                          Number((item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation                        
                      ) : item?.discount_type === "Price" ? (                        
                          Number(item?.product_id?.common_mrp - item?.product_spec?.discount_id?.discount_id?.discount) + // Apply flat discount
                          Number((item?.product_id?.common_mrp - item?.product_spec?.discount_id?.discount_id?.discount) * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation                        
                      ) : (
                        (item?.product_id?.common_mrp || 0) + 
                      ((item?.product_id?.common_mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      )
                      )
                      :
                      ((item?.product_id?.common_mrp || 0) + 
                      ((item?.product_id?.common_mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100)));
    } else {
      productAmount = item?.product_spec?.discount_id?.discount_id?.discount > 0 ? 
                      (item?.discount_type === "Percentage" ? (                        
                          Number(
                            (item?.sp || item?.product_id?.common_sp || 0) - ((item?.sp || item?.product_id?.common_sp || 0) * item?.product_spec?.discount_id?.discount_id?.discount / 100) 
                          ) +
                          Number(
                            ((item?.sp || item?.product_id?.common_sp || 0) - ((item?.sp || item?.product_id?.common_sp || 0) * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                          )                        
                      ) : item?.discount_type === "Price" ? (                        
                          Number(
                            (item?.sp || item?.product_id?.common_sp || 0) - item?.product_spec?.discount_id?.discount_id?.discount 
                          ) +
                          Number(
                            ((item?.sp || item?.product_id?.common_sp || 0) - item?.product_spec?.discount_id?.discount_id?.discount) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                          )                        
                      ) : (
                        (item?.sp || item?.product_id?.common_sp || 0) + 
                      ((item?.sp || item?.product_id?.common_sp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      )
                      )
                      :
                      ((item?.sp || item?.product_id?.common_sp || 0) + 
                      ((item?.sp || item?.product_id?.common_sp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100)));
    }
  
    // Return the total amount including quantity
    return total + (productAmount * (item?.qty || 1));
  }, 0);

  return (
    <PriceListSection>
      <div className="cart_right">
       
          <table>
            <tbody>
              <tr>
                <td>Selling Price ({cart?.cart_item?.length} items)</td>
                 
                <td>{
                // isDecimal?.isDecimal === false 
                // ? Math.round(Number(cart?.delivery_charge_before_total)) 
                // : Number(cart?.delivery_charge_before_total).toFixed(isDecimal?.Decimal_points)

                (isDecimal?.isDecimal === false
                  ? Math.round(totalAmountAll)
                  : totalAmountAll?.toFixed(isDecimal?.Decimal_points))
                }
                </td>
              </tr>
              {/* {cart?.cart_item?.map((item)=> item?.discount_amount ) ? <tr><td>Discount</td><td>{Number(cart?.discount_amount).toFixed(2)}</td></tr>  :<tr><td></td></tr> }
              <tr>
                <td>Tax</td>
                <td>{Number(cart?.totalTax).toFixed(2)}</td>
              </tr>  */}
              <tr>
                <td>Delivery Charges</td>
                <td>{isDecimal?.isDecimal === false 
                  ? Math.round(Number(cart?.total_delivery_charge)) 
                  : Number(cart?.total_delivery_charge).toFixed(isDecimal?.Decimal_points)}
                </td>
              </tr>
              <tr>
                <td>Total Amount</td>
                <td>{
                // isDecimal?.isDecimal === false 
                //   ? Math.round(Number(cart?.calculated_total)) 
                //   : Number(cart?.calculated_total).toFixed(isDecimal?.Decimal_points)

                (isDecimal?.isDecimal === false
                  ? Math.round(Number(totalAmountAll + cart?.total_delivery_charge))
                  : Number(totalAmountAll + cart?.total_delivery_charge).toFixed(isDecimal?.Decimal_points))
                  }
                </td>
              </tr>
            </tbody>
          </table>

      </div>
    </PriceListSection>
  );
};

export default PriceList;

const PriceListSection = styled.section`
  margin: 45px 0 0 0;
  padding-right: 20px;

  .cart_right {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 25px 15px;
    max-width: 100%;
    margin: auto;
  }
  table {
    width: 100%;
  }
  tbody {
    width: 100%;
  }
  tbody tr {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
  tbody tr:not(:last-child) {
    margin: 0 0 20px;
  }
  tbody tr td:nth-child(1) {
    font-family: var(--semibold);
    text-align: left;
    padding: 0 10px 20px 0px;
    border-bottom: 1px solid #e9e9e9;
  }
  tbody tr td:nth-child(2) {
    font-family: var(--regular);
    text-align: right;
    padding: 0 0 20px 10px;
    border-bottom: 1px solid #e9e9e9;
  }
  tbody tr:last-child td:nth-child(1),
  tbody tr:last-child td:nth-child(2) {
    border-bottom: 0px;
  }
  tbody tr:last-child td:nth-child(1) {
    padding: 0 10px 0px 0px;
  }
  tbody tr:last-child td:nth-child(2) {
    padding: 0 0 0px 10px;
  }

  @media screen and (max-width: 500px) {
    .cart_right table {
      width: 100% !important;
    }
  }
`;
