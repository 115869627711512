import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "../ApiService/ApiService";
import {
  Tag,
  Button,
  Divider,
  Popconfirm,
  message,
  Empty,
  Input,
  Spin,
  Table,
  Modal
} from "antd";
import { DeleteOutlined, InfoOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import styles from "../ApiService/Data";
import "../../src/Assets/Css/style.css";
import { Spinner } from "react-bootstrap";
import dayjs from "dayjs";

import { cartSuccess, cartStart, cartFailure } from "../Redux/slices/cart";
import {
  wiseListStart,
  wiseListSuccess,
  wiseListFailure,
} from "../Redux/slices/wishList";
import { updateLocalCartLength } from '../Redux/slices/cartLocalLength';
import { updateLocalWishlistLength } from '../Redux/slices/wishlistLocalLength';
import { company_code, base_url, api_website, api_admin } from "../config";

const { confirm } = Modal;
const WishListGuest = () => {
    const api = new API();
    const CurrentDate = new dayjs();
    const [itemCounts, setItemCounts] = useState({}); 
    const [wishList, setWishList] = useState([]);
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem("user")) || "";
    const token = localStorage.getItem("token") || ""
    const [cartUpdate, setCartUpdate] = useState(false);
    const [specData, setSpecData] = useState([]);
    const [scrollSize, setScrollSize] = useState(1000);
    const navigate = useNavigate();
    const params = useParams();
    const loginTrue = localStorage.getItem("login");
  
    const wishlistLocal = JSON.parse(localStorage.getItem('wishList'));
    // console.log("wishlistLocal", wishlistLocal);
  
    const dispatch = useDispatch()
  
    const isDecimal = useSelector((state) => state?.priceDecimalsSlice?.pricedecimal?.[0]);
  
    // console.log("user", user?.company_code);
  
  
    useEffect(() => {    
      // getSingleProduct();
      wishlistLocal.map((item) => {
        getSingleProduct(item?.product_id, item?.product_spec);
      })
    }, []);
  
    const getSingleProduct = (Id, specId) => {
      api.getSingleProduct(Id).then((res) => {  
        
        const filteredSpec = res?.data?.data?.productspec?.filter((spec) => spec._id === specId);
        
        setSpecData((prevSpecData) => [
          ...prevSpecData,
          ...filteredSpec.flat()
        ]);         
      });
    };
  
    // console.log("specData", specData);
  
    const data4Checkout = {
      buyer_id: user?._id,
    };
  
    useEffect(() => {
      getAllWishList();
    }, []);
  
    const getAllWishList = () => {
      api.getWishList(user?._id).then((res) => {
        setWishList(res?.data?.data || []);
        dispatch(wiseListSuccess(res?.data));
      });
    };
  
    useEffect(() => {
      const handleResize = () => {
        // Adjust scroll size based on screen width
        const newScrollSize = getNewScrollSize();
        setScrollSize(newScrollSize);
      };
  
      // Initial adjustment
      handleResize();
  
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Cleanup on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    useEffect(() => {
      getAllCartss();
    }, []);
    const getAllCartss = () => {
      if (user) {
        api.getcart(data4Checkout).then((res) => {
          // console.log("res--->", res);
          if (res.response?.data?.status === 500) {
            message.error(res?.response?.data?.message);
            // navigate("/delivery-address");
            setCart({
              cart_item: [],
            });
          } else {
            setCart(res?.data?.data?.cart_item);
            setLoading(false);
            dispatch(cartSuccess(res?.data));
          }
        });
      }
    };
  
    const getInitialScrollSize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1024) return 1000;
      if (screenWidth >= 768) return 500;
      if (screenWidth >= 540) return 400;
      if (screenWidth >= 425) return 300;
      if (screenWidth >= 385) return 200;
      return 200; // Default for screen width <= 320
    };
  
    const getNewScrollSize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1024) return 1000;
      if (screenWidth >= 768) return 500;
      if (screenWidth >= 540) return 400;
      if (screenWidth >= 425) return 300;
      if (screenWidth >= 385) return 200;
      return 200; // Default for screen width <= 320
    };
  
    const deleteItem = (specId) => {
      // Remove from specData
      setSpecData((prevSpecData) => prevSpecData.filter((spec) => spec._id !== specId));
    
      // Remove from localStorage
      let storedList = JSON.parse(localStorage.getItem('wishList')) || [];
      storedList = storedList.filter((item) => item.product_spec !== specId);
      localStorage.setItem('wishList', JSON.stringify(storedList));
      dispatch(updateLocalWishlistLength(storedList.length));
      if(storedList.length <= 0) {
        window.location.reload();
      };
    };
  
  
    const showconfirm = (id) => {
      confirm({
        title: "Are you sure remove this item from wishlist?",
        content: "Click OK to remove",
        onOk() {
          deleteItem(id);
        },
        onCancel() { },
      });
    }
  
    const handleAddToCart = (itemId, item) => {
      setLoading(true);
      setItemCounts((prevCounts) => ({
        ...prevCounts,
        [itemId]: (prevCounts[itemId] || 0) + 1, // Increment count for the item
      }));
  
      // Call the addToCart function here
      addToCart(item);
    };
  
    const addToCart = (item) => {
      // console.log("item", item);
      // setAddToCartLoading(true);

      const combinedData = {
          qty: item?.qty ? item?.qty : 1,
          product_id: item?.product_id?._id,
          product_spec: item?.product_spec?._id ? item?.product_spec?._id : item?._id,
          company_code: company_code,
        // company_code: user?.company_code,
      };

      // console.log("combinedData", combinedData);
  
      if (!loginTrue) {
        // setAddToCartLoading(false);

        let storedList = JSON.parse(localStorage.getItem('cartLocal')) || [];

          // Check if a matching entry exists
          const existingIndex = storedList.findIndex(
            item => 
              item.product_id === combinedData.product_id &&
              item.product_spec === combinedData.product_spec
          );

          if (existingIndex !== -1) {
            // Update the quantity of the existing item
            storedList[existingIndex].qty += combinedData.qty;
          } else {
            // Add the new combinedData object to the list
            storedList.push(combinedData);
          }

          // Store the updated list back to local storage
          localStorage.setItem('cartLocal', JSON.stringify(storedList));

          dispatch(updateLocalCartLength(storedList.length));
          message.success("Added to Cart Successfully");
          setLoading(false);
      } else {
        // const combinedData = {
        //   buyer_id: item?.buyer_id,
        //   qty: item?.qty,
        //   product_id: item?.product_id?._id,
        //   product_spec: item?.product_spec?._id,
        //   company_code: company_code,
        //   // company_code: user?.company_code,
        // };

        const combinedDataWithUser = {
          ...combinedData,
          buyer_id: user?._id,
        };
  
        api.addCart(combinedDataWithUser).then((res) => {
          if (res?.status == 200) {
            dispatch(cartStart);
            api.getcart().then((res) => {
              // console.log("res--->", res);
              dispatch(cartSuccess(res?.data));
            });
            message.success(res?.data?.message);
            getAllCartss(); 
            // deleteItem();
            // setAddToCartLoading(false);
          } else {
            dispatch(cartFailure);
            message.error(res?.response?.data?.message);
            setLoading(false);
            if (res?.response?.data?.message == "Please Add Delivery Address") {
              navigate("/add-address");
            }
            // setAddToCartLoading(false);
          }
        });
      }
    };

    const data = [];

    specData?.map((item) => {
        // console.log("item-->", item);
        // console.log("item-->", item);
        // console.log("item?.discount_id?.discount_id?.discounttype-->", item?.discount_id?.discount_id?.discounttype);
        // console.log("item?.discount_id-->", item?.discount_id);
        // console.log("dayjs(item?.discount_id != null ? item?.discount_id?.discount_id?.discount_valid_to : null)", dayjs(item?.discount_id != null ? item?.discount_id?.discount_id?.discount_valid_to : null).isAfter(dayjs(CurrentDate)));
        // console.log("isDecimal-->", isDecimal);
      data.push({
        thumb: item?.product_images?.[0]?.image_path,
        title: item?.product_id?.product_name,
        price: (item?.show_mrp == true && item?.show_sp == false) ? 
                (
                  dayjs(item?.discount_id != null ? item?.discount_id?.discount_id?.discount_valid_to : null).isAfter(dayjs(CurrentDate))
                    ?  (item?.discount_id?.discount_id?.discounttype == "Percentage" ?
                    (
                      (isDecimal?.isDecimal === false 
                        ? Math.round( 
                            Number(item?.mrp - (item?.mrp * item?.discount_id?.discount_id?.discount / 100)) +
                              Number((item?.mrp - (item?.mrp * item?.discount_id?.discount_id?.discount / 100)) * 
                              (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ) 
                        : ( Number(item?.mrp - (item?.mrp * item?.discount_id?.discount_id?.discount / 100)) +
                        Number((item?.mrp - (item?.mrp * item?.discount_id?.discount_id?.discount / 100)) * 
                        (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ).toFixed(isDecimal?.Decimal_points))
                    ) 
                    : item?.discount_id?.discount_id?.discounttype == "Price" ?
                    (isDecimal?.isDecimal === false 
                            ? Math.round( 
                                Number(item?.mrp - item?.discount_id?.discount_id?.discount) +
                                  Number((item?.mrp - item?.discount_id?.discount_id?.discount) * 
                                  (item?.product_id?.tax_percentage?.tax_percentage / 100))
                            ) 
                            : ( Number(item?.mrp - item?.discount_id?.discount_id?.discount) +
                            Number((item?.mrp - item?.discount_id?.discount_id?.discount) * 
                            (item?.product_id?.tax_percentage?.tax_percentage / 100))
                            ).toFixed(isDecimal?.Decimal_points)) 
                      : (isDecimal?.isDecimal === false 
                        ? Math.round(
                            item?.mrp + 
                            (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ) 
                        : (item?.mrp + 
                            (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ).toFixed(isDecimal?.Decimal_points))
                    )  
                    : (isDecimal?.isDecimal === false 
                            ? Math.round(
                                item?.mrp + 
                                (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                            ) 
                            : (item?.mrp + 
                                (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                            ).toFixed(isDecimal?.Decimal_points))   
                ) 
                :
                (item?.product_id?.show_common_mrp == true && item?.product_id?.show_common_sp == false) ? 
                (
                    dayjs(item?.discount_id != null ? item?.discount_id?.discount_id?.discount_valid_to : null).isAfter(dayjs(CurrentDate)) 
                    ? (item?.discount_id?.discount_id?.discounttype == "Percentage" ?
                      (
                        (isDecimal?.isDecimal === false 
                          ? Math.round( 
                                Number(item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.discount_id?.discount_id?.discount / 100)) +
                                Number((item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.discount_id?.discount_id?.discount / 100)) * 
                                (item?.product_id?.tax_percentage?.tax_percentage / 100))                                 
                          ) 
                          : (Number(item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.discount_id?.discount_id?.discount / 100)) +
                          Number((item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.discount_id?.discount_id?.discount / 100)) * 
                          (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ).toFixed(isDecimal?.Decimal_points))
                      ) : item?.discount_id?.discount_id?.discounttype == "Price" ?
                      (
                        (isDecimal?.isDecimal === false 
                          ? Math.round( 
                                Number(item?.product_id?.common_mrp - item?.discount_id?.discount_id?.discount ) +
                                Number((item?.product_id?.common_mrp - item?.discount_id?.discount_id?.discount ) * 
                                (item?.product_id?.tax_percentage?.tax_percentage / 100))                                 
                          ) 
                          : (Number(item?.product_id?.common_mrp - item?.discount_id?.discount_id?.discount ) +
                          Number((item?.product_id?.common_mrp - item?.discount_id?.discount_id?.discount ) * 
                          (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ).toFixed(isDecimal?.Decimal_points))
                      ) :
                      (
                        (isDecimal?.isDecimal === false 
                           ? Math.round(
                               item?.product_id?.common_mrp + 
                               (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                           ) 
                           : (item?.product_id?.common_mrp + 
                               (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                           ).toFixed(isDecimal?.Decimal_points))   
                   ) 
                                                 
                    )
                    :
                    (
                         (isDecimal?.isDecimal === false 
                            ? Math.round(
                                item?.product_id?.common_mrp + 
                                (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                            ) 
                            : (item?.product_id?.common_mrp + 
                                (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                            ).toFixed(isDecimal?.Decimal_points))   
                    )                         
                )
                :
                (
                dayjs(item?.discount_id != null ? item?.discount_id?.discount_id?.discount_valid_to : null).isAfter(dayjs(CurrentDate)) 
                ? (
                  item?.discount_id?.discount_id?.discounttype == "Percentage" ? 
                    (
                      isDecimal?.isDecimal === false 
                        ? Math.round(
                            Number((item?.sp || item?.product_id?.common_sp) - ((item?.sp || item?.product_id?.common_sp) * item?.discount_id?.discount_id?.discount / 100)) +
                            Number(((item?.sp || item?.product_id?.common_sp) - ((item?.sp || item?.product_id?.common_sp) * item?.discount_id?.discount_id?.discount / 100)) * 
                            (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ) 
                        : ((item?.sp || item?.product_id?.common_sp) - 
                            (Number((item?.sp || item?.product_id?.common_sp) * (item?.discount_id?.discount_id?.discount / 100))) * 
                            (item?.product_id?.tax_percentage?.tax_percentage / 100)
                        ).toFixed(isDecimal?.Decimal_points)
                    )
                    : item?.discount_id?.discount_id?.discounttype == "Price" ?
                    (isDecimal?.isDecimal === false 
                        ? Math.round(
                            Number((item?.sp || item?.product_id?.common_sp) - item?.discount_id?.discount_id?.discount ) +
                            Number(((item?.sp || item?.product_id?.common_sp) - item?.discount_id?.discount_id?.discount ) * 
                            (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ) 
                        : (Number((item?.sp || item?.product_id?.common_sp) - item?.discount_id?.discount_id?.discount ) +
                        Number(((item?.sp || item?.product_id?.common_sp) - item?.discount_id?.discount_id?.discount ) * 
                        (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ).toFixed(isDecimal?.Decimal_points))
                    : ((isDecimal?.isDecimal === false 
                      ? Math.round(
                        (item?.sp || item?.product_id?.common_sp) + 
                          ((item?.sp || item?.product_id?.common_sp) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      ) 
                      : ((item?.sp || item?.product_id?.common_sp) + 
                          ((item?.sp || item?.product_id?.common_sp) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      ).toFixed(isDecimal?.Decimal_points))                    
                    )
                  )                    
                : (isDecimal?.isDecimal === false 
                        ? Math.round(
                            (item?.sp || item?.product_id?.common_sp) + 
                            ((item?.sp || item?.product_id?.common_sp) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ) 
                        : ((item?.sp || item?.product_id?.common_sp) + 
                            ((item?.sp || item?.product_id?.common_sp) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ).toFixed(isDecimal?.Decimal_points))                    
                
              ),

        item,
        remove: item,
      });
    });

    const columns = [
        {
          title: "THUMBNAIL",
          dataIndex: "thumb",
          key: "thumb",
          // fixed: "left",
          render: (value, records) => {            
            return <img width={50} src={api.rootUrl1 + value} />;
          },
        },
        {
          title: "TITLE",
          dataIndex: "title",
          key: "title",
          // fixed: "left",
        },
        {
          title: "SPECIFICATION",
          dataIndex: "item",
          key: "item",
          render: (value) => {
            // console.log("value", value);
            return (
              <>
                {value?.specification?.map((item) => {
                  return (<Tag color="green">{item?.specification_details}</Tag>)
                })}
              </>
            )
          }
          // fixed: "left",
        },
        {
          title: "PRICE",
          dataIndex: "price",
          key: "price",
        },
        {
          title: "ADD TO CART",
          dataIndex: "cart",
          key: "cart",
          render: (item, records) => {
             // Find the matching product in the cart based on the title
        // const matchingCartItem = cart?.find((cartItem) => cartItem?.product_id?.product_name === records?.title);
        const matchingCartItem = Array.isArray(cart) 
      ? cart.find((cartItem) => cartItem?.product_id?.product_name === records?.title) 
      : records?.item;
        // console.log("Matching cart item:", matchingCartItem);
        // console.log("records:", records);
    
        // Extract the quantities for comparison
        const cartQuantity = matchingCartItem ? (matchingCartItem?.product_spec?.quantity ? matchingCartItem?.product_spec?.quantity : matchingCartItem?.product_id?.common_stock) :
        (records?.item?.product_spec?.quantity ? records?.item?.product_spec?.quantity : records?.item?.product_id?.common_stock);
        const cartAvailableQuantity = matchingCartItem?.qty;
        const currentCount = itemCounts[records?.item?._id] || 0; // Get the current count, default to 0
    
            // console.log("Current count:", currentCount);
            // console.log("Cart quantity:", cartQuantity);
            // console.log("cartAvailableQuantity:", cartAvailableQuantity);
            // console.log("records?.item?.product_spec?.quantity:", records?.item?.product_spec?.quantity);
            // console.log("records?.item?.product_id?.common_stock", records?.item?.product_id?.common_stock);
    
            // Disable the button if current count reaches cart quantity
            // const isButtonDisabled = cartAvailableQuantity == cartQuantity || !(records?.item?.product_id?.common_stock ? Number(records?.item?.product_id?.common_stock) > 0 : records?.item?.product_spec?.quantity > 0);
            const isButtonDisabled = !( records?.item?.quantity  ?  records?.item?.quantity > 0 :  Number(records?.item?.product_id?.common_stock) > 0 );
            // console.log("isButtonDisabled:", isButtonDisabled);
            return <Button 
            // onClick={() => addToCart(records?.item)} 
            onClick={() => handleAddToCart(records?.item?._id, records?.item)} 
            loading={loading}
            icon={<ShoppingCartOutlined />}
            // disabled={!(records?.item?.product_spec?.quantity > 0)}
            disabled={isButtonDisabled}
            >Add to Cart</Button>;
          },
        },
        {
          title: "REMOVE",
          dataIndex: "remove",
          key: "remove",
          render: (action) => {
            // console.log("action", action)
            return <DeleteOutlined onClick={() => showconfirm(action?._id)} />;
          },
        },
      ];

  return (
    <div className="cart_section">
    <div className="cart_left">
      <div className="cart_list" id="style-10">
        <Table
          columns={columns}
          dataSource={data}
          pagination={true}
          responsive={true}
          scroll={{
            x: scrollSize,
          }}
        />
      </div>
    </div>
  </div>
  )
}

export default WishListGuest