import React, { useEffect, useState } from "react";
import {
  Col,
  Divider,
  Image,
  Row,
  Timeline,
  Breadcrumb,
  message,
  Tag,
  Button,
  Modal,
  Form,
  Input,
  Rate,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import styles from "../ApiService/Data";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import crud_service from "../ApiService/CrudService";
import API from "../ApiService/ApiService";
import styled from "styled-components";
import { addReviewStart, addReviewSuccess, addReviewFail } from "../Redux/slices/review";
import { useDispatch, useSelector } from "react-redux";


const OrderDetail = () => {
  const crud = new crud_service();
  const api = new API()
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState([]);
  const [getstatus, setGetstatus] = useState([]);
  const dispatch = useDispatch()
  const params = useParams();
  const allowedLabels = {
    "OUT FOR PICKUP": "Ready to Dispatch",
    "PICKED UP": "Picked Up",
    "SHIPPED": "Shipped",
    "OUT FOR DELIVERY": "Out for Delivery",
    "DELIVERED": "Delivered"
  };
  // console.log("paramsparamsparams", params);
  function status(val) {
    if (val === "Pending") return "Ordered";
    else return val;
  }

  const [form] = Form.useForm();

  const isDecimal = useSelector((state) => state?.priceDecimalsSlice?.pricedecimal?.[0]);
  console.log("isDecimal==>", isDecimal);

  const handleNavigate = () => {
    navigate("/my-order");
  };

  useEffect(() => {
    getSignleOrder();
  }, []);


  const getSignleOrder = () => {
    api.getSignleOrder(params?.id).then((res) => {
      // console.log("res--->", res);
      setData(res?.data?.data);
    });
  };

  // console.log("data", data);

  const addReview = async (values) => {
    await crud.create(
      dispatch,
      [addReviewStart, addReviewSuccess, addReviewFail],
      "review",
      values,
      (err, res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message)
          setOpen(false)
        } else {
          message.error("Already Review Submited Successfully")
        }
      }
    );

  };

  const getRating = () => {
    // api.getCommonApiRating().then((res) => {
    //   setRating(res?.data?.data);
    // });
  };

  const handleCancel = () => { setOpen(false) }
  const handleOk = () => { setOpen(false) }

  const handleAddReview = (index) => {
    setOpen(true)
    form.setFieldsValue({
      buyer_id: data?.order?.buyer_id?._id,
      product_id: data?.order?.products[index]?.product_id?._id,
      product_spec: data?.order?.products[index]?.productspec_id,
    })
  }

  return (
    <>
      <Section>
        <Wrapper>
          <BreadcrumbStyle onClick={handleNavigate}>
            <ArrowLeftOutlined
              style={{ marginRight: "5px", position: "relative", top: "3px" }}
            />
            My Order
          </BreadcrumbStyle>
          <br />
          <Title>Order Detail</Title>
          <h5>
            Order No:
            <strong>#{data?.order?.order_no}</strong>
          </h5>
          <Align>
            <Left>
              <Row
                className="Title"
                // gutter={[8, 8]}
                align="middle"
              // justify="center"
              >
                <Col lg={8} md={8} sm={12} xs={12}>
                  <h5>Items</h5>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4}>
                  <h5>Price</h5>
                </Col>
                <Col lg={4} md={4} sm={0} xs={0}>
                  <h5>Quantity</h5>
                </Col>
                <Col lg={0} md={0} sm={4} xs={4}>
                  <h5>Qty</h5>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4}>
                  <h5>Total Price</h5>
                </Col>
                <Col lg={4} md={4} sm={0} xs={0}>
                  <h5>Action</h5>
                </Col>
              </Row>
              {data?.order?.products?.map((product, index) => (
                // console.log("products", product),
                <div className="Order_Product">
                  <OrderCard key={index} className="Order">
                    <Row
                    // gutter={[8, 8]}
                    // justify="space-between"
                    // align="middle"
                    >
                      <Col lg={8} md={8} sm={12} xs={12}>
                        <Row>
                          <Col lg={8} md={8} sm={8} xs={24}>
                            <ProductImage>
                              <div className="Product_Image">
                                <Image
                                  preview={false}
                                  src={
                                    api.rootUrl1 +
                                    (product?.productspec_id?.product_images[0]?.image_path || "")
                                  }
                                  style={{
                                    border: "1px solid #f2f2f2",
                                    borderRadius: "5px",
                                  }}
                                />
                              </div>
                            </ProductImage>
                          </Col>
                          <Col lg={16} md={6} sm={8} xs={24}>
                            <ProductDetails>
                              <strong style={{ display: "block" }}>{product?.product_name}</strong>
                              {/* <p>{product?.sku}</p> */}
                              {console.log("product", product?.productspec_id?.discount_id?.discount_id?.discount)}
                              {product?.productspec_id?.discount_id?.discount_id?.discount > 0 ? (
                                product?.productspec_id?.discount_id?.discount_id?.discounttype == "Percentage" ?
                                (
                                <div className="Tax" style={{ color: "green" }}>
                                  {product?.productspec_id?.discount_id?.discount_id?.discount > 0 ? " (" + "Discount" + "  " + product?.productspec_id?.discount_id?.discount_id?.discount + "%" + " , Tax" + "  " + product?.tax_percentage + "%" + " Inclusive" + ")" : ""}
                                </div>
                                ) : (
                                  <div className="Tax" style={{ color: "green" }}>
                                    {product?.productspec_id?.discount_id?.discount_id?.discount > 0 ? " (" + "Discount" +  `${styles?.currency}` + product?.productspec_id?.discount_id?.discount_id?.discount + " , Tax" + "  " + product?.tax_percentage + "%" + " Inclusive" + ")" : ""}
                                </div>
                                )
                              ) : (
                                <div className="Tax" style={{ color: "green" }}>
                                  {product?.tax_percentage > 0
                                    ? "(" + "Tax " + product?.tax_percentage + "%" + " Inclusive" + ")"
                                    : ""}
                                </div>
                              )}
                              {product?.productspec_id?.specification?.map((e) => (
                                <Tag color="green" style={{ marginTop: "10px" }}>{e?.specification_details}</Tag>
                              ))}
                              {/* {<p style={{marginTop: "10px", color: "green"}}>Tax - {styles?.currency}&nbsp;{product?.tax}</p>}                               */}
                              {/* {product?.specification.map((e) => (
                            <React.Fragment key={e?._id}>
                              <Tag color="magenta">{e?.text}</Tag>
                            </React.Fragment>
                          ))} */}
                            </ProductDetails>
                          </Col>
                        </Row>
                      </Col>
                                {console.log("product", product)}
                      <Col lg={4} md={4} sm={4} xs={4}>
                      {(product?.productspec_id?.show_mrp == true && product?.productspec_id?.show_sp == false) ?  (
                          <p> {
                            product?.productspec_id?.discount_id?.discount_id?.discounttype == "Percentage" ?
                            (
                              (isDecimal?.isDecimal === false 
                                ? `₹ ${Math.round(
                                    product?.productspec_id?.mrp - 
                                    (product?.productspec_id?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.productspec_id?.mrp - (product?.productspec_id?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  )}`
                                : `₹ ${(product?.productspec_id?.mrp - 
                                    (product?.productspec_id?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.productspec_id?.mrp - (product?.productspec_id?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  ).toFixed(isDecimal?.Decimal_points)}`
                                )
                            ) : product?.productspec_id?.discount_id?.discount_id?.discounttype == "Price" ?
                            (
                              (isDecimal?.isDecimal === false 
                                ? `₹ ${Math.round(
                                    (product?.productspec_id?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                    (product?.productspec_id?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) * 
                                    (product?.tax_percentage / 100)
                                  )}`
                                : `₹ ${((product?.productspec_id?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                    (product?.productspec_id?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) * 
                                    (product?.tax_percentage / 100)
                                  ).toFixed(isDecimal?.Decimal_points)}`
                                )
                            ) :
                            (isDecimal?.isDecimal === false 
                              ? `₹ ${Math.round(
                                  product?.productspec_id?.mrp +
                                  ((product?.productspec_id?.mrp) * (product?.tax_percentage / 100))
                                )}`
                              : `₹ ${(product?.productspec_id?.mrp +
                                  ((product?.productspec_id?.mrp) * (product?.tax_percentage / 100))
                                ).toFixed(isDecimal?.Decimal_points)}`
                              )
                          }
                        </p>
                        )
                        :
                      (product?.product_id?.show_common_mrp == true && product?.product_id?.show_common_sp == false) ?
                        (
                          <p>
                          {/* {
                            console.log("sp", (product?.sp - (product?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 ) * ((product?.tax_percentage)/100))
                          } */}
                          {/* {(product?.sp - ((product?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) + (product?.sp - (product?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * ((product?.tax_percentage) / 100))?.toLocaleString("en-IN", {
                            maximumFractionDigits: 2,
                            style: "currency",
                            currency: "INR",
                          })} */}

                            {product?.productspec_id?.discount_id?.discount_id?.discounttype == "Percentage" ?
                            (
                              (isDecimal?.isDecimal === false 
                                ? `₹ ${Math.round(
                                    product?.mrp - 
                                    (product?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.mrp - (product?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  )}`
                                : `₹ ${(product?.mrp - 
                                    (product?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.mrp - (product?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  ).toFixed(isDecimal?.Decimal_points)}`
                              )
                            ) : product?.productspec_id?.discount_id?.discount_id?.discounttype == "Price" ?
                            (
                              (isDecimal?.isDecimal === false 
                                ? `₹ ${Math.round(
                                    (product?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                    ((product?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) * (product?.tax_percentage / 100))
                                  )}`
                                : `₹ ${((product?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                    ((product?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) * (product?.tax_percentage / 100))
                                  ).toFixed(isDecimal?.Decimal_points)}`
                              )
                            )
                            :
                            (isDecimal?.isDecimal === false 
                              ? `₹ ${Math.round(
                                  product?.mrp +
                                  ((product?.mrp) * (product?.tax_percentage / 100))
                                )}`
                              : `₹ ${(product?.mrp +
                                  ((product?.mrp) * (product?.tax_percentage / 100))
                                ).toFixed(isDecimal?.Decimal_points)}`
                            )
                            }
                        </p>
                        )
                        :
                        (
                          <p>
                          {/* {
                            console.log("sp", (product?.sp - (product?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 ) * ((product?.tax_percentage)/100))
                          } */}
                          {/* {(product?.sp - ((product?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) + (product?.sp - (product?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * ((product?.tax_percentage) / 100))?.toLocaleString("en-IN", {
                            maximumFractionDigits: 2,
                            style: "currency",
                            currency: "INR",
                          })} */}
                          {product?.productspec_id?.discount_id?.discount_id?.discounttype == "Percentage" ?
                            (
                              (isDecimal?.isDecimal === false 
                                ? `₹ ${Math.round(
                                    product?.productspec_id?.sp - 
                                    (product?.productspec_id?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.productspec_id?.sp - (product?.productspec_id?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  )}`
                                : `₹ ${(product?.productspec_id?.sp - 
                                    (product?.productspec_id?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.productspec_id?.sp - (product?.productspec_id?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  ).toFixed(isDecimal?.Decimal_points)}`
                              )
                          ) :
                          product?.productspec_id?.discount_id?.discount_id?.discounttype == "Price" ?
                          (
                            (isDecimal?.isDecimal === false 
                              ? `₹ ${Math.round(
                                  (product?.productspec_id?.sp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                  ((product?.productspec_id?.sp - product?.productspec_id?.discount_id?.discount_id?.discount) * 
                                  (product?.tax_percentage / 100))
                                )}`
                              : `₹ ${((product?.productspec_id?.sp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                  ((product?.productspec_id?.sp - product?.productspec_id?.discount_id?.discount_id?.discount) * 
                                  (product?.tax_percentage / 100))
                                ).toFixed(isDecimal?.Decimal_points)}`
                            )
                          )
                          :
                            (isDecimal?.isDecimal === false 
                              ? `₹ ${Math.round(
                                  product?.productspec_id?.sp +
                                  ((product?.productspec_id?.sp) * (product?.tax_percentage / 100))
                                )}`
                              : `₹ ${(product?.productspec_id?.sp +
                                  ((product?.productspec_id?.sp) * (product?.tax_percentage / 100))
                                ).toFixed(isDecimal?.Decimal_points)}`
                            )
                          }
                        </p>
                        )
                      }
                        
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={4}>
                        <p>{product?.qty}</p>
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={4}>
                        {(product?.productspec_id?.show_mrp == true && product?.productspec_id?.show_sp == false) ?  (
                          <p> 
                            {
                            product?.productspec_id?.discount_id?.discount_id?.discounttype == "Percentage" ?
                            (
                              (isDecimal?.isDecimal === false 
                                ? `₹ ${Math.round(
                                    product?.productspec_id?.mrp - 
                                    (product?.productspec_id?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.productspec_id?.mrp - (product?.productspec_id?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  ) * product?.qty}`
                                : `₹ ${(product?.productspec_id?.mrp - 
                                    (product?.productspec_id?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.productspec_id?.mrp - (product?.productspec_id?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  ).toFixed(isDecimal?.Decimal_points) * product?.qty}`
                                )
                            ) : product?.productspec_id?.discount_id?.discount_id?.discounttype == "Price" ?
                            (
                              (isDecimal?.isDecimal === false 
                                ? `₹ ${Math.round(
                                    (product?.productspec_id?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                    (product?.productspec_id?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) * 
                                    (product?.tax_percentage / 100)
                                  ) * product?.qty}`
                                : `₹ ${((product?.productspec_id?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                    (product?.productspec_id?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) * 
                                    (product?.tax_percentage / 100)
                                  ).toFixed(isDecimal?.Decimal_points) * product?.qty}`
                                )
                            ) :
                            (isDecimal?.isDecimal === false 
                              ? `₹ ${Math.round(
                                  product?.productspec_id?.mrp +
                                  ((product?.productspec_id?.mrp) * (product?.tax_percentage / 100))
                                ) * product?.qty}`
                              : `₹ ${(product?.productspec_id?.mrp +
                                  ((product?.productspec_id?.mrp) * (product?.tax_percentage / 100))
                                ).toFixed(isDecimal?.Decimal_points) * product?.qty}`
                              )
                          }
                        </p>
                        )
                        :
                        (product?.product_id?.show_common_mrp == true && product?.product_id?.show_common_sp == false) ? 
                        (
                          <p>
                          {product?.productspec_id?.discount_id?.discount_id?.discounttype == "Percentage" ?
                            (
                              (isDecimal?.isDecimal === false 
                                ? `₹ ${Math.round(
                                    product?.mrp - 
                                    (product?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.mrp - (product?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  ) * product?.qty}`
                                : `₹ ${(product?.mrp - 
                                    (product?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.mrp - (product?.mrp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  ).toFixed(isDecimal?.Decimal_points) * product?.qty}`
                              )
                            ) : product?.productspec_id?.discount_id?.discount_id?.discounttype == "Price" ?
                            (
                              (isDecimal?.isDecimal === false 
                                ? `₹ ${Math.round(
                                    (product?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                    ((product?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) * (product?.tax_percentage / 100))
                                  ) * product?.qty}`
                                : `₹ ${((product?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                    ((product?.mrp - product?.productspec_id?.discount_id?.discount_id?.discount) * (product?.tax_percentage / 100))
                                  ).toFixed(isDecimal?.Decimal_points) * product?.qty}`
                              )
                            )
                            :
                            (isDecimal?.isDecimal === false 
                              ? `₹ ${Math.round(
                                  product?.mrp +
                                  ((product?.mrp) * (product?.tax_percentage / 100))
                                ) * product?.qty}`
                              : `₹ ${(product?.mrp +
                                  ((product?.mrp) * (product?.tax_percentage / 100))
                                ).toFixed(isDecimal?.Decimal_points) * product?.qty}`
                            )
                            }
                        </p>
                        )
                        :
                        (
                          <p>
                          {product?.productspec_id?.discount_id?.discount_id?.discounttype == "Percentage" ?
                            (
                              (isDecimal?.isDecimal === false 
                                ? `₹ ${Math.round(
                                    product?.productspec_id?.sp - 
                                    (product?.productspec_id?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.productspec_id?.sp - (product?.productspec_id?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  ) * product?.qty}`
                                : `₹ ${(product?.productspec_id?.sp - 
                                    (product?.productspec_id?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100 +
                                    (product?.productspec_id?.sp - (product?.productspec_id?.sp * product?.productspec_id?.discount_id?.discount_id?.discount) / 100) * 
                                    (product?.tax_percentage / 100)
                                  ).toFixed(isDecimal?.Decimal_points) * product?.qty}`
                              )
                          ) :
                          product?.productspec_id?.discount_id?.discount_id?.discounttype == "Price" ?
                          (
                            (isDecimal?.isDecimal === false 
                              ? `₹ ${Math.round(
                                  (product?.productspec_id?.sp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                  ((product?.productspec_id?.sp - product?.productspec_id?.discount_id?.discount_id?.discount) * 
                                  (product?.tax_percentage / 100))
                                ) * product?.qty}`
                              : `₹ ${((product?.productspec_id?.sp - product?.productspec_id?.discount_id?.discount_id?.discount) +
                                  ((product?.productspec_id?.sp - product?.productspec_id?.discount_id?.discount_id?.discount) * 
                                  (product?.tax_percentage / 100))
                                ).toFixed(isDecimal?.Decimal_points) * product?.qty}`
                            )
                          )
                          :
                            (isDecimal?.isDecimal === false 
                              ? `₹ ${Math.round(
                                  product?.productspec_id?.sp +
                                  ((product?.productspec_id?.sp) * (product?.tax_percentage / 100))
                                ) * product?.qty}`
                              : `₹ ${(product?.productspec_id?.sp +
                                  ((product?.productspec_id?.sp) * (product?.tax_percentage / 100))
                                ).toFixed(isDecimal?.Decimal_points) * product?.qty}`
                            )
                          }
                        </p>
                        )
                        }
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={4}>
                        <Button
                          disabled={data?.order?.status !== "Delivered"}
                          className="add_review_btn"
                          onClick={() => handleAddReview(index)}>Add Review</Button>
                      </Col>
                    </Row>
                  </OrderCard>
                </div>
              ))}
              <div className="Total_Price">
                <h6 >
                  Delivery Charge: {styles?.currency}
                  {isDecimal?.isDecimal === false 
  ? Math.round(Number(data?.order?.delivery_charge))
  : Number(data?.order?.delivery_charge).toFixed(isDecimal?.Decimal_point)
}
                </h6>
                <h4>
                  Order Amount: {styles?.currency}
                  {/* {Number((data?.order?.total)).toFixed(2)} */}
                  {isDecimal?.isDecimal === false 
  ? Math.round(Number(data?.order?.total))
  : Number(data?.order?.total).toFixed(isDecimal?.Decimal_point)
}
                </h4>
              </div>
            </Left>
            <Right>
              <CustomerInfo>
                <h3>Customer Information</h3>
                <p>
                  <strong>Name:</strong>{" "}
                  {data?.order?.delivery_address?.firstname}
                </p>
                {/* <p>
                  <strong>Email:</strong> {data?.order?.delivery_address?.email}
                </p> */}
                <p>
                  <strong>Phone:</strong>{" "}
                  {data?.order?.delivery_address?.mobile_no}
                </p>
                <br />
                <h3>Shipping Address</h3>
                <p>
                  {`${data?.order?.delivery_address?.address_line_1},   ${data?.order?.delivery_address?.address_line_2}, ${data?.order?.delivery_address?.city}, ${data?.order?.delivery_address?.state}, Pincode - ${data?.order?.delivery_address?.pincode}`}
                </p>
              </CustomerInfo>
              <br />
              <OrderStatus>
                <h3>Order Status</h3>
                <div className="Tracking">
                  {data?.tracking?.tracking_data?.track_status > 0 ? (
                    <Timeline mode="left" style={{ padding: "15px" }}>
                      {data?.tracking?.tracking_data?.shipment_track_activities
                        ?.sort((a, b) => {
                          return new Date(a.date) - new Date(b.date);
                        })?.map((e) => (
                          <Timeline.Item
                            label={allowedLabels.hasOwnProperty(status(e?.["sr-status-label"])) ? allowedLabels[status(e?.["sr-status-label"])] : ""}


                            dot={allowedLabels.hasOwnProperty(status(e?.["sr-status-label"])) ?
                              e?.["sr-status-label"] == "CANCEL" ? (
                                <CloseCircleOutlined />
                              ) : (
                                <CheckCircleOutlined />
                              ) : ""
                            }
                            color={e?.["sr-status-label"] == "CANCEL" ? "red" : "green"}
                            style={{ marginLeft: "-20%" }}
                          >
                            {allowedLabels.hasOwnProperty(status(e?.["sr-status-label"])) ? new Date(e?.date)?.toLocaleString({
                              dateStyle: "medium",
                              timeStyle: "medium",
                            }) : ""}
                          </Timeline.Item>
                        ))}
                    </Timeline>
                  ) : <Timeline mode="left">
                    {data?.orderlogs?.map((e) => (
                      // console.log("e==========>", e),
                      <Timeline.Item
                        label={status(e?.order_status)}
                        dot={
                          e?.order_status == "Cancel" ? (
                            <CloseCircleOutlined />
                          ) : (
                            <CheckCircleOutlined />
                          )
                        }
                        color={e?.order_status == "Cancel" ? "red" : "green"}
                        style={{ marginLeft: "-20%" }}
                      >
                        {new Date(e?.updatedAt)?.toLocaleString("en-IN", {
                          dateStyle: "medium",
                          timeStyle: "medium",
                        })}
                      </Timeline.Item>
                    ))}
                  </Timeline>}
                </div>
              </OrderStatus>
            </Right>
          </Align>
        </Wrapper>
      </Section>
      <Modal title="Add Review" open={open} footer={null} onCancel={handleCancel} onOk={handleOk}>
        <Form form={form} onFinish={addReview}>
          <Form.Item name="buyer_id" hidden></Form.Item>
          <Form.Item name="product_id" hidden></Form.Item>
          <Form.Item name="product_spec" hidden></Form.Item>

          <Form.Item
            label="Rating"
            name="rating"
            rules={[{ required: true, message: "Please select your rating!" }]}
          >
            <Rate name="star" />
          </Form.Item>
          <Form.Item
            label="Review"
            name="review"
            rules={[{ required: true, message: "Please write your review!" }, {
              max: 50,
              message: "Review must below 50 characters"
            }]}
          >
            <Input.TextArea rows={4} maxLength={100} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary" danger >
              Submit Review
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Divider />
    </>
  );
};

export default OrderDetail;

const Section = styled.section`

  margin-top: 130px !important;
  /* border: 1px solid red; */
  margin: 60px 0 0 0;
  width: 100%;
  position: relative;
  display: inline-block;

  @media screen and (max-width: 768px) {
    // .Title {
    //   display: none;
    // }
    .ant-btn {
      padding: 4px 2px;
    }
    // .Order {
    //   height: 220px !important;
    // }
  }
`;

const Title = styled.h1`
  font-size: 30px;
  color: ${styles.color};
  margin: 0 0 25px;
`;

const Wrapper = styled.div`
  max-width: 1200px;
  padding: 0 10px;
  margin: auto;
`;

const Align = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
`;

const Left = styled.div`
  width: 68%;
  display: inline-block;
  border: 1px solid ${styles.light};
  padding: 20px;
  filter: blur();
  @media screen and (max-width: 956px) {
    width: 100%;
    .Order_Product {
      padding: 3px;
      display: flex;
      align-items: center;
    }
  }

  .Total_Price {
    text-align: right;
  }
`;

const CustomerInfo = styled.div`
  h3 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  p {
    font-size: 14px;
    margin-bottom: 8px;
  }
`;

const Right = styled.div`
  width: 30%;
  display: inline-block;
  border: 1px solid ${styles.light};
  padding: 24px;

  @media screen and (max-width: 956px) {
    width: 100%;
    margin: 0 0 50px;
  }
`;

const OrderCard = styled.div`
  width: 100%;
  // height: 130px;
  border: 1px solid #f2f2f2;
  /* padding-top: 3px; */
  padding: 16px;
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    padding: 16px 5px;
  }
  p {
    margin: 0;
  }
  @media screen and (max-width: 575px) {
    .add_review_btn{
      margin-top:15px;
    }
  }
`;

const ProductImage = styled.div`
  text-align: center;

  .Product_Image {
    /* height: 80px; */
    width: 60px;
  }

  @media screen and (max-width: 768px) {
    .Product_Image {
      width: 60px;
    }
  }
`;

const ProductDetails = styled.div`
  /* padding-left: 16px; */
`;

const BreadcrumbStyle = styled(Breadcrumb)`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
`;

const OrderStatus = styled.div`
  h3 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .Tracking {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 16px;
  }
`;
