import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "../ApiService/ApiService";
import {
  Tag,
  Button,
  Divider,
  Popconfirm,
  message,
  Empty,
  Input,
  Spin,
  Table,
  Modal
} from "antd";
import { DeleteOutlined, InfoOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import styles from "../ApiService/Data";
import "../../src/Assets/Css/style.css";
import { Spinner } from "react-bootstrap";
import dayjs from "dayjs";

import { cartSuccess, cartStart, cartFailure } from "../Redux/slices/cart";
import {
  wiseListStart,
  wiseListSuccess,
  wiseListFailure,
} from "../Redux/slices/wishList";
import { company_code, base_url, api_website, api_admin } from "../config";
import WishListGuest from "./WishListGuest";

const { confirm } = Modal
const WishList = () => {
  const api = new API();
  const CurrentDate = new dayjs();
  const [itemCounts, setItemCounts] = useState({}); 
  const [wishList, setWishList] = useState([]);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user")) || "";
  const token = localStorage.getItem("token") || ""
  const [cartUpdate, setCartUpdate] = useState(false);
  const [specData, setSpecData] = useState([]);
  const [scrollSize, setScrollSize] = useState(1000);
  const navigate = useNavigate();
  const params = useParams();
  const loginTrue = localStorage.getItem("login");
  const [itemLoading, setItemLoading] = useState({});
  const wishlistLocal = JSON.parse(localStorage.getItem('wishList'));
  // console.log("wishlistLocal", wishlistLocal);

  const dispatch = useDispatch()

  const isDecimal = useSelector((state) => state?.priceDecimalsSlice?.pricedecimal?.[0]);

  // console.log("user", user?.company_code);


  useEffect(() => {    
    // getSingleProduct();
    wishlistLocal?.map((item) => {
      getSingleProduct(item?.product_id, item?.product_spec);
    })
  }, []);

  const getSingleProduct = (Id, specId) => {
    api.getSingleProduct(Id).then((res) => {  
      
      const filteredSpec = res?.data?.data?.productspec?.filter((spec) => spec._id === specId);
      
      setSpecData((prevSpecData) => [
        ...prevSpecData,
        ...filteredSpec.flat()
      ]);         
    });
  };

  // console.log("specData", specData);

  const data4Checkout = {
    buyer_id: user?._id,
  };

  useEffect(() => {
    getAllWishList();
  }, []);

  const getAllWishList = () => {
    api.getWishList(user?._id).then((res) => {
      setWishList(res?.data?.data || []);
      dispatch(wiseListSuccess(res?.data));
    });
  };

  useEffect(() => {
    const handleResize = () => {
      // Adjust scroll size based on screen width
      const newScrollSize = getNewScrollSize();
      setScrollSize(newScrollSize);
    };

    // Initial adjustment
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getAllCartss();
  }, []);
  const getAllCartss = () => {
    if (user) {
      api.getcart(data4Checkout).then((res) => {
        // console.log("res--->", res);
        if (res.response?.data?.status === 500) {
          message.error(res?.response?.data?.message);
          // navigate("/delivery-address");
          setCart({
            cart_item: [],
          });
        } else {
          setCart(res?.data?.data?.cart_item);
          setLoading(false);
          dispatch(cartSuccess(res?.data));
        }
      });
    }
  };

  const getInitialScrollSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1024) return 1000;
    if (screenWidth >= 768) return 500;
    if (screenWidth >= 540) return 400;
    if (screenWidth >= 425) return 300;
    if (screenWidth >= 385) return 200;
    return 200; // Default for screen width <= 320
  };

  const getNewScrollSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1024) return 1000;
    if (screenWidth >= 768) return 500;
    if (screenWidth >= 540) return 400;
    if (screenWidth >= 425) return 300;
    if (screenWidth >= 385) return 200;
    return 200; // Default for screen width <= 320
  };

  const deleteItem = (id) => {
    api.deleteWishList(id).then((res) => {
      message.success("Removed Successfully");
      getAllWishList()

    });
  };


  const showconfirm = (id) => {
    confirm({
      title: "Are you sure remove this item from wishlist?",
      content: "Click OK to remove",
      onOk() {
        deleteItem(id);
      },
      onCancel() { },
    });
  }

  // const handleAddToCart = (itemId, item) => {
  //   setLoading(true);
  //   setItemCounts((prevCounts) => ({
  //     ...prevCounts,
  //     [itemId]: (prevCounts[itemId] || 0) + 1, // Increment count for the item
  //   }));

  //   // Call the addToCart function here
  //   addToCart(item);
  // };

  // const addToCart = (item) => {
  //   // console.log(item)
  //   // setAddToCartLoading(true);

  //   if (!loginTrue) {
  //     // setAddToCartLoading(false);
  //   } else {
  //     const combinedData = {
  //       buyer_id: item?.buyer_id,
  //       qty: item?.qty,
  //       product_id: item?.product_id?._id,
  //       product_spec: item?.product_spec?._id,
  //       company_code: company_code,
  //       // company_code: user?.company_code,
  //     };
  const handleAddToCart = (itemId, item) => {
    // Set the specific item loading to true
    setItemLoading((prevLoading) => ({
      ...prevLoading,
      [itemId]: true,
    }));
  
    setItemCounts((prevCounts) => ({
      ...prevCounts,
      [itemId]: (prevCounts[itemId] || 0) + 1, // Increment count for the item
    }));
  
    // Call the addToCart function here
    addToCart(item, itemId);
  };
  
  const addToCart = (item, itemId) => {
    if (!loginTrue) {
      // If not logged in, stop the loading for this item
      setItemLoading((prevLoading) => ({
        ...prevLoading,
        [itemId]: false,
      }));
    } else {
      const combinedData = {
        buyer_id: item?.buyer_id,
        qty: item?.qty,
        product_id: item?.product_id?._id,
        product_spec: item?.product_spec?._id,
        company_code: company_code,
      };
      api.addCart(combinedData).then((res) => {
        if (res?.status == 200) {
          dispatch(cartStart);
          api.getcart().then((res) => {
            // console.log("res--->", res);
            dispatch(cartSuccess(res?.data));
          });
          message.success(res?.data?.message);
          getAllCartss(); 
          // deleteItem();
          // setAddToCartLoading(false);
        } else {
          dispatch(cartFailure);
          message.error(res?.response?.data?.message);
          setLoading(false);
          if (res?.response?.data?.message == "Please Add Delivery Address") {
            navigate("/add-address");
          }
          // setAddToCartLoading(false);
        }
      });
      setTimeout(() => {
        // Simulate successful add to cart, stop loading for the specific item
        setItemLoading((prevLoading) => ({
          ...prevLoading,
          [itemId]: false,
        }));
      }, 2000); // Replace with your actual API call
    }
  };

  specData?.map((item) => {
    console.log("item-->", item);
  // data.push({
  //   key: item?._id,
  //   product_id: item?.product_id?._id,
  //   product_name: item?.product_id?.product_name,
  //   product_image: item?.product_id?.product_image,
  //   product_price: item?.product_id?.common_sp,
  //   product_spec: item?._id,
  //   specification: item?.specification,
  //   qty: 1,
  // });
});


  const data = [];
  wishList?.map((item) => {
    console.log("item", item);
    console.log("item?.product_spec?.discount_id", dayjs(item?.product_spec?.discount_id != null ? item?.product_spec?.discount_id?.discount_id?.discount_valid_to : null));
    // console.log("CurrentDate", dayjs(CurrentDate).format("DD-MMM-YYYYhh:mm:ss"));
    // console.log("discount_valid_to", dayjs(item?.product_spec?.discount_id?.discount_id?.discount_valid_to).format("DD-MMM-YYYYhh:mm:ss"));
    // console.log("isTrue", dayjs(item?.product_spec?.discount_id?.discount_id?.discount_valid_to).isAfter(dayjs(CurrentDate)));
    console.log("dayjs(item?.product_spec?.discount_id?.discount_id?.discount_valid_to).isAfter(dayjs(CurrentDate))", dayjs(item?.product_spec?.discount_id != null ? item?.product_spec?.discount_id?.discount_id?.discount_valid_to : null).isAfter(dayjs(CurrentDate)));
  //   console.log("item--->", 
  //     Number(item?.product_spec?.mrp - (item?.product_spec?.mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) +
  //     Number((item?.product_spec?.mrp - (item?.product_spec?.mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * 
  //     (item?.product_id?.tax_percentage?.tax_percentage / 100))
  // );
  
    data.push({
      thumb: item?.product_spec?.product_images[0],
      title: item?.product_id?.product_name,
      // price: dayjs(item?.product_spec?.discount_id?.discount_id?.discount_valid_to).format("DD-MMM-YYYYhh:mm:ss") > dayjs(CurrentDate).format("DD-MMM-YYYYhh:mm:ss") ? 
      // (item?.product_id?.common_sp ? Math.round(item?.product_id?.common_sp - ((Number(item?.product_id?.common_sp * (item?.product_spec?.discount_id?.discount_id?.discount / 100)))) * (item?.product_id?.tax_percentage?.tax_percentage / 100)) : Math.round(item?.product_spec?.sp - ((Number(item?.product_spec?.sp * (item?.product_spec?.discount_id?.discount_id?.discount / 100)))) * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(0) 
      // : (item?.product_id?.common_sp ? Math.round(item?.product_id?.common_sp + (item?.product_id?.common_sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))) : Math.round(item?.product_spec?.sp + (item?.product_spec?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100)))).toFixed(0),
      price: (item?.product_spec?.show_mrp == true && item?.product_spec?.show_sp == false) ? 
              (
                dayjs(item?.product_spec?.discount_id != null ? item?.product_spec?.discount_id?.discount_id?.discount_valid_to : null).isAfter(dayjs(CurrentDate)) 
                  ? (item?.product_spec?.discount_id?.discount_id?.discounttype == "Percentage" ?
                      (
                        isDecimal?.isDecimal === false 
                          ? Math.round( 
                              Number(item?.product_spec?.mrp - (item?.product_spec?.mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) +
                              Number((item?.product_spec?.mrp - (item?.product_spec?.mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * 
                              (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ) 
                          : (
                            Number(item?.product_spec?.mrp - (item?.product_spec?.mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) +
                            Number((item?.product_spec?.mrp - (item?.product_spec?.mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * 
                            (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ).toFixed(isDecimal?.Decimal_points)
                      )
                      : item?.product_spec?.discount_id?.discount_id?.discounttype == "Price" ?
                      (
                        isDecimal?.isDecimal === false 
                          ? Math.round( 
                              Number(item?.product_spec?.mrp - item?.product_spec?.discount_id?.discount_id?.discount ) +
                              Number((item?.product_spec?.mrp - item?.product_spec?.discount_id?.discount_id?.discount ) * 
                              (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ) 
                          : (
                            Number(item?.product_spec?.mrp - item?.product_spec?.discount_id?.discount_id?.discount ) +
                            Number((item?.product_spec?.mrp - item?.product_spec?.discount_id?.discount_id?.discount ) * 
                            (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ).toFixed(isDecimal?.Decimal_points)
                      )
                      :
                      (isDecimal?.isDecimal === false 
                        ? Math.round(
                            item?.product_spec?.mrp + 
                            (item?.product_spec?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ) 
                        : (item?.product_spec?.mrp + 
                            (item?.product_spec?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ).toFixed(isDecimal?.Decimal_points))                        
                    )
                  : 
                  (
                    (isDecimal?.isDecimal === false 
                          ? Math.round(
                              item?.product_spec?.mrp + 
                              (item?.product_spec?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ) 
                          : (item?.product_spec?.mrp + 
                              (item?.product_spec?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ).toFixed(isDecimal?.Decimal_points))                                         
                  )  
              ) 
              :      
              (item?.product_id?.show_common_mrp == true && item?.product_id?.show_common_sp == false) ? 
              (
                dayjs(item?.product_spec?.discount_id != null ? item?.product_spec?.discount_id?.discount_id?.discount_valid_to : null).isAfter(dayjs(CurrentDate)) 
                ? (item?.product_spec?.discount_id?.discount_id?.discounttype == "Percentage" ?
                  (
                    (isDecimal?.isDecimal === false 
                      ? Math.round(
                          Number(item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) +
                          Number((item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * 
                          (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ) 
                      : (                           
                          Number(item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) +
                          Number((item?.product_id?.common_mrp - (item?.product_id?.common_mrp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * 
                          (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ).toFixed(isDecimal?.Decimal_points)) 
                  )
                  : item?.product_spec?.discount_id?.discount_id?.discounttype == "Price" ?                  
                  ((isDecimal?.isDecimal === false 
                        ? Math.round(
                            Number(item?.product_id?.common_mrp - item?.product_spec?.discount_id?.discount_id?.discount ) +
                            Number((item?.product_id?.common_mrp - item?.product_spec?.discount_id?.discount_id?.discount ) * 
                            (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ) 
                        : (                           
                            Number(item?.product_id?.common_mrp - item?.product_spec?.discount_id?.discount_id?.discount ) +
                            Number((item?.product_id?.common_mrp - item?.product_spec?.discount_id?.discount_id?.discount ) * 
                            (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ).toFixed(isDecimal?.Decimal_points))                    
                  ) :
                  (isDecimal?.isDecimal === false 
                    ? 
                    Math.round(
                        item?.product_id?.common_mrp + 
                        (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      ) 
                    : (item?.product_id?.common_mrp + 
                        (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      ).toFixed(isDecimal?.Decimal_points))

                )              
                : (isDecimal?.isDecimal === false 
                        ? 
                        Math.round(
                            item?.product_id?.common_mrp + 
                            (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ) 
                        : (item?.product_id?.common_mrp + 
                            (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ).toFixed(isDecimal?.Decimal_points))  
              )              
              :
              (
                dayjs(item?.product_spec?.discount_id != null ? item?.product_spec?.discount_id?.discount_id?.discount_valid_to : null).isAfter(dayjs(CurrentDate)) 
              ? (item?.product_spec?.discount_id?.discount_id?.discounttype == "Percentage" ?
                  (item?.product_spec?.sp ?  
                    (isDecimal?.isDecimal === false 
                    ? Math.round(
                        Number(item?.product_spec?.sp - (item?.product_spec?.sp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) +
                        Number((item?.product_spec?.sp - (item?.product_spec?.sp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * 
                        (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      ) 
                    : (
                      Number(item?.product_spec?.sp - (item?.product_spec?.sp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) +
                      Number((item?.product_spec?.sp - (item?.product_spec?.sp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * 
                      (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      ).toFixed(isDecimal?.Decimal_points))
                  : (isDecimal?.isDecimal === false 
                      ? Math.round(                          
                          Number(item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) +
                          Number((item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * 
                          (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ) 
                      : (
                        Number(item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) +
                        Number((item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.product_spec?.discount_id?.discount_id?.discount / 100)) * 
                        (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ).toFixed(isDecimal?.Decimal_points))
                  ) : item?.product_spec?.discount_id?.discount_id?.discounttype == "Price" ?
                  (item?.product_spec?.sp ?  
                    (isDecimal?.isDecimal === false 
                    ? Math.round(
                        Number(item?.product_spec?.sp - item?.product_spec?.discount_id?.discount_id?.discount ) +
                        Number((item?.product_spec?.sp - item?.product_spec?.discount_id?.discount_id?.discount ) * 
                        (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      ) 
                    : (
                      Number(item?.product_spec?.sp - item?.product_spec?.discount_id?.discount_id?.discount ) +
                      Number((item?.product_spec?.sp - item?.product_spec?.discount_id?.discount_id?.discount ) * 
                      (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      ).toFixed(isDecimal?.Decimal_points))
                  : (isDecimal?.isDecimal === false 
                      ? Math.round(                          
                          Number(item?.product_id?.common_sp - item?.product_spec?.discount_id?.discount_id?.discount ) +
                          Number((item?.product_id?.common_sp - item?.product_spec?.discount_id?.discount_id?.discount ) * 
                          (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ) 
                      : (
                        Number(item?.product_id?.common_sp - item?.product_spec?.discount_id?.discount_id?.discount ) +
                        Number((item?.product_id?.common_sp - item?.product_spec?.discount_id?.discount_id?.discount ) * 
                        (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ).toFixed(isDecimal?.Decimal_points))
                  )
                  :
                  (item?.product_spec?.sp 
                    ? (isDecimal?.isDecimal === false 
                      ? Math.round(
                          item?.product_spec?.sp + 
                          (item?.product_spec?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ) 
                      : (item?.product_spec?.sp + 
                          (item?.product_spec?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ).toFixed(isDecimal?.Decimal_points))
                    : (isDecimal?.isDecimal === false 
                        ? Math.round(
                            item?.product_id?.common_sp + 
                            (item?.product_id?.common_sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ) 
                        : (item?.product_id?.common_sp + 
                            (item?.product_id?.common_sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                          ).toFixed(isDecimal?.Decimal_points)) 
                  )
                )
              : (item?.product_spec?.sp 
                  ? (isDecimal?.isDecimal === false 
                    ? Math.round(
                        item?.product_spec?.sp + 
                        (item?.product_spec?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      ) 
                    : (item?.product_spec?.sp + 
                        (item?.product_spec?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                      ).toFixed(isDecimal?.Decimal_points))
                  : (isDecimal?.isDecimal === false 
                      ? Math.round(
                          item?.product_id?.common_sp + 
                          (item?.product_id?.common_sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ) 
                      : (item?.product_id?.common_sp + 
                          (item?.product_id?.common_sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                        ).toFixed(isDecimal?.Decimal_points)) 
                )
              )            
              ,

      item,
      remove: item?._id,
    });
  });

  const columns = [
    {
      title: "THUMBNAIL",
      dataIndex: "thumb",
      key: "thumb",
      // fixed: "left",
      render: (value, records) => {
        // console.log(value)
        return <img width={50} src={api.rootUrl1 + value?.image_path} />;
      },
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      // fixed: "left",
    },
    {
      title: "SPECIFICATION",
      dataIndex: "item",
      key: "item",
      render: (value) => {
        return (
          <>
            {value?.product_spec?.specification?.map((item) => {
              return (<Tag color="green">{item?.specification_details}</Tag>)
            })}
          </>
        )
      }
      // fixed: "left",
    },
    {
      title: "PRICE",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "ADD TO CART",
      dataIndex: "cart",
      key: "cart",
      render: (item, records) => {
         // Find the matching product in the cart based on the title
    // const matchingCartItem = cart?.find((cartItem) => cartItem?.product_id?.product_name === records?.title);
    const matchingCartItem = Array.isArray(cart) 
  ? cart.find((cartItem) => cartItem?.product_id?.product_name === records?.title) 
  : records?.item;
    // console.log("Matching cart item:", matchingCartItem);
    // console.log("records:", records);

    // Extract the quantities for comparison
    const cartQuantity = matchingCartItem ? (matchingCartItem?.product_id?.common_stock ? matchingCartItem?.product_id?.common_stock : matchingCartItem?.product_spec?.quantity) :
    (records?.item?.product_id?.common_stock ? records?.item?.product_id?.common_stock : records?.item?.product_spec?.quantity);
    const cartAvailableQuantity = matchingCartItem?.qty;
    const currentCount = itemCounts[records?.item?._id] || 0; // Get the current count, default to 0

        // console.log("Current count:", currentCount);
        // console.log("Cart quantity:", cartQuantity);
        // console.log("cartAvailableQuantity:", cartAvailableQuantity);

        // Disable the button if current count reaches cart quantity
        const isButtonDisabled = cartAvailableQuantity == cartQuantity || !(records?.item?.product_id?.common_stock ? Number(records?.item?.product_id?.common_stock) > 0 : records?.item?.product_spec?.quantity > 0);
        // console.log("isButtonDisabled:", isButtonDisabled);
        return <Button 
        // onClick={() => addToCart(records?.item)} 
        onClick={() => handleAddToCart(records?.item?._id, records?.item)} 
        // loading={loading}
        loading={itemLoading[records?.item?._id] || false} // Check load
        icon={<ShoppingCartOutlined />}
        // disabled={!(records?.item?.product_spec?.quantity > 0)}
        disabled={isButtonDisabled}
        >Add to Cart</Button>;
      },
    },
    {
      title: "REMOVE",
      dataIndex: "remove",
      key: "remove",
      render: (action) => {
        // console.log(action)
        return <DeleteOutlined onClick={() => showconfirm(action)} />;
      },
    },
  ];

  return (
    <CartSection>
      <div className="wrapper" style={{ padding: "60px 40px" }}>
        <h1>Wish List</h1>
        {wishList?.length < 1 ? (
          wishlistLocal ? <WishListGuest /> : <Empty />
        ) : cartUpdate ? (
          <Spin fullScreen />
        ) : (
          <div className="cart_section">
            <div className="cart_left">
              <div className="cart_list" id="style-10">
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={true}
                  responsive={true}
                  scroll={{
                    x: scrollSize,
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </CartSection>
  );
};

export default WishList;

const CartSection = styled.section`
  width: 100%;
  margin: 60px 0;
  display: inline-block;

  @media screen and (max-width: 768px) {
   max-width: 768px;
    
  }
`;
