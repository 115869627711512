import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import styled from "styled-components";
import { DeleteOutlined, InfoOutlined, LoadingOutlined, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import {Button, Form, Input, message, Modal, Row, Col} from "antd";
import {styles} from "../ApiService/Data";
import API from "../ApiService/ApiService";

import {useDispatch, useSelector} from "react-redux";
import {
  userGetAllStart,
  userGetAllSuccess,
  userGetAllFail,
} from "../Redux/slices/user";
import {
  addressGetAllFail,
  addressGetAllStart,
  addressGetAllSuccess,
} from "../Redux/slices/userAddress";
import crud_service from "../ApiService/CrudService";
import { company_code, base_url, api_website, api_admin } from "../config";


const Login = () => {
  const api1 = new API();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [loginForm] = Form.useForm();
  const [issSaving, setIsSaving] = useState(false);
  const [visible, setVisible] = useState(true);
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginPassword, setIsLoginPassword] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [saving, setSaving] = useState(false);
  const [otpSent, setOtpSent] = useState(false); // Tracks if OTP has been sent
  const [resendCounter, setResendCounter] = useState(30);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginTrue = useSelector((state) => state?.user?.user?.token);
  const user = useSelector((state) => state?.user?.user);
  const redirect = localStorage.getItem("redirectPath") || ""
  const api = new crud_service();

  useEffect(() => {
    if (!loginTrue && loginTrue != "") {
    } else {
      if(redirect){
        console.log("bbbbbbbbbb")
        navigate(redirect);
      } else {
        console.log("aaaaammmm")
        navigate("/");
      }
    }
  }, [loginTrue]);

  const loginForm1 = async (values) => {
    try {
      const user = await api.create(
        dispatch,
        [userGetAllStart, userGetAllSuccess, userGetAllFail],
        "login",
        values,
        (err, res) => {
          // console.log("res--------->", res);
          if (res?.status === 200) {
          // console.log("res--------->", res);
            // message.success("Login Successful");
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem("user", JSON.stringify(res?.data?.data));
            localStorage.setItem("login", true);
            localStorage.setItem("buyer_name", values?.buyer_number);
            localStorage.setItem("buyer_pasword", values?.buyer_password);
            window.location.reload();
          } else {
            message.error(res?.response?.data?.message);
          }
        }
      );
    } catch (error) {
      message.error("Something went wrong");
      // console.error("Login error:", error);
    }
  };

  const login1 = async (values) => {
    // console.log("values", values); 
    // setLoginLoading(true);

    const Data = {
      ...values,
      company_code: company_code,
    };

    try {
        const user = await api1.create1(
          dispatch,
          [userGetAllStart, userGetAllSuccess, userGetAllFail],
          "verify-otp",
          Data,
          (err, res) => {   
            console.log("register res----->", res);            
            // console.log("register err----->", err?.response?.data?.message);            
    
            if (res?.status === 200) {  
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("user", JSON.stringify(res.data.data));
              localStorage.setItem("login", true);
              localStorage.setItem("buyer_name", values?.name);
              navigate("/");
              window.location.reload();              
            } else {
              // Handle unexpected response (res is null or undefined)
              message.error(err?.response?.data?.message);
              // setIsLoading2(false);
            }
          }
        );
      } catch (error) {
        message.error("Something went wrong");
        // console.error("Registration error:", error);
      }

    // setAddAddressModal(true);
  };

  const generateOtp = async () => {
    const MobNumber = loginForm.getFieldsValue("buyer_number");
    const MobName = loginForm.getFieldsValue("buyer_name");
    const MobEmail = loginForm.getFieldsValue("buyer_emailid");
    console.log("MobNumber", MobNumber);

    const data1 = {
      ...MobNumber,
      company_code: company_code,
    };
    //  {
    //   name: MobName,
    //   mobile: MobNumber,
    //   email: MobEmail,
    //   company_code: company_code
    // }
    const response = await api1.createCommon('guest-register', data1);
    console.log("response", response);
    try {
        if (response?.status === 200) {
          if(response?.data?.message?.includes("OTP sent")){
            setVisible(false);
            console.log("i am here inside")
          }            
            // setCountdown(120);
            // setResendDisabled(true);
            message.success(response.data.message);
            // message.success(response.data.data.code);
        } else {  
            message.error("Unable to generate OTP!");
        }
    } catch (error) {
        message.error(response?.response?.data?.message);
    }

    try {
      // Call API to generate OTP
      // await api.generateOtp(); 
      if(response?.data?.message?.includes("OTP sent")){
        setOtpSent(true); 
        setVisible(false);
      }         

      // Start the resend OTP timer (30 seconds countdown)
      let countdown = 30;
      const timer = setInterval(() => {
        if (countdown > 0) {
          setResendCounter(countdown);
          countdown--;
        } else {
          clearInterval(timer);
          setResendCounter(0);  // Reset counter once the time is up
        }
      }, 1000);
    } catch (error) {
      message.error('Failed to generate OTP, please try again.');
    }
};

  return (
    <React.Fragment>
      <LoginSection>
        <LoginAlign>
          <LoginRight>
            <Title>Login</Title>
                {isLoginPassword == true ? 
                (<Form
                  layout="vertical"
                  form={form}
                  name="Login_Form"
                  onFinish={loginForm1}
                >
                  <Form.Item
                    label="Enter Mobile number"
                    name="buyer_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Mobile Number",
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: "Please enter valid Mobile Number!",
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="Forgot_Password">
                    <Link to="/forgot-password">Forgot Password</Link>
                  </div>
                  <Form.Item
                    label="Enter Password"
                    name="buyer_password"
                    rules={[
                      {required: true, message: "Please enter your password!"},
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      loading={issSaving}
                      className="primary_btn"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>)
                  :
                  (<Form
                    layout="vertical"
                    form={loginForm}
                    name="Login_Form"
                    onFinish={login1}
                  >                    
                    <Form.Item
                      label="Enter Mobile number"
                      name="buyer_number"
                      rules={[
                        {
                          required: true,
                          pattern: /^[0-9]{10}$/,
                          message: "Please enter your Mobile number!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>                     
                    <Form.Item label="OTP" name="buyer_code" hidden={visible} 
                    rules={[
                      {
                        required: true,                        
                        message: "Please enter your OTP!",
                      },
                    ]}
                    >
                      <Input placeholder="Otp" />
                    </Form.Item> 
                    {/* {console.log("visible", visible)} */}
                    {visible == true ? (
                      <Button type="primary" onClick={generateOtp}>
                        <LoginOutlined /> Generate OTP
                      </Button>
                    ) : (
                      <Form.Item>
                        <Button type="primary" htmlType="submit" loading={saving}>
                          <LoginOutlined /> Submit OTP
                        </Button>
                      </Form.Item>
                    )}

                      {otpSent && resendCounter === 0 && (
                        <Button type="link" onClick={generateOtp}>
                          Resend OTP
                        </Button>
                      )}

                      {/* Display the countdown for resend OTP */}
                      {otpSent && resendCounter > 0 && (
                        <p style={{ color: 'gray' }}>You can resend OTP in {resendCounter} seconds.</p>
                      )}
                  </Form>)}
            <Or>or</Or>

            {isLoginPassword == false ? (<NewAccount>
              <Link onClick={() => setIsLoginPassword(true)} >Click Here</Link>
              to Login Using Password
            </NewAccount>) : (<NewAccount>
              <Link onClick={() => setIsLoginPassword(false)} >Click Here</Link>
              to Login Using OTP
            </NewAccount>)}
            
            <Or>or</Or>

            <NewAccount>
              <Link to="/register">Click Here</Link>
              to Register a Account
            </NewAccount>
          </LoginRight>
        </LoginAlign>
      </LoginSection>
    </React.Fragment>
  );
};

export default Login;
const LoginSection = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 580px) {
    padding: 0 20px;
  }
  .primary_btn {
    background: ${styles?.background1};
    /* border: 1px solid ${styles?.colorapi}; */
  }

  @media screen and (max-width: 768px) {
    margin-top: 60px;    
  }
`;
const LoginAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  flex-wrap: wrap;
  box-shadow: 0 0 40px rgb(0 0 0 / 9%);
  border-radius: 5px;
  margin: 70px 0;
  min-height: 300px;

  @media screen and (max-width: 580px) {
    width: 100%;
    margin: 40px 0;
  }
`;

const LoginRight = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 35px 35px;
  input {
    width: 100%;
    padding: 8px 14px;
  }
.Forgot_Password {
  width: 100%;
  text-align: right;
  margin: 0;
  Link {
    color: #000;
    font-size: 14px;
    text-decoration: underline;
  }
}

  input[type="password"] {
    width: 100%;
    padding: 4px 0px;
  }
  .ant-space {
    width: 100%;
    margin: 0 0 10px;
  }
  button {
    padding: 7px 20px;
    height: auto;
    font-size: 15px;
    /* background: ${styles.background}; */
    border: 1px solid ${styles.background};
  }

  .ant-row.ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form label {
    width: 100%;
    display: inline-block;
    text-align: left;
  }

  @media screen and (max-width: 580px) {
    padding: 30px 25px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  color: #000;
  font-weight: 700;
  width: 100%;
  margin: 0 0 20px;
  text-transform: uppercase;
`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;
const LoginOtp = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin: 10px 0 0 0;
  width: 100%;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
const ForgetPassword = styled.div`
  margin: 25px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
`;
const NewAccount = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
